import React, { useEffect, useState } from "react";
import moment from 'moment'
// Style //
import "./RegisterPlayers.css";

// Components //
import { Header } from "../../../../common/containers/index";
import { Sidebar, SkLoader } from "../../../../common/components/index";
import Pagination from "rc-pagination";
// Hooks //
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getTourSchedule } from "../../../../api/tournamentApi";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import Constant from "../../../../services/Constant";
import { setSelectedTournamentAction } from "../../../../redux/actions/tournamentAction";

const RegisterPlayers = () => {

  //  Hooks //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedSeason } = useSelector((state) => state.season);
  const { selectedLeague } = useSelector((state) => state.league);
  const [tournamentsData, setTournamentsData] = useState([]);
  const [localLoding, setLocalLoding] = useState(false);
  const [showList,setShowList] = useState([])
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(8);

  //handelers
  const PaginationChange = (page, pageSize,data) => {
    setPageNo(page);
    setLimit(pageSize);
    let startIndex = (page-1)*pageSize
    let endIndex = (page)*pageSize
    if(data){
      return setShowList(data.slice(startIndex,endIndex))
    }
    setShowList(tournamentsData.slice(startIndex,endIndex))
  };
  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };
  const PerPageChange = (value) => {
    setLimit(value);
    const newPerPage = Math.ceil(tournamentsData.length / value);
    if (pageNo > newPerPage) {
      setPageNo(newPerPage);
    }
  };

  //apiCalls
  const getTournamentsCall = async () => {
    try {
      setLocalLoding(true);
      const data = await getTourSchedule({
        seasonId: selectedSeason.id,
        forRegister:true
      });
      setTournamentsData(data.data);
      PaginationChange(1,8,data.data);
      setLocalLoding(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setLocalLoding(false);
    }
  };

  // useEffect
useEffect(() => {
    getTournamentsCall();
  },[]);
  return (
    <div>
      <Header />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar />
        </div>
        <div className="manage_sec_page_cont">
          <div className="header d-flex justify-content-between align-items-center ">
            <h2>Register A Player</h2>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Tournament</th>
                <th scope="col">Date</th>
                <th scope="col">Players</th>
                <th scope="col">Golf Course</th>
                <th scope="col">Register</th>
              </tr>
            </thead>
            <tbody>
              {!localLoding && showList.length ? showList.map((tournament) => (
                <tr>
                  <td>{tournament.t_name ? tournament.t_name : '-'}</td>
                  <td>{tournament.start_date ? moment(tournament.start_date).format(Constant.DATE_FORMAT) : '-'}</td>
                  <td>{tournament.no_of_players ? tournament.no_of_players : '-'}</td>
                  <td>{tournament.golf_course  && tournament.golf_course.course_name? tournament.golf_course.course_name : '-'}</td>
                  <td>
                    <button
                      className="btn-style btn-orange"
                      onClick={() =>{
                        dispatch(setSelectedTournamentAction(tournament))
                        navigate("/manage-tournament/register-player")
                      }
                      }
                    >
                      Register a Player
                    </button>
                  </td>
                </tr>
              )):(<tr>
                  <td colSpan={5}> <h3>No running tournaments found</h3></td>
                </tr> )}
            </tbody>
          </table>
          {localLoding ? (<SkLoader limit={8} height={25}  mt={2}/>):('')}
          <div className="table-filter-info">
              <Pagination
                className="pagination-data text-light"
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total}`
                }
                onChange={PaginationChange}
                total={tournamentsData.length ? tournamentsData.length : 0}
                current={pageNo}
                pageSize={limit}
                showSizeChanger={false}
                itemRender={PrevNextArrow}
                onShowSizeChange={PerPageChange}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPlayers;
