import React, { useState } from "react";

import "./RegisterPlayersForm.css";

// Components //
import { Header } from "../../../../common/containers/index";
import {
  Sidebar,
  InputField,
  WalletInput,
} from "../../../../common/components/index";

// Images //
import { images } from "../../../../assets/index";

const RegisterPlayersForm = () => {
  const [formDetails, setFormDetails] = useState({
    player_name: "",
    nick_name: "",
    email: "",
    mobile: "",
    handicap_points: "",
  });

  const [hgaCoins, setHgaCoins] = useState("50");
  const [isEnoughCoins, setTsEnoughCoins] = useState(false);

  return (
    <div>
      <Header />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar />
        </div>
        <div className="manage_sec_page_cont">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Player's Details</h2>
          </div>
          <div className="register_player_form_cont">
            <div className="form_cont">
              <div className="form_input_cont">
                <p>Player Name -</p>
                <InputField
                  placeholder={"Enter Player Name"}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      player_name: value,
                    })
                  }
                  value={formDetails.player_name}
                  width={"63%"}
                />
              </div>
              <div className="form_input_cont">
                <p>Nick Name -</p>
                <InputField
                  placeholder={"Enter Nick Name"}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      nick_name: value,
                    })
                  }
                  value={formDetails.nick_name}
                  width={"63%"}
                />
              </div>
              <div className="form_input_cont">
                <p>Email ID -</p>
                <InputField
                  placeholder={"Enter Email ID"}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      email: value,
                    })
                  }
                  value={formDetails.email}
                  width={"63%"}
                  type={"Email"}
                />
              </div>
              <div className="form_input_cont">
                <p>Mobile No. -</p>
                <InputField
                  placeholder={"Enter Mobile No"}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      mobile: value,
                    })
                  }
                  value={formDetails.mobile}
                  width={"63%"}
                  type={"Number"}
                />
              </div>
              <div className="form_input_cont">
                <p>Handicap Points -</p>
                <InputField
                  placeholder={"Enter Handicap Points"}
                  onChange={(value) =>
                    setFormDetails({
                      ...formDetails,
                      handicap_points: value,
                    })
                  }
                  value={formDetails.handicap_points}
                  width={"63%"}
                />
              </div>
              <div className="btn_cont justify-content-end d-flex w-100">
                <button className="btn-style btn-orange"> Save Details </button>
              </div>
            </div>
            <div className="payment_cont">
              <div className="your_wallet d-flex">
                <p>Your Wallet</p>
                <div className="wallet_value_cont d-flex">
                  <div className="img_cont">
                    <img src={images.coinLogo} alt="" />
                  </div>
                  <p>
                    20 <span>(Hga Coin)</span>
                  </p>
                </div>
              </div>
              <div className="registration_fee_cont">
                <h2>Registration Fee</h2>
                <div className="btn_and_inp_cont d-flex justify-content-between align-items-center">
                  <WalletInput
                    placeholder={"HGA Coins"}
                    value={hgaCoins}
                    onChange={setHgaCoins}
                    width={"45%"}
                    coinLogo={true}
                    fieldRed={isEnoughCoins}
                    disabled
                  />
                  {isEnoughCoins ? (
                    <button
                      className="btn-style btn-green col-5"
                      style={{
                        height: "2.5rem",
                      }}
                    >
                      Register Player
                    </button>
                  ) : (
                    <button
                      className="btn-style btn-orange col-5"
                      style={{
                        height: "2.5rem",
                      }}
                    >
                      Purchase Coin
                    </button>
                  )}
                </div>
              </div>
              {
                !isEnoughCoins?<p className="message">
                * You don’t have enough coins to register please add 20 HGA coins
              </p>:''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPlayersForm;
