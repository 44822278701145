import React,{useState} from "react";

// Style //
import "./UploadScoresFile.css";

// Components //
import { Header } from "../../../../common/containers/index";
import { Sidebar, SkLoader } from "../../../../common/components/index";
import { FileUploader } from "react-drag-drop-files";
import { images } from "../../../../assets";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { receivedError, requestSent } from "../../../../redux/actions/utilsAction";
import { submitScores } from "../../../../api/tournamentApi";
import { toast } from "react-toastify";

const UploadScoresFile = () => {
  const fileTypes = ["CSV"];
  const DUMMY_DATA = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [buttonLoading,setButtonLoading]= useState(false)
  //  Hooks //
  const { selectedTournament } = useSelector((state) => state.tournament);
  const [file , setFile] = useState('')
  const dispatch = useDispatch()

  // Handlers //
  const handleChange = (file) => {
    setFile(file);
  };

  const handelFileUpload = () => {
    console.log("file::",file);
    submitScoresApiCall(file)
  }

  // api calls
  const submitScoresApiCall = async(file) =>{
    try {
      setButtonLoading(true)
      const data = await submitScores({tournament_id:selectedTournament.id,course_id:selectedTournament.course_id,file:file})
      setButtonLoading(false)
      toast.success('Scores added successfully !')
    } catch (error) {
      console.log("error::",error);
      dispatch(receivedError(error))
      setButtonLoading(false)

    }
  }

  return (
    <div>
      <Header heading={selectedTournament.t_name} />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar inMangeTournament={true} />
        </div>
        <div className="manage_sec_page_cont">
          <div className="drag_and_drop_cont">
            <h2>Upload</h2>
            <div className="drop-here">
              <div className="txt_cont">
                <img src={images.uploadIcon} alt="" />
                {file ? (
                  <p className="browse">{file.name}</p>
                ) : (
                  <React.Fragment>
                    <p className="browse">browse</p>
                    <p className="file_type">
                      Supported Formate :
                      {fileTypes.map((item) => (
                        item
                      ))}
                    </p>
                  </React.Fragment>
                )}
              </div>
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>
            {buttonLoading?(<SkLoader className='btn-style btn-green textcenter' limit={1} mt={'5'} height={40} width={'100%'}/>):(<button className="btn-style btn-green w-100 mt-5" onClick={handelFileUpload}>Upload File</button>)}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadScoresFile;
