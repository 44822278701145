import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../../../../assets";
import {
  receivedError,
  requestSent,
} from "../../../../../redux/actions/utilsAction";
import { getPlayer, updatePlayerWallet } from "../../../../../api/usersApi";
import { registerPlayer } from "../../../../../api/paymentApi";
import { toast } from "react-toastify";
import { ModalContainer } from "../../../../containers";
import WalletInput from "../../../wallet-input/WalletInput";
import { getSettings } from "../../../../../api/utilsApi";

const status = {
    PROCESSING: "PROCESSING",
    SUCCES: "SUCCESS",
    FAILED: "FAILED",
  };

export default function Register({showRegister,setShowRegister,reLoadComponent,selectedPlayerId,selectedTournamentId,selectedGroupId}) {
  // hooks
  const [playerDetails, setPlayerDetails] = useState({});
  const [localLoading, setLocalLoading] = useState(false);
  const [hgaCoins, setHgaCoins] = useState(0);
  const [amount, setAmount] = useState(0);
  const [oneCoinInDollar, setOneCoinInDollar] = useState(0);
  const [purchaseVisiblity, setPurchaseVisiblity] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(status.PROCESSING);
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const { selectedTournament } = useSelector((state) => state.tournament);
  
  // handelers
  const enoughCoins = () => {
    return (
      playerDetails.wallet &&
      Number(playerDetails.wallet) >= selectedTournament.registration_fee
    );
  };

  const handelHgaCoinsUpdation = (coins) => {
    setHgaCoins(coins)
    setAmount(Number(coins)*Number(oneCoinInDollar))
  }

  const handelRegisterPlayer = () => {
    let details = {
        playerId: selectedPlayerId ? selectedPlayerId : playerDetails.id,
        tournamentId: selectedTournamentId ? selectedTournamentId : selectedTournament.id,
      }
    if(selectedGroupId){
        details['groupId'] = selectedGroupId
    }
    registerPlayerCall(details);
  };

  //   apiCalls
  const getplayerDetailsCall = async () => {
    try {
      setButtonLoading(true)
      const data = await getPlayer();
      setPlayerDetails(data);
      setButtonLoading(false)
      
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false)
      
    }
  };

  const registerPlayerCall = async (details) => {
    try {
      setButtonLoading(true)
      const data = await registerPlayer(details);
      toast.success("Player Registerd successfully !");
      setShowRegister(false);
    //   getGroupsDetailsCall();
      setButtonLoading(false)
      reLoadComponent()
      getplayerDetailsCall()
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false)
    }
  };
  const getSettingCall = async () => {
    try {
      setButtonLoading(true);
      const data = await getSettings();
      console.log("data::",data)
      setOneCoinInDollar(1/data['dollar_in_hga_coin']);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const purchaseCoinApi = async (coins) => {
    setShowRegister(false);
    setRegisterStatus(status.PROCESSING);
    try {
      setLocalLoading(true);
      const data = await updatePlayerWallet({
        coins: coins,
      });
      setPlayerDetails(data);
      setShowRegister(true);
      setTimeout(() => {
        setRegisterStatus(status.SUCCES);
      }, 2000);
      setPurchaseVisiblity(false)
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setTimeout(() => {
        setRegisterStatus(status.FAILED);
      }, 2000);
      setPurchaseVisiblity(false)
    }
  };



  //   useEffect
  useEffect(() => {
    getplayerDetailsCall();
    getSettingCall();
  }, []);

  

  return (
    <div>
    <ModalContainer show={showRegister}>
      <div
        className="register_modal_cont form_common_style"
        id="register_modal"
      >
        <div className="header">
          <h2> Register </h2>
          <i
            className="fa-solid fa-xmark"
            onClick={() => setShowRegister(!showRegister)}
          ></i>
        </div>
        <div className="payment_cont">
          <div className="your_wallet d-flex">
            <p>Your Wallet</p>
            <div className="wallet_value_cont d-flex">
              <div className="img_cont">
                <img src={images.coinLogo} alt="" />
              </div>
              <p>
                {playerDetails.wallet || playerDetails.wallet == 0
                  ? playerDetails.wallet
                  : "-"}
                <span>(Hga Coins)</span>
              </p>
            </div>
          </div>
          <div className="registration_fee_cont">
            <h2>Registration Fee </h2>
            <div className="btn_and_inp_cont d-flex justify-content-between align-items-center">
              <WalletInput
                placeholder={"HGA Coins"}
                value={selectedTournament.registration_fee}
                onChange={setHgaCoins}
                width={"45%"}
                coinLogo={true}
                fieldRed={enoughCoins()}
                disabled={true}
              />
              {enoughCoins() ? (
                <button
                  className="btn-style btn-green col-5"
                  style={{
                    height: "2.5rem",
                  }}
                  onClick={() => {
                    handelRegisterPlayer();
                  }}
                >
                  Register Player
                </button>
              ) : (
                <button
                  className="btn-style btn-orange col-5"
                  style={{
                    height: "2.5rem",
                  }}
                  onClick={() => {
                    setHgaCoins(
                      selectedTournament.registration_fee -
                        Number(playerDetails.wallet)
                    );
                    setAmount(
                      (selectedTournament.registration_fee -
                        Number(playerDetails.wallet)) *
                        oneCoinInDollar
                    );
                    setPurchaseVisiblity(true);
                  }}
                >
                  Purchase Coin
                </button>
              )}
            </div>
          </div>

          {!enoughCoins() ? (
            <p className="message">
              {`* You don’t have enough coins to register please add ${
                selectedTournament.registration_fee -
                Number(playerDetails.wallet)
              } HGA
          coins`}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </ModalContainer>
    <ModalContainer show={purchaseVisiblity}>
      <div className="purchese_coin_cont form_common_style loader_modal">
        <div className="filler_dots_cont">
          <div className="orange_dots">
            <img src={images.fillerImg} className="dots_btm" alt="" />
            <img src={images.fillerImg} className="dots_top" alt="" />
          </div>
        </div>
        <div className="header">
          <h2> Purchese HGA Coins </h2>
          <i
            className="fa-solid fa-xmark close_btn"
            onClick={() => setPurchaseVisiblity(false)}
          ></i>
        </div>
        <div className="wallet_input_cont">
          <WalletInput
            type={"text"}
            coinLogo={true}
            placeholder={"HGA Coins"}
            width={"100%"}
            fieldRed={true}
            value={hgaCoins}
            onChange={handelHgaCoinsUpdation}
            alignCenter={true}
          />

          <div className="divider_line">
            <div className="line"></div>
            <span>OR</span>
            <div className="line"></div>
          </div>
          <WalletInput
            type={"text"}
            coinLogo={false}
            placeholder={"Amount in Dollars"}
            width={"100%"}
            fieldRed={true}
            value={amount}
            disabled={true}
            alignCenter={true}
          />
          <button
            className="btn-style btn-green w-100 mt-5"
            onClick={() => {
              purchaseCoinApi(hgaCoins);
            }}
          >
            Purchase Coins
          </button>
        </div>
      </div>
    </ModalContainer>
    <ModalContainer show={localLoading}>
      <div className="form_common_style loader_modal">
        <div className="filler_dots_cont">
          {registerStatus === status.PROCESSING ? (
            <div className="gray_dots">
              <img src={images.grayDots} className="dots_btm" alt="" />
              <img src={images.grayDots} className="dots_top" alt="" />
            </div>
          ) : registerStatus === status.SUCCES ? (
            <div className="green_dots">
              <img src={images.greenDots} className="dots_btm" alt="" />
              <img src={images.greenDots} className="dots_top" alt="" />
            </div>
          ) : (
            <div className="orange_dots">
              <img src={images.orangeDots} className="dots_btm" alt="" />
              <img src={images.orangeDots} className="dots_top" alt="" />
            </div>
          )}
        </div>
        {registerStatus === status.PROCESSING ? (
          ""
        ) : (
          <i
            className="fa-solid fa-xmark"
            onClick={() => {
              setLocalLoading(false);
            }}
          ></i>
        )}
        <div className="loader_gif_cont">
          {registerStatus === status.SUCCES ? (
            <img src={images.succes} alt="" />
          ) : registerStatus === status.PROCESSING ? (
            <img src={images.loagerGif} alt="" />
          ) : (
            <img src={images.failed} alt="" />
          )}
        </div>
        {registerStatus === status.SUCCES ? (
          <React.Fragment>
            <p>Hga Coins are successfully added to your account</p>
            {/*<h2>Tournament Name</h2>*/}
          </React.Fragment>
        ) : registerStatus === status.PROCESSING ? (
          <h2>Payment Is Processing</h2>
        ) : (
          <h2>Payment Failed</h2>
        )}
      </div>
    </ModalContainer>
  </div>
  ) ;
}
