import { actionType } from "../actionType";

const initialState ={
    allSeasons:[],
    selectedSeason:{}
}

function seasonReducer(state= initialState, action){
     switch(action.type) {
        
         case (actionType.SEASON.SELECT_SEASON):
            return{
                ...state,
                selectedSeason: action.payload
            }
        case (actionType.SEASON.SET_ALL_SEASONS):
            return{
                ...state,
                allSeasons:action.payload
            }
         default: return state

     };
}

export default seasonReducer;