import React, { useState } from "react";

// Style //
import "./Sponsors.css";

// Container //
import { Header } from "../../../../common/containers/index";

// Components //
import { Sidebar } from "../../../../common/components/index";

const Sponsors = () => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div>
      <Header />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar />
        </div>
        <div className="manage_sec_page_cont">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Sponsors</h2>
          </div>
          <table className="Sponsors">
            <tbody>
              <tr>
                <td>
                  <div className="sponsor_img_cont">
                    <div className="sponsor_img">
                      <img
                        src="https://logos-world.net/wp-content/uploads/2020/04/Nike-Logo.png"
                        alt=""
                      />
                    </div>
                    {isEdit ? (
                      <div className="btn_cont d-flex flex-column">
                        <label for={"file"} className="button green-btn">
                          <i className="fa-solid fa-pencil"></i>
                        </label>
                        <input type="file" id="file" />
                        <button className="button orange-btn">
                          <i className="fa-sharp fa-solid fa-trash"></i>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td className="rgt_btn_cont">
                  {isEdit ? (
                    <input
                      className="edit_input_field"
                      type="text"
                      value={"Nike"}
                    />
                  ) : (
                    <h3>Nike</h3>
                  )}
                </td>
                <td>
                  {isEdit ? (
                    <input
                      className="edit_input_field"
                      type="text"
                      value={"examplesponsors.comfergrtgrtbrtbrtbgrtbrtbt"}
                    />
                  ) : (
                    <p>examplesponsors.com</p>
                  )}
                </td>
                <td>
                  {isEdit ? (
                    <textarea
                      className="edit_input_field"
                      type="text"
                      value={
                        " have found the game to be, in all factualness, a universal"
                      }
                    />
                  ) : (
                    <p>
                      I have found the game to be, in all factualness, a
                      universal{" "}
                    </p>
                  )}
                </td>
                <td className="rgt_btn_cont">
                  <div className="btn_cont d-flex rgt_btns">
                    {isEdit ? (
                      <>
                        <button className="button green-btn">
                          <i className="fa-solid fa-check"></i>
                        </button>
                        <button
                          className="button gray-btn ml-2"
                          onClick={() => setIsEdit(false)}
                        >
                          <i className="fa-sharp fa-solid fa-xmark"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="button green-btn"
                          onClick={() => setIsEdit(true)}
                        >
                          <i className="fa-solid fa-pencil"></i>
                        </button>
                        <button className="button orange-btn">
                          <i className="fa-sharp fa-solid fa-trash"></i>
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
