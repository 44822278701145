import React, { useEffect, useState } from "react";

// Style //
import "./PlayerComparison.css";

// Components //
import { Header, Wraper } from "../../common/containers";
import { DropDownWithSearch, Loader } from "../../common/components";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { fetchPlayerStats, getAllPlayers } from "../../api/usersApi";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";

const PlayerComparison = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { selectedSeason } = useSelector((state) => state.season);
  const { loading } = useSelector((state) => state.utils);
  const location = useLocation();
  const [playerStats, setPlayerStats] = useState({});
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [allPlayers,setAllPlayers] = useState([])
  const [search,setSearch] =useState('')
  const [localLoading,setLocalLoading] = useState(false)

  
  // handelers
  const handelOnSelect = (user) => {
   
    setSelectedPlayer(user)
    let ids = []
    if (location.search) {
      let s = decodeURIComponent(location.search.substring(1));
      if (s.length) {
        ids = s.split(",");
      }
    }
    if(ids.includes(user.id.toString())){
      return
    }
    if(ids.length>=4){
      return toast.warning('Max 4 players can be compaired !')
    }
    
    ids.push(user.id)
    let idString = ids.join(',')
    navigate({pathname:'/player-comparesion',search:encodeURIComponent(idString).toString()})

  }

  const handelRemovePlayer = (userId) => {
    let ids = []
    if (location.search) {
      let s = decodeURIComponent(location.search.substring(1));
      if (s.length) {
        ids = s.split(",");
      }
    }
    ids = ids.filter((id)=>id!=userId)
    let idString = ids.join(',')
    navigate({pathname:'/player-comparesion',search:encodeURIComponent(idString).toString()})
   
  }

  // apiCalls

  const fetchPlayerStatsCall = async () => {
    try {
      dispatch(requestSent());
      let options = { seasonId: selectedSeason.id };
      if (location.search) {
        let s = decodeURIComponent(location.search.substring(1));
        if (s.length) {
          options["playerIds"] = s.split(",");
        }
      }
      let data = await fetchPlayerStats(options);
      setPlayerStats(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getAllPlayersCall = async (search) => {
    try {
      setLocalLoading(true)
      const data = await getAllPlayers({ search });
      console.log("players::", data);
      setAllPlayers(data.rows);
      setLocalLoading(false)
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setLocalLoading(false)
    }
  };

  // useEffect
  useEffect(() => {
    fetchPlayerStatsCall();
  }, [location.search]);
  return (
    <div>
      <Header />
      <div className="player_card_cont d-flex justify-content-center mt-2">
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {Object.keys(playerStats).map((key) => {
              return (
                <div className="player_card" key={key}>
                  <div className="vs">
                    <span>VS</span>
                  </div>
                  <div className="d-flex justify-content-end" onClick={()=>{handelRemovePlayer(key)}}>
                    <i
                      className="fa-solid fa-xmark"
                      style={{
                        cursor: "pointer",
                        color: "gray",
                        fontSize: "1.2rem",
                      }}
                    ></i>
                  </div>
                  <div className="top d-flex align-items-center">
                    <div className="profile">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Tiger_Woods_drives_by_Allison.jpg/800px-Tiger_Woods_drives_by_Allison.jpg"
                        alt=""
                      />
                    </div>
                    <div className="player_name">
                      <h2>
                        {playerStats[key].name ? playerStats[key].name : "-"}
                      </h2>
                      <p>
                        @
                        {playerStats[key].username
                          ? playerStats[key].username
                          : "-"}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="contact_info">
                    <div className="header">
                      <div className="line"></div>
                      <p>Contact Info</p>
                      <div className="line"></div>
                    </div>
                    <div className="info d-flex align-items-center">
                      <p className="key">Mobile :</p>
                      <p className="value">
                        {playerStats[key].phone ? playerStats[key].phone : "-"}{" "}
                      </p>
                    </div>
                    <div className="info d-flex align-items-center">
                      <p className="key">Email :</p>
                      <p className="value">
                        {playerStats[key].email ? playerStats[key].email : "-"}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="stats_cont">
                    <div className="stats d-flex">
                      <p>Season Victories </p>
                      <p>
                        {playerStats[key].season_victories
                          ? playerStats[key].season_victories
                          : "0"}{" "}
                      </p>
                    </div>
                    <div className="stats d-flex">
                      <p>Season Top3s </p>
                      <p>
                        {playerStats[key].season_top3s
                          ? playerStats[key].season_top3s
                          : "0"}
                      </p>
                    </div>
                    <div className="stats d-flex">
                      <p>Scoring Avg </p>
                      <p>
                        {playerStats[key].season_score_average
                          ? playerStats[key].season_score_average
                          : "0"}
                      </p>
                    </div>
                    <div className="stats d-flex">
                      <p>Points Avg </p>
                      <p>
                        {playerStats[key].season_points_average
                          ? playerStats[key].season_points_average
                          : "0"}{" "}
                      </p>
                    </div>
                    <div className="stats d-flex">
                      <p>Total Birdies </p>
                      <p>
                        {playerStats[key].total_birdies
                          ? playerStats[key].total_birdies
                          : "0"}
                      </p>
                    </div>
                    <div className="stats d-flex">
                      <p>Total Eagles </p>
                      <p>
                        {playerStats[key].total_eagles
                          ? playerStats[key].total_eagles
                          : "0"}
                      </p>
                    </div>
                    <div className="stats d-flex">
                      <p>Holes In Ones </p>
                      <p>
                        {playerStats[key].total_hole_in_ones
                          ? playerStats[key].total_hole_in_ones
                          : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}
        {Object.keys(playerStats).length < 4 ? (
          <div className="player_card add_card">
          <div className="add_circle">
            <i className="fa-solid fa-plus"></i>
          </div>
          <h2>Add Player</h2>
          <DropDownWithSearch
            title={
              selectedPlayer.username
                ? selectedPlayer.username
                : ""
            }
            onChange={(val) => {
              setSearch(val)
              getAllPlayersCall(val)
            }}
            placeholder="Search Players"
            dropdownPlaceholder={"Select Player"}
            headerOptions={["Player", "HDCP"]}
            player={allPlayers}
            value={search}
            handelChangeSelection={(user) => {
              handelOnSelect(user);
            }}
          />
          
        </div>
        ) :('')}
        
      </div>
    </div>
  );
};

export default PlayerComparison;
