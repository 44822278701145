import React from 'react'
import {images} from "../../../assets/index"

import "./Loader.css"

const Loader = () => {
  return (
    <div className='loader_cont' >
       <img src={images.loader} alt="" />
    </div>
  )
}

export default Loader