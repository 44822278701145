import React, { useEffect, useState } from "react";
import "./Group.css";
import moment from "moment";
import { images } from "../../assets/index";
import __ from "lodash";
// Component //
import { Wraper, Header, ModalContainer } from "../../common/containers";
import {
  UserProfile,
  DropDownWithSearch,
  SkLoader,
  WalletInput,
} from "../../common/components";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { getGroupDetails } from "../../api/tournamentApi";
import Constant from "../../services/Constant";
import {
  addPlayerToGroup,
  checkPlayerStatus,
  getPlayer,
  getUsers,
  getUsersOfTournament,
  removePlayerFromGroup,
  updateHandicap,
  updatePlayerWallet,
} from "../../api/usersApi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { isObjectEmpty } from "../../services/utils";
import { registerPlayer } from "../../api/paymentApi";
import { getSettings } from "../../api/utilsApi";
import Register from "../../common/components/Modals/payment/Register/Register";

const responsibilityOptions = {
  newToPlatform: 1,
  newToTournament: 2,
  newToGroup: 3,
  oldInGroup:4,
};

const status = {
  PROCESSING: "PROCESSING",
  SUCCES: "SUCCESS",
  FAILED: "FAILED",
};

const Groups = () => {
  // hooks
  const [editGroupModal, setEditGroupModal] = useState(false);
  const { selectedTournament } = useSelector((state) => state.tournament);
  const { loading } = useSelector((state) => state.utils);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  let [tournamentDetails, setTournamentDetails] = useState({});
  let [allPlayers, setAllPlayers] = useState({});
  const [search, setSearch] = useState({});
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [buttonResponsibility, setButtonResponsibility] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initialPlayers,setInitialPlayers] = useState({});
  const [iconLoading,setIconLoading] = useState({});
  const [registerModal,setRegisterModal] = useState(false);
  const [selectedGroupId,setSelectedGroupId] = useState(0);
  // handelers
  const handelSearchChange = (val, groupId) => {
    let newSearchState = { ...search };
    newSearchState[groupId] = val;
    console.log("newSearchState::", newSearchState);
    setSearch(newSearchState);
    getUserCall(val, groupId);
    if (val == "") handelOnSelect({}, groupId);
  };

  let handelOnSelect = (user, groupId) => {
    let newSelectedPlayer = { ...selectedPlayer };
    newSelectedPlayer[groupId] = user;
    setSelectedPlayer(newSelectedPlayer);
    checkPlayerStatusCall(user.id, groupId, selectedTournament.id);
  };

  const getPlayersCount = (groups) => {
    let count = 0;
    groups.forEach((group) => {
      if (group.players && group.players.length > 0) {
        count = count + group.players.length;
      }
    });
    return count;
  };

  const handelRegisterNewPlayer = (groupId) => {
    navigator(
      `/manage-tournament/register-player?groupId=${groupId}`
    );
  };
  const showRegisterModal = (groupId) => {
    setSelectedGroupId(groupId);
    setRegisterModal(true);
  };

  const handelHandicapChange = (handicap, playerIndex, groupIndex) => {
    let newtournamentObj = __.cloneDeep(tournamentDetails);
    newtournamentObj.group_details[groupIndex].players[playerIndex].handicap =
      handicap;
    setTournamentDetails(newtournamentObj);
  };
  // apiCalls
  const checkPlayerStatusCall = async (playerId, groupId, tournamentId) => {
    try {
      setButtonLoading(true);
      let data = responsibilityOptions.newToPlatform;
      if (playerId) {
        data = await checkPlayerStatus({ playerId, groupId, tournamentId });
      }
      let newButtonResponsibilities = __.cloneDeep(buttonResponsibility);
      newButtonResponsibilities[groupId] = responsibilityOptions[data];
      setButtonResponsibility(newButtonResponsibilities);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const addToGroupCall = async (groupId) => {
    try {
      setButtonLoading(true);

      let data = await addPlayerToGroup({
        playerId: selectedPlayer[groupId].id,
        groupId,
        tournamentId: selectedTournament.id,
      });

      toast.success("Player successfully added");
      setButtonLoading(false);
      getGroupsDetailsCall();
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const submitHandicapChangeCall = async (playerId, handicap,groupId) => {
    try {
      let newIconLoading = __.cloneDeep(iconLoading);
      newIconLoading[groupId][playerId] = true
      setIconLoading(newIconLoading)
      const data = await updateHandicap({ playerId, handicap });
      setTimeout(() => {
        let newIconLoading2 = __.cloneDeep(iconLoading);
        newIconLoading[groupId][playerId] = false
        setIconLoading(newIconLoading2)
      }, 500);
      toast.success("Handicap updated successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
    }
  };

  const removePlayerCall = async (playerId, groupId, groupIndex) => {
    console.log("playerId,groupId::", playerId, groupId);
    try {
      dispatch(requestSent());
      const data = await removePlayerFromGroup({ playerId, groupId });
      dispatch(responseRecived());
      let newtournamentObj = __.cloneDeep(tournamentDetails);
      newtournamentObj.group_details[groupIndex].players =
        tournamentDetails.group_details[groupIndex].players.filter(
          (player) => player.id != playerId
        );
      setTournamentDetails(newtournamentObj);
      toast.success("removed player successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const getGroupsDetailsCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getGroupDetails(selectedTournament.id);
      // const data = await getGroupDetails(147);
      const players = await getUsersOfTournament({tournamentId:selectedTournament.id});

      if (!data.group_details) {
        throw new Error("Some thing went wrong !");
      }
      let newPlayers = {};
      let newButtonResponsibilities = {};
      let newSelectedPlayers = {};
      let newIconLoading = {}
      data.group_details.forEach((group) => {
        newPlayers[group.id] = players;
        newButtonResponsibilities[group.id] =
          responsibilityOptions.newToPlatform;
        newSelectedPlayers[group.id] = {};
        newIconLoading[group.id] = {}
        group.players.forEach((player)=>{
          newIconLoading[group.id][player.id] = false
        })
      });
      setIconLoading(newIconLoading)
      setButtonResponsibility(newButtonResponsibilities);
      setInitialPlayers(newPlayers);
      setAllPlayers(newPlayers);
      setSelectedPlayer(newSelectedPlayers);
      setTournamentDetails(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const getUserCall = async (searchVal, groupId) => {
    try {
      setButtonLoading(true);
      let searchValue = searchVal.trim().replace(/[%]+/g, "\\%");
      console.log("initialPlayers::",initialPlayers,initialPlayers[groupId])
      let data = initialPlayers[groupId]
      let newAllPlayers = { ...allPlayers };
      newAllPlayers[groupId] = data;
      if(searchVal!=''){
         data = await getUsers({ search: searchValue });
         newAllPlayers[groupId] = data.rows;
      }
      setAllPlayers(newAllPlayers);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      setButtonLoading(false);
      dispatch(receivedError(error));
    }
  };



  // useEffect
  useEffect(() => {
    getGroupsDetailsCall();
  }, []);

  return (
    <div id="groups">
      <Register showRegister={registerModal} setShowRegister={setRegisterModal} selectedGroupId={selectedGroupId} reLoadComponent={getGroupsDetailsCall} selectedPlayerId={selectedPlayer[selectedGroupId]?.id} />
      <ModalContainer show={editGroupModal}>
        <div className="form_common_style overflow-hidden" id="edit_groups">
          <div className="filler_elem">
            <img src={images.fillerImg} alt="" />
            <img src={images.fillerImg} alt="" />
          </div>
          <div className="header">
            <h2> Edit Group </h2>
          </div>
          <div className="form_input_cont">
            <div className="input_lable_cont">
              <p>Group Name -</p>
              <div className="input_field_cont">
                <input
                  type="text"
                  className="input_field"
                  placeholder="Enter Group Name"
                />
              </div>
            </div>
            <div className="input_lable_cont">
              <p>No. Of Players -</p>
              <div className="input_field_cont">
                <input
                  type="Number"
                  className="input_field"
                  placeholder="Enter No. of Players"
                />
              </div>
            </div>
            <div className="input_lable_cont">
              <p>Tee Time -</p>
              <div className="input_field_cont">
                <input type="time" className="input_field input_field_time" />
              </div>
            </div>
          </div>
          <div className="btn_cont">
            <button
              className="btn-style btn-orange"
              onClick={() => setEditGroupModal(false)}
            >
              Cancle
            </button>
            <button className="btn-style btn-green">Save Changes</button>
          </div>
        </div>
      </ModalContainer>
      <Header
        floatingElem={true}
        heading={
          tournamentDetails.t_name
            ? tournamentDetails.t_name
            : "Tournament Name"
        }
        subHeading={
          tournamentDetails.golf_course &&
          tournamentDetails.golf_course.course_name
            ? tournamentDetails.golf_course.course_name
            : "Golfe Course Name"
        }
      >
        <div className="floating_cont">
          {loading ? (
            <div className="table">
              <SkLoader limit={2} height={28} mt={2} />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Players</th>
                  <th scope="col">Groups</th>
                  <th scope="col">Registration Fee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {tournamentDetails.start_date
                      ? moment(tournamentDetails.start_date).format(
                          Constant.DATE_FORMAT
                        )
                      : "-"}
                  </td>
                  <td>
                    {tournamentDetails.start_date
                      ? moment(tournamentDetails.start_date).format(
                          Constant.TIME_FORMAT
                        )
                      : "-"}
                  </td>
                  <td>
                    {tournamentDetails.group_details &&
                    tournamentDetails.group_details.length > 0
                      ? getPlayersCount(tournamentDetails.group_details)
                      : "-"}
                  </td>
                  <td>
                    {tournamentDetails.group_details &&
                    tournamentDetails.group_details.length > 0
                      ? tournamentDetails.group_details.length
                      : "-"}
                  </td>
                  <td>
                    {tournamentDetails.registration_fee
                      ? tournamentDetails.registration_fee
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </Header>
      <Wraper id={"groups_table_wraper"} title={"Tournament Registration"}>
        {loading ? (
          <div className="">
            <div className="single_group_cont">
              <SkLoader mt={2} height={40} limit={1} />

              <div className="">
                <SkLoader mt={3} height={30} limit={1} />
                <SkLoader mt={2} height={20} limit={4} />
              </div>
            </div>
            <div className="single_group_cont">
              <SkLoader mt={2} height={40} limit={1} />
            </div>
            <div className="">
              <SkLoader mt={3} height={30} limit={1} />
              <SkLoader mt={2} height={20} limit={4} />
            </div>
          </div>
        ) : tournamentDetails.group_details &&
          tournamentDetails.group_details.length > 0 ? (
          tournamentDetails.group_details.map((group, groupIndex) => {
            return (
              <div className="single_group_cont" key={group.id}>
                <div className="single_group_header d-flex w-100 justify-content-between">
                  <div className="d-flex">
                    <p>{groupIndex + 1}</p>
                    <p className="group_name">
                      {group.name ? group.name : "-"}
                    </p>
                  </div>
                  <div>
                    <span className="tee_time">
                      Tee Time -
                      {group.tee_time
                        ? moment(group.tee_time).format(Constant.TIME_FORMAT)
                        : "-"}
                    </span>
                  </div>
                  <div onClick={() => setEditGroupModal(true)}>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </div>
                </div>

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <td>PLayer</td>
                      <td>Email</td>
                      <td>Handicap</td>
                      
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {group.players && group.players.length > 0
                      ? group.players.map((player, playerIndex) => (
                          <tr key={player.id}>
                            <td>
                              <UserProfile name={player.username} redirection={true} userId={player.id}/>
                            </td>
                            <td>{player.email ? player.email : "-"}</td>
                            <td>
                              <div className="handicap_cont">
                                <input
                                  type="number"
                                  placeholder={`-`}
                                  value={player.handicap}
                                  onChange={(e) => {
                                    handelHandicapChange(
                                      e.target.value,
                                      playerIndex,
                                      groupIndex
                                    );
                                  }}
                                />
                                {iconLoading[group.id] && iconLoading[group.id][player.id]? ( <i
                                  className="fa-solid fa-circle-notch fa-spin"
                                ></i>):(<i
                                  className="fa-solid fa-check"
                                  onClick={() => {
                                    submitHandicapChangeCall(
                                      player.id,
                                      player.handicap,
                                      group.id
                                    );
                                  }}
                                ></i>)}
                                
                              </div>
                            </td>
                            {/*<td>{ ? player.handicap : "-"}</td>*/}
                            {/*<td>
                              {player.change_in_hdcps &&
                              player.change_in_hdcps[0] &&
                              player.change_in_hdcps[0].hga_bar
                                ? player.change_in_hdcps[0].hga_bar
                                : "-"}
                              </td>*/}
                           {/* <td>
                              {player.tournament_leaderboards &&
                              player.tournament_leaderboards[0] &&
                              player.tournament_leaderboards[0].hga_points
                                ? player.tournament_leaderboards[0].hga_points
                                : "-"}
                              </td> */}
                            <td>
                              <button
                                className="btn-style btn-gray"
                                onClick={() => {
                                  removePlayerCall(
                                    player.id,
                                    group.id,
                                    groupIndex
                                  );
                                }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))
                      : ""}
                    <tr>
                      <td>
                        <DropDownWithSearch
                          title={
                            selectedPlayer[group.id] &&
                            selectedPlayer[group.id].username
                              ? selectedPlayer[group.id].username
                              : ""
                          }
                          openTop={true}
                          onChange={(val) => {
                            handelSearchChange(val, group.id);
                          }}
                          placeholder="Search Players"
                          dropdownPlaceholder={"Select Player"}
                          headerOptions={["Player", "HDCP"]}
                          player={allPlayers[group.id]}
                          value={search[group.id]}
                          handelChangeSelection={(user) => {
                            handelOnSelect(user, group.id);
                          }}
                        />
                      </td>
                      <td>
                        {buttonResponsibility[group.id] && !buttonLoading ? (
                          buttonResponsibility[group.id] == 1 ? (
                            <button
                              className="btn-style btn-green add_player_btn_new_to_platform"
                              onClick={() => {
                                toast.warning("Please select any player !");
                              }}
                            >
                              Register
                            </button>
                          ) : buttonResponsibility[group.id] == 2 ? (
                            <button
                              className="btn-style btn-orange add_player_btn"
                              onClick={() => {
                                showRegisterModal(group.id);
                              }}
                            >
                              Register
                            </button>
                          ) : buttonResponsibility[group.id] == 4 ? (
                            <button
                              className="btn-style btn-orange add_player_btn"
                              onClick={() => {
                                toast.warning("Already in the group !");
                              }}
                            >
                              Add To Group
                            </button>
                          ) :  (
                            <button
                              className="btn-style btn-green add_player_btn"
                              onClick={() => {
                                addToGroupCall(group.id);
                              }}
                            >
                              Add To Group
                            </button>
                          )
                        ) : (
                          <SkLoader
                            mt={2}
                            height={20}
                            limit={1}
                            width={"30%"}
                          />
                        )}
                      </td>
                      {/*<td>
                        <div className="palceholder"></div>
                      </td>
                      <td>
                        <div className="palceholder"></div>
                        </td>*/}
                      <td>
                        <div className="palceholder"></div>
                      </td>
                      <td>
                        <button
                          className="btn-style btn-orange"
                          onClick={() => {
                            handelRegisterNewPlayer(group.id);
                          }}
                        >
                          New Player
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })
        ) : (
          <h1 className="text-center">"No Groups Found "</h1>
        )}
      </Wraper>
    </div>
  );
};

export default Groups;
