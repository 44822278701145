import React from "react";

// Style //
import "./UserProfile.css"
import { useNavigate } from "react-router";

const UserProfile = ({name, nickName, profileImg, linkTo,redirection=false,userId,link=''}) => {

  // hooks
  const navigate = useNavigate()
  const handelSelect = () =>{
    console.log('userId::',userId)
    if(redirection){
      if(link){
        navigate(link)
      }else{
        navigate({pathname:'/player-comparesion',search:encodeURIComponent(userId).toString()})
      }
    }
  }
  return (
    <div className="player" onClick={handelSelect}>
      <div className="profile-img">
        <img
          src={profileImg? profileImg: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2-fG35fi_gLe1mF3qat5TyfEEef_XsdVg8fFO6j3Zmpcr0griJOxB5SrTaMZCar9qQ8o&usqp=CAU"}
          alt=""
        />
      </div>
      <div className="player-info">
        <h4>{name}</h4>
      {/*  <p style={{colo: "gray"}}>{nickName}</p> */}
      </div>
    </div>
  );
};

export default UserProfile;
