import { actionType } from "../actionType";

// api //
import {signUp, logIn} from "../../api/auth"
import { getSelectedLeague } from "../../api/leagueApi";

// ================ Season Actions =================//

export const setSelectedSeasonAction = (season) => {
    return {
        type: actionType.SEASON.SELECT_SEASON,
        payload:season
      }
}

export const setAllSeasonsAction = (allSeasons) => {
  return {
    type: actionType.SEASON.SET_ALL_SEASONS,
    payload:allSeasons
  }
}

