import axios from "axios";

// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";

export const getAllGolfCourse = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-all-golf-courses`;
      
      let response = await axios.get(url, {
        headers: {},
        params,
      });
      let data = response.data.data.rows.map((course, index) => {
        return {
          item1: course.course_name ? course.course_name.toUpperCase() : '-',
          item2: course.course_location ? course.course_location.toUpperCase() : '-',
          ...course,
        };
      });
      resolve(data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
export const getGolfScoreSheet = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/golf-course-sheet`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
