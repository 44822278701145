import React, { useEffect, useState } from "react";
import moment from 'moment'
// Style //
import "./ManageSeasons.css";

// Container //
import { Header, ModalContainer } from "../../../../common/containers/index";

// Components //
import { Sidebar } from "../../../../common/components/index";
import { useDispatch, useSelector } from "react-redux";
import { receivedError, requestSent, responseRecived } from "../../../../redux/actions/utilsAction";
import { getAllSeasons } from "../../../../api/seasonApi";
import { setAllSeasonsAction, setSelectedSeasonAction } from "../../../../redux/actions/seasonAction";
import Constant from "../../../../services/Constant";
import { useNavigate } from "react-router";
import CreateSeason from "../../../season/create-season/CreateSeason";

const ManageSeasons = () => {
  // Hooks
  
  const {selectedLeague} = useSelector((state)=>state.league)
  const {allSeasons} = useSelector((state)=>state.season)
  const [seasonsList,setSeasonList] = useState([])
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const [showCreateSeason,setShowCreartSeason] = useState(false)
  
  // Api Calls
  // const getSeasonCall = ()=>{
  //   try {
  //     dispatch(requestSent())
  //     console.log("allSeasons",allSeasons);
  //     // const seasons = getAllSeasons({leagueId:selectedLeague.id})
      

  //     dispatch(responseRecived())
  //   } catch (error) {
  //     dispatch(receivedError(error))
  //     dispatch(responseRecived())
  //   }
  // }

  // handelers
  const handelTournamentsOnClick = (season)=>{
    dispatch(setSelectedSeasonAction(season))
    navigator('/schedule?manageView=true')
  }
  // useEffect
  useEffect(()=>{
    setSeasonList(allSeasons)
  },[allSeasons])
  
  return (
    <div>
      <Header />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar />
        </div>
        <div className="manage_sec_page_cont" id="manage-seasons">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Seasons</h2>
            <button className="btn-style btn-green" onClick={()=>{setShowCreartSeason(true)}}> Start New Season</button>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Season Name</th>
                <th scope="col">Status </th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {seasonsList.map((season)=>(
               <tr key={season.id}>
                  <td>
                     {season.name ? season.name : 'Na'}
                  </td>
                  <td>
                  {[0,1,2].includes(season.status) ? Constant.SEASON_STATUS_CONSTANT[season.status] : 'Na' }
                  </td>
                  <td>
                  {season.start_date ? moment(season.start_date).format('MMMM Do, YYYY') : 'Na'}
                  </td>
                  <td>
                  {season.end_date ? moment(season.end_date).format('MMMM Do, YYYY') : 'Na'}
                  </td>
                  <td>
                     <button className="btn-style btn-orange" onClick={()=>{handelTournamentsOnClick(season)}}>Tournaments</button>
                  </td>
               </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ModalContainer show={showCreateSeason}>
              <CreateSeason 
              handleClose={()=>{setShowCreartSeason(false)}}
              />
      </ModalContainer>
      </div>
    </div>
  );
};

export default ManageSeasons;
