import React, { useState } from "react";

// Style //
import "./CommonModal.css";
// import "./LogInSignUp.css";

// Component //
import SignUp from "../../../../pages/auth/signup/SignUp";
import LogIn from "../../../../pages/auth/login/LogIn";
import SelectLeague from "../../../../pages/select-league/SelectLeague";
// Container //
import { ModalContainer } from "../../../containers/index";

import { useDispatch, useSelector } from "react-redux";
import CreateTournament from "../../../../pages/create-tournament/CreateTournament";
import {
  changeShowLogin,
  changeShowSignup,
} from "../../../../redux/actions/action";
import { setShowCreateTournamentAction } from "../../../../redux/actions/tournamentAction";
import { useNavigate } from "react-router";
import { isObjectEmpty } from "../../../../services/utils";
import { CreateLeague } from "../../../../pages";
import { changeShowCreateLeague } from "../../../../redux/actions/leagueAction";
import { receivedError } from "../../../../redux/actions/utilsAction";

const CommonModals = () => {
  // hooks //

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [dropDown, setDropDown] = useState(false);
  const [showSelectLeague, setShowSelectLeague] = useState(false);
  const {user,showLogin,showSignup} = useSelector((state)=>state.user)
  const {isCreateTournamentVisible,selectedTournament} = useSelector((state)=>state.tournament)
  const {showCreateLeague} = useSelector((state)=>state.league)
    //  Handlers //
    const handleClose = () => {
      dispatch(changeShowLogin(false))
      dispatch(changeShowSignup(false))
      dispatch(changeShowCreateLeague(false))
      dispatch(receivedError(''))
      setShowSelectLeague(false);
    };

  const showSignUpHandler = () => {
    dispatch(changeShowLogin(false));
    dispatch(changeShowSignup(true));
    setShowSelectLeague(false);
  };
  const showLogInHandler = () => {
    dispatch(changeShowLogin(true));
    dispatch(changeShowSignup(false));
    setShowSelectLeague(false);
  };
  const showSelectLeagueHandler = () => {
    dispatch(changeShowLogin(false));
    dispatch(changeShowSignup(false));
    setShowSelectLeague(true);
  };

  return (
    <>
      {/* Sign Up Modal */}
      <ModalContainer show={user ? false : showSignup}>
        <SignUp
          showLogInHandler={showLogInHandler}
          showSelectLeagueHandler={showSelectLeagueHandler}
          handleClose={handleClose}
        />
      </ModalContainer>
      {/* Log In Modal */}
      <ModalContainer show={user ? false : showLogin}>
        <LogIn
          showSignUpHandler={showSignUpHandler}
          handleClose={handleClose}
          showSelectLeagueHandler={showSelectLeagueHandler}
        />
      </ModalContainer>
      {/* create tournament */}
      <ModalContainer show={isCreateTournamentVisible}>
        <CreateTournament
          handleClose={() => {
            dispatch(setShowCreateTournamentAction(false));
          }}
          showSelectLeagueHandler={showSelectLeagueHandler}
          showSignUpHandler={showSignUpHandler}
        />
      </ModalContainer>
      {/* League Modal*/}
      {showSelectLeague ? (
        <ModalContainer
          show={
            user && isObjectEmpty(selectedTournament) ? showSelectLeague : false
          }
        >
          <SelectLeague handleClose={handleClose} />
        </ModalContainer>
      ) : (
        ""
      )}
      {showCreateLeague ? (
        <ModalContainer show={showCreateLeague}>
          <CreateLeague
            handleClose={handleClose}
            showSignUpHandler={showSignUpHandler}
          />
        </ModalContainer>
      ) : (
        ""
      )}
    </>
  );
};

export default CommonModals;