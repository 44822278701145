import React from "react";

// Style //
import "./header.css";

// Images //
import { images } from "../../../assets";

// Component //
import { useSelector } from "react-redux";

// Navigation //
import { useNavigate } from "react-router";

const Header = ({heading , button, children, subHeading, floatingElem,isManager=true}) => {
  const {selectedLeague } = useSelector((state) => state.league);

  const navigate = useNavigate()

  return (
    <div className={`${floatingElem?"floating-elem":''}`}>
      <div className="page-banner_cont">
        <img src={images.headerImg} alt="" />
        <div className="overlay">
          <div className="item_cont">
            <h1>{ heading ? heading : (selectedLeague && selectedLeague.league_name ? selectedLeague.league_name.toUpperCase() : 'Tour Name')}</h1>
            {subHeading? (<p>{subHeading}</p>):''}
            {button && isManager ? (<button onClick={()=>navigate("/manage-tour")} className="btn-style btn-orange">Manage Tour</button>):''}
          </div>
        </div>
      </div>
      {children? (children): ''}
    </div>
  );
};

export default Header;
