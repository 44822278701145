import { combineReducers } from "redux";

import { actionType } from "./actionType";

// reducers
import userReducer from "./reducers/reducer";
import utilsReducer from "./reducers/utilsReducer";
import leagueReducer from "./reducers/leagueReducer";
import tournamentReducer from "./reducers/tournamentReducer";
import seasonReducer from "./reducers/seasonReducer";
console.log("userReducer::",userReducer);
const rootReducer = combineReducers({
  user: userReducer,
  // leaderboard:leaderboardReducer,
  utils: utilsReducer,
  league: leagueReducer,
  tournament: tournamentReducer,
  season: seasonReducer,
});

export default rootReducer;
