import { actionType } from "../actionType";

// api //
import {signUp, logIn} from "../../api/auth"
import { getSelectedLeague } from "../../api/leagueApi";

// ================ League Actions =================//

export const setSelectedLeagueAction = (league) => {
    return {
        type: actionType.LEAGUE.SELECT_LEAGUE,
        payload:league
      }
}

export const changeShowCreateLeague = (bool) => {
  return {
      type: actionType.LEAGUE.CHANGE_SHOW_LEAGUE,
      payload:bool
    }
}

