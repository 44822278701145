import React from "react";

// Style //
import styles from "./WalletInput.module.css";
import { images } from "../../../assets";

const WalletInput = ({ value, placeholder, onChange, width, coinLogo, type, fieldRed, disabled, alignCenter  }) => {
  console.log("dissabled::",disabled)
  return (
    <label
      htmlFor="hga_coin_inp"
      className={`${styles.wallet_input_cont} ${!fieldRed?styles.border_red:''}`}
      style={{
        width: width ? width : "40%",
      }}
    >
      {coinLogo ? (
        <div className={styles.coin_logo_cont}>
          <img src={images.coinLogo} alt="" />
        </div>
      ) : (
        ""
      )}

      <div className={styles.inp_cont}>
        {value ? (
          <div className={styles.label} style={{
            textAlign: alignCenter? "center":''
          }}>{placeholder ? placeholder : ""}</div>
        ) : (
          ""
        )}
        <input  
          disabled={disabled}
          type={type?type:"text"}
          placeholder={placeholder ? placeholder : ""}
          id="hga_coin_inp"
          value={value ? value : ""}
          onChange={(e) => onChange(e.target.value)}
          style={{
            textAlign: alignCenter? "center":''
          }}
        />
      </div>
    </label>
  );
};

export default WalletInput;
