import React from "react";

// Style //
import "./wraper.css";

import { DropdownList } from "../../components/index";

const Wraper = ({
  children,
  title,
  button,
  id,
  otherClassName,
  dropDown,
  otherElem,
  endButton,
  endButtonOnclick,
  endButtonTitle,
}) => {
  // handlers
  const handelClick = () => {
    if (button.handelViewGroupsClick) {
      console.log("inside handler if");
      button.handelViewGroupsClick();
    }
    console.log("inside handler");
  };

  return (
    <React.Fragment>
      <div
        className={`${otherClassName ? otherClassName : ""} wraper`}
        id={`${id ? id : ""}`}
      >
        <div className="heading_cont">
          {title ? <h2>{title}</h2> : ""}
          {otherElem ? otherElem : ""}
          {button ? (
            <button
              className={`btn-${button?.btnColor} btn-style`}
              onClick={() => {
                handelClick();
              }}
            >
              {button?.btnTitle}
            </button>
          ) : (
            ""
          )}
          {
            dropDown || endButton ? (
              <div className='d-flex '>
              {dropDown ? (
                <DropdownList
                  title={dropDown.title}
                  listItems={dropDown.items}
                  handelOnClick={dropDown.handelOnClick}
                />
              ) : (
                ""
              )}
              {endButton ? (
                <button 
                className={`btn-orange btn-style`}
                onClick={() => {
                  endButtonOnclick();
                }}
                style={
                  {
                    marginLeft:5
                  }
                }
              >
                { endButtonTitle }
              </button>
              ) : ('')}
              </div>
            ):''
          }
          
        </div>
        {children}
      </div>
    </React.Fragment>
  );
};

export default Wraper;
