import bronzBadge from "./images/badge-bronz.svg"
import goldBadge from "./images/badge-gold.svg"
import silverBadge from "./images/badge-silver.svg"
import coinLogo from "./images/coin-logo.svg"
import hgaLogo from "./images/hga-logo.svg"
import loginPageImg from "./images/loginpageimg.png"
import fillerImg from "./images/orange-dots.svg"
import headerImg from "./images/header.png"
import loader from "./images/Loader.svg"
import langingPage from "./images/landingpage.webp"
import loagerGif from "./images/Eclipse-1s-201px.gif"
import succes from "./images/succes.svg"
import failed from "./images/failed.svg"
import greenDots from "./images/green-filler-dots.svg"
import orangeDots from "./images/orange-filler-dots.svg"
import grayDots from "./images/gray-filler-dots.svg"
import uploadIcon from "./images/upload-icon.svg"

export default {
    bronzBadge,
    goldBadge,
    silverBadge,
    coinLogo,
    hgaLogo,
    loginPageImg,
    fillerImg,
    headerImg,
    loader,
    langingPage,
    loagerGif,
    succes,
    failed,
    greenDots,
    orangeDots,
    grayDots,
    uploadIcon
}