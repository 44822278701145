import React, { useEffect, useState } from "react";
import "./CreateLeague.css";

// useDispatch //
import { useDispatch, useSelector } from "react-redux";
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router";
import { images } from "../../../assets";
import DateTime from "react-datetime";
import moment from "moment";
import { getCountryArray } from "../../../services/utils";
import { DropDownWithSearch } from "../../../common/components";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsAction";
import Constant from "../../../services/Constant";
import { toast } from "react-toastify";
import { createLeague, createSudoLeague } from "../../../api/leagueApi";
import {
  changeShowCreateLeague,
  setSelectedLeagueAction,
} from "../../../redux/actions/leagueAction";
export default function CreateLeague({ handleClose, showSignUpHandler }) {
  // useState
  const [leagueName, setLeagueName] = useState("");
  const [leagueStartDate, setLeagueStartDate] = useState("");
  const [description, setDescription] = useState("");
  const [search, setSearch] = useState("");

  const [selectedCountry, setSelectedConutry] = useState("");
  const [countryArray, setConutryArray] = useState([]);
  // useNavigate
  const navigator = useNavigate();
  const { user } = useSelector((state) => state.user);
  // useDispatch
  const dispatch = useDispatch();

  // useSlector
  const { error, loading } = useSelector((state) => state.utils);
  const { selectedLeague } = useSelector((state) => state.league);

  // api calls
  const createLeagueCall = async (details) => {
    try {
      console.log("body::", details);

      dispatch(requestSent());
      let data = {};
      if (user) {
        data = await createLeague(details);
      } else {
        data = await createSudoLeague(details);
      }
      console.log("createdLeagueData");
      dispatch(setSelectedLeagueAction(data));
      dispatch(responseRecived());
      toast.success(
        "League created successfully,Please sign up to continue !",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      dispatch(changeShowCreateLeague(false));
      handleClose();
      setLeagueName("");
      setLeagueStartDate("");
      setDescription("");
      setSelectedConutry("");
      // navigator('/manage-tournament')
      showSignUpHandler();
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler
  const handelSearchChange = (val) => {
    setSearch(val);
    const countries = getCountryArray();
    let filteredCountries = countries.filter((name) => name.toLowerCase().includes(val.toLowerCase()));
    console.log("filteredCountries::", filteredCountries);
    let list = filteredCountries.map((name) => {
      return { id: null, item1: name };
    });
    setConutryArray(list);
  };

  const handelChangeSelection = (item) => {
    // let selected = { id: item.id, league_name: item.item1 };
    setSelectedConutry(item.item1);
  };

  function handleSubmit() {
    // if (
    //  !leagueName ||
    //  !leagueStartDate||
    //  !description
    // ) {
    //   dispatch(receivedError(Constant.ERRORS_LIST.FILL_ALL_FIELDS))
    //   return;
    // }

    createLeagueCall({
      league_name: leagueName,
      start_date: leagueStartDate,
      description: description,
      country: selectedCountry,
    });
  }

  // useEffect
  useEffect(() => {
    handelSearchChange("");
  }, []);
  return (
    <div className="create_league_form form_common_style" id="create-league">
      {/* <div className="filler_elem">
        <img src={images.fillerImg} alt="" />
        <img src={images.fillerImg} alt="" />
      </div> */}
      <div className="header">
        <h2> Create New League </h2>
      </div>
      <div className="form_cont">
        <div className="input_lable_cont">
          <p>League Name -</p>
          <div className="input_field_cont">
            <input
              type="text"
              className="input_field"
              placeholder="Enter League Name"
              value={leagueName}
              onChange={(e) => {
                setLeagueName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Start Date -</p>
          <div className="input_field_cont  number-input-elem custom-date_slector">
            <DateTime
              value={leagueStartDate}
              format="DD-MM-YYYY"
            
              inputProps={{ placeholder: "Select Date", readOnly: true }}
              timeFormat={false}
              onChange={(e) => {
                setLeagueStartDate(moment(e).format("YYYY-MM-DD"));
              }}
              closeOnSelect={true}
            />
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Country -</p>
          <div className="input_field_cont">
            <DropDownWithSearch
              title={selectedCountry}
              listItems={countryArray}
              placeholder="Search Country..."
              dropdownPlaceholder={"Select Country"}
              headerOptions={["Country"]}
              handelChangeSelection={handelChangeSelection}
              value={search}
              onChange={handelSearchChange}
              // onChange={setSearchDropDownValue}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>League Description -</p>
          <div className="input_field_cont">
            <textarea
              type="text"
              className="input_field"
              placeholder="Enter Discription"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="btn_cont">
        <button className="btn-style btn-orange" onClick={handleClose}>
          Close
        </button>
        <button className="btn-style btn-green" onClick={handleSubmit}>
          Create
        </button>
      </div>
    </div>
  );
}

