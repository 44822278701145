import React, { useEffect, useState } from "react";

// Style //
import "./Landing.css";

// useSlector//
import { useDispatch, useSelector } from "react-redux";

// React Router//
import { useNavigate } from "react-router";
import { images } from "../../assets/index";

// components//
import CreateTournament from "../create-tournament/CreateTournament";
import CommonModals from "../../common/components/Modals/UnRestricted/CommonModals";

// Container //
import { ModalContainer } from "../../common/containers/index";

// utils
import { isObjectEmpty } from "../../services/utils";
import { setShowCreateTournamentAction } from "../../redux/actions/tournamentAction";
import { changeShowCreateLeague } from "../../redux/actions/leagueAction";

const Landing = () => {
  const navigator = useNavigate();
 
  
  // hooks
  const { user } = useSelector((state) => state.user);
  
  const { selectedSeason } = useSelector((state) => state.season);
  
  const { selectedTournament,isCreateTournamentVisible } = useSelector((state) => state.tournament);
  
  const { selectedLeague } = useSelector((state) => state.league);
  const [showCreateTournament, setShowCreateTournament] = useState(false);

  const dispatch = useDispatch()
  // handler //
  const handleShowCreateTournament = () => {
    dispatch(setShowCreateTournamentAction(true))
    // setLogIn(true)
  };
  const handleShowCreateLeague = () => {
    dispatch(changeShowCreateLeague(true))
    // setLogIn(true)
  };

  //useEffect
  useEffect(() => {
    if (
      user &&
      !isObjectEmpty(selectedLeague) &&
      !isObjectEmpty(selectedSeason) &&
      !isObjectEmpty(selectedTournament)
    ) {
      navigator("/standing");
    }else if(user && !isObjectEmpty(selectedTournament)){
      navigator("/manage-my-tournament");
    }
    // console.log("isCreateTournamentVisible::",isCreateTournamentVisible);
  },[]);  
  return (
    <>
      <div className="landing_cont">
        <img src={images.langingPage} alt="" />
        <div className="overlay">
          <div className="txt_cont">
            <div className="line-and-txt">
              <div className="line"></div>
              <span>ENJOY THE ULTIMATE</span>
              <div className="line"></div>
            </div>
            <h1>GOLF EXPERIENCE</h1>
            <p>
            Tee up and join us as we embark on a captivating journey through the dynamic world of golf. 
            </p>
            <div className="btn_cont">
              <button
                className="btn-style btn-orange"
                onClick={() => {
                  handleShowCreateTournament();
                }}
              >
                Create Tournaments
              </button>
              <button className="btn-style btn-orange" onClick={() => {
                handleShowCreateLeague();
              }}>Create League</button>
            </div>
          </div>
        </div>
      </div>
      {/* Create Tourmament Modal*/}
          // <CommonModals/>
    </>
  );
};

export default Landing;
