import { actionType } from "../actionType";

const initialState = {
  loading: false,
  error: null,
  bodyOverFlowHidden: false
};

function utilsReducer(state = initialState, action) {
  switch (action.type) {

    // ================= Utils for loader and error ===============//

    case actionType.UTILS.REQUEST_SENT:
      return {
        ...state,
        loading: true,
      };

    case actionType.UTILS.RESPONSE_RECEVIED:
      return {
        ...state,
        loading: false,
      };

    case actionType.UTILS.REQUEST_ERROR:
      console.log("utils error::",state.error);
      return {
        ...state,
        loading: false,
        error: state.error && state.error.indexOf('!') >=0 ? action.payload.split('!')[0] : action.payload ,
      };

    case actionType.BODY_OVERFLOW_HIDDEN:
      console.log("utils error::",state.error);
      return {
        ...state,
        bodyOverFlowHidden: action.payload
      };
      
    default:
      return state;
  }
}

export default utilsReducer;
