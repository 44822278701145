import React, {useState} from 'react'

// Style //
import "./ModalContainer.css"

const ModalContainer = ({children, show}) => {
   const modalState =  show
  return (
    <div className={`modal_cont ${modalState? '': "d-none"}`}>
       <div className='item_cont'>
          {children}
       </div>
    </div>
  )
}

export default ModalContainer
