import React from "react";

// style //
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ inMangeTournament = false }) => {
  const {selectedTournament} = useSelector((state)=>state.tournament)
  return (
    <>
      <div className="side-bar">
        <div className="nav-link_cont">
          {inMangeTournament ? (
            <div>
            <NavLink to={"/manage-tournament/tournament-info"}>Information</NavLink>
            <NavLink to={"/manage-tournament/groups"}>Groups</NavLink>
            <NavLink to={"/manage-tournament/upload-scores"}>Upload Scores</NavLink>
            { selectedTournament.tournament_leaderboards && selectedTournament.tournament_leaderboards.length > 0 ? ('') :(<NavLink to={"/manage-tournament/register-player"}>Register a Player</NavLink>)}
            <NavLink to={"/manage-tournament/sponsors"}>Sponsors</NavLink>
          </div>
          ) : (
            <div>
              <NavLink to={"/manage-tour/tour-info"}>Tour Info.</NavLink>
              <NavLink to={"/manage-tour/seasons"}>Seasons</NavLink>
              <NavLink to={"/manage-tour/register-player"}>
                Register a Player
              </NavLink>
              <NavLink to={"/"}>Standings</NavLink>
              <NavLink to={"/manage-tour/sponsors"}>Sponsors</NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Sidebar;
