import React, { useEffect, useState } from "react";

// Style //
import "./TournamentLeaderboard.css";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsAction";
import { getTournamentLeaderBoard } from "../../../api/tournamentApi";
import { toast } from "react-toastify";
import { Loader, UserProfile } from "../../../common/components";
import { Header, Wraper } from "../../../common/containers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { images } from "../../../assets";
export default function TournamentLeaderboard() {
  // hooks
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.utils);
  const [tournamentResult, setTournamentResult] = useState({});
  const [hioVisibility, setHioVisibility] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  //api calls
  const getTournamentLeaderBoardCall = async () => {
    try {
      const tournamentId = searchParams.get("tournamentId");
      dispatch(requestSent());
      const data = await getTournamentLeaderBoard(tournamentId);
      let flag = false;
      if (data.tournament_leaderboards && data.tournament_leaderboards > 0) {
        data.tournament_leaderboards.forEach((record) => {
          if (record.hio_winner == 1) {
            flag = true;
          }
        });
      }
      setHioVisibility(flag);
      setTournamentResult(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler
  const handelViewGroupsClick = () => {
    // navigator(`/tournament/result?${tournamentId}`)
    navigator(`/results/tournament-groups?tournamentId=${tournamentResult.id}`);
  };
  // useEffects
  useEffect(() => {
    getTournamentLeaderBoardCall();
  }, []);
  return (
    <div id="tournament_leader_board">
      {loading ? <Loader /> : ""}
      <Header
        floatingElem={true}
        heading={tournamentResult.t_name ? tournamentResult.t_name : "-"}
        subHeading={
          tournamentResult.golf_course
            ? tournamentResult.golf_course.course_name
              ? tournamentResult.golf_course.course_name
              : "-"
            : "-"
        }
      >
        <div className="floating_cont">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Players</th>
                <th scope="col">Groups</th>
                <th scope="col">Champions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {tournamentResult
                    ? moment(tournamentResult.start_date).format("DD/MM/YYYY")
                    : "-"}
                </td>
                <td>
                  {tournamentResult && tournamentResult.tournament_leaderboards
                    ? tournamentResult.tournament_leaderboards.length
                    : "-"}
                </td>
                <td>
                  {tournamentResult && tournamentResult.group_details
                    ? tournamentResult.group_details.length
                    : "-"}
                </td>
                <td>
                  {tournamentResult &&
                  tournamentResult.tournament_leaderboards ? (
                    <UserProfile
                      name={
                        tournamentResult.tournament_leaderboards["0"].player
                          ? tournamentResult.tournament_leaderboards["0"].player
                              .username
                          : "-"
                      }
                      nickme={
                        tournamentResult.tournament_leaderboards["0"].player
                          ? tournamentResult.tournament_leaderboards["0"].player
                              .email
                          : "-"
                      }
                      userId={
                        tournamentResult.tournament_leaderboards["0"]
                          ? tournamentResult.tournament_leaderboards["0"]
                              .player_id
                          : 0
                      }
                      redirection={
                        tournamentResult.tournament_leaderboards["0"].player_id
                          ? true
                          : false
                      }
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Header>
      <Wraper
        title={"Leaderboard"}
        button={{
          btnTitle: "View Groups",
          btnColor: "green",
          handelViewGroupsClick,
        }}
      >
        <div className="scroll-table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Hga Finish</th>
                <th scope="col">Hga Bar</th>
                <th scope="col">Player</th>
                <th scope="col">Actual Score</th>
                <th scope="col">Hga Points</th>
                <th scope="col">Group</th>
                <th scope="col">Gross Finish</th>
                <th scope="col">Season Points</th>
                <th scope="col">BCW</th>
                {hioVisibility ? <th scope="col">HIO</th> : ""}

                <th scope="col">Old Hdcp</th>
                <th scope="col">New Hdcp</th>
              </tr>
            </thead>
            <tbody>
              {tournamentResult.tournament_leaderboards &&
              tournamentResult.tournament_leaderboards.length > 0
                ? tournamentResult.tournament_leaderboards.map(
                    (position, index) => {
                      return (
                        <tr
                          className={`tournament-schedules`}
                          key={position.id}
                        >
                          <td scope="row">
                            <div className="badge_cont">
                              {position.hga_finish == 1 ? (
                                <img src={images.goldBadge} alt="" />
                              ) : position.hga_finish == 2 ? (
                                <img src={images.silverBadge} alt="" />
                              ) : position.hga_finish == 3 ? (
                                <img src={images.bronzBadge} alt="" />
                              ) : (
                                ""
                              )}
                              <span className="ranking">
                                {position.hga_finish}
                              </span>

                              {/* <img src={images.goldBadge} alt="" /> */}
                            </div>
                          </td>

                          <td>
                            {position.player && position.player.handicap
                              ? position.player.handicap
                              : "-"}
                          </td>
                          <td>
                            {position.player ? (
                              <UserProfile
                                name={position.player.username}
                                nickName={position.player.email}
                                redirection={true}
                                userId={position.player_id}
                                link={`/results/groups-scorecard?groupId=${position.player?.group_details[0]?.id}&tournamentId=${tournamentResult.id}`}
                              />
                            ) : (
                              "-"
                            )}{" "}
                          </td>
                          <td>
                            {position.actual_strokes
                              ? position.actual_strokes
                              : "-"}
                          </td>
                          <td>
                            <div className="hta-points">
                              {position.hga_points ? position.hga_points : "-"}
                            </div>
                          </td>
                          <td>
                            {position.player?.group_details[0]?.name
                              ? position.player.group_details[0].name
                              : "-"}
                          </td>
                          <td>
                            {position.gross_finish
                              ? position.gross_finish
                              : "-"}
                          </td>
                          <td>
                            {position.season_points
                              ? position.season_points
                              : "-"}
                          </td>
                          <td>
                            {position.bcw_string && position.bcw_string != "NA"
                              ? position.bcw_winner == 1
                                ? `${position.bcw_string} # Winner`
                                : position.bcw_string
                              : "-"}
                          </td>
                          {hioVisibility ? (
                            <td>
                              {position.hio_string &&
                              position.hio_string != "NA"
                                ? position.hio_winner == 1
                                  ? `${position.hio_string} # Winner`
                                  : position.hio_string
                                : "-"}
                            </td>
                          ) : (
                            ""
                          )}

                          <td>
                            {position.player &&
                            position.player.change_in_hdcps &&
                            position.player.change_in_hdcps["0"]
                              ? position.player.change_in_hdcps["0"]
                                  .old_handicap
                              : "-"}
                          </td>
                          <td>
                            {position.player &&
                            position.player.change_in_hdcps &&
                            position.player.change_in_hdcps["0"]
                              ? position.player.change_in_hdcps["0"]
                                  .new_handicap
                              : "-"}
                          </td>
                        </tr>
                      );
                    }
                  )
                : "Scores Not Updated Yet !! "}
            </tbody>
          </table>
        </div>
      </Wraper>
    </div>
  );
}
