import { actionType } from "../actionType";

// api //
import { signUp, logIn } from "../../api/auth";

// ================ LogOut User =================//

export const reciveLogOut = () => {
  return {
    type: actionType.LOG_OUT,
    payload: null,
  };
};

// ================ Login User =================//

const receiveLogin = (user) => {
  return {
    type: actionType.LOGIN_SUCCESS,
    payload: user,
  };
};

export const changeShowLogin = (bool) => {
  return {
    type: actionType.SHOW_LOGIN,
    payload:bool,
  };
};

export const loginUser = (data) => async (dispatch) => {
  dispatch(receiveLogin(data.data?.reset_token));
  const reset_token = JSON.stringify(data.data?.reset_token);
  localStorage.setItem("reset_token", reset_token);

  // catch((err)=>{
  //   console.log("error::",err);
  //    dispatch(loginError(err.response.data))
  // })
};

//   ========================= Sign Up ========================//

export const changeShowSignup = (bool) => {
  return {
    type: actionType.SHOW_SINGUP,
    payload:bool,
  };
};

const receivesignUp = (user) => {
  return {
    type: actionType.SIGN_UP_SUCCESS,
    payload: user,
  };
};

const signUpError = (err) => {
  return {
    type: actionType.SIGN_UP_FAILURE,
    payload: err,
  };
};

export const signUpUser = (user) => (dispatch) => {
  dispatch(receivesignUp(user));
  const reset_token = JSON.stringify(user.reset_token);
  localStorage.setItem("reset_token", reset_token);

  // })
  // .catch((err)=>{
  //   console.log("error::",err);
  //    dispatch(signUpError(err.response.user))
  // })
};

// ==================== get selected info =================== //
