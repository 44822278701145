import React, { useEffect, useState } from "react";
// import "./CreateSeason.css";
import "../../league/create-league/CreateLeague.css";


// useDispatch //
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
import { images } from "../../../assets";
import DateTime from "react-datetime";
import moment from "moment";
import { getCountryArray } from "../../../services/utils";
import { DropDownWithSearch } from "../../../common/components";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsAction";
import Constant from "../../../services/Constant";
import { toast } from "react-toastify";
import { createLeague, createSudoLeague } from "../../../api/leagueApi";
import {
  changeShowCreateLeague,
  setSelectedLeagueAction,
} from "../../../redux/actions/leagueAction";
import { createSeason } from "../../../api/seasonApi";
import { setAllSeasonsAction, setSelectedSeasonAction } from "../../../redux/actions/seasonAction";

export default function CreateSeason({ handleClose }) {
  // useState
  const [seasonName, setSeasonName] = useState("");
  const [seasonStartDate, setSeasonStartDate] = useState(null);
  const [seasonEndDate, setSeasonEndDate] = useState(null);
  const [description, setDescription] = useState("");

  // useNavigate
  const navigator = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { allSeasons } = useSelector((state) => state.season);
  // useDispatch
  const dispatch = useDispatch();

  // useSlector
  const { loading } = useSelector((state) => state.utils);
  const { selectedLeague } = useSelector((state) => state.league);

  // api calls
  const createSeasonCall = async (details) => {
    try {
      console.log("body::", details);
      dispatch(requestSent());
      let data = {};

      data = await createSeason(details);

      console.log("createdSeasonData::", data);
      dispatch(setAllSeasonsAction([data,...allSeasons]));
      dispatch(responseRecived());
      handleClose();
      setSeasonName("");
      setSeasonStartDate(null);
      setDescription('');
      setSeasonEndDate(null);
      toast.success("Season created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // navigator('/manage-tournament')
    } catch (error) {
      console.log("error in call ::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler

  function handleSubmit() {
    if (!seasonName || !seasonStartDate || !description) {
      dispatch(receivedError(Constant.ERRORS_LIST.FILL_ALL_FIELDS));
      return;
    }

    createSeasonCall({
      league_id: selectedLeague.id,
      season_name: seasonName,
      start_date: seasonStartDate,
      end_date: seasonEndDate,
    });
  }

  return (
    <div className="create_league_form form_common_style" id="create-league">
      {/*<div className="filler_elem">
        <img src={images.fillerImg} alt="" />
        <img src={images.fillerImg} alt="" />
  </div>*/}
      <div className="header">
        <h2> Create New Season </h2>
      </div>
      <div className="form_cont">
        <div className="input_lable_cont">
          <p>Season Name :</p>
          <div className="input_field_cont">
            <input
              type="text"
              className="input_field"
              value={seasonName}
              onChange={(e) => {
                setSeasonName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Start Date</p>
          <div className="input-elem number-input-elem custom-date_slector">
            <i className="fa-solid fa-calendar-days"></i>
            <DateTime
              value={seasonStartDate}
              format="DD-MM-YYYY"
              inputProps={{ placeholder: "Select Date", readOnly: true }}
              timeFormat={false}
              onChange={(e) => {
                setSeasonStartDate(moment(e).format("YYYY-MM-DD"));
              }}
              closeOnSelect={true}
            />
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </div>
        </div>
        <div className="input_lable_cont">
          <p>End Date</p>
          <div className="input-elem number-input-elem custom-date_slector">
            <i className="fa-solid fa-calendar-days"></i>
            <DateTime
              value={seasonEndDate}
              format="DD-MM-YYYY"
              inputProps={{ placeholder: "Select Date", readOnly: true }}
              timeFormat={false}
              onChange={(e) => {
                setSeasonEndDate(moment(e).format("YYYY-MM-DD"));
              }}
              closeOnSelect={true}
            />
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Season Description:</p>
          <div className="input_field_cont">
            <textarea
              type="text"
              className="input_field"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="btn_cont">
        <button className="btn-style btn-orange" onClick={handleClose}>
          Close
        </button>
        <button className="btn-style btn-green" onClick={handleSubmit}>
          Create
        </button>
      </div>
    </div>
  );
}
