import { sendErrorMail } from "../api/utilsApi"
import Constant from "../services/Constant";
import CONSTANTS from "../services/Constant"
import moment from 'moment'


export const catchHandle = async (error) => {
    console.log("error ::", error);
    let errorMessage = CONSTANTS.INTERNAL_ERROR_MESSAGE;
    // handle the error here
    if (error.code === 'ERR_NETWORK') {
      console.log("Connection refused error caught");

      errorMessage = CONSTANTS.ERRORS_LIST.UNABLE_TO_CONNECT_TO_SERVER;
    }else if (error.response && error.response.data.message ) {
        errorMessage = error.response.data.message ;
      if(Constant.MANIPULATED_ERRORS[errorMessage]){
        errorMessage = Constant.MANIPULATED_ERRORS[errorMessage]
      }
      if(errorMessage=='Please Login Again !'){
        // localStorage.removeItem('reset_token')
      }
    } else if (error.request && !error.response) {
      errorMessage = CONSTANTS.ERRORS_LIST.NO_RESPONSE_RECIEVED;
    } else if (error.message ) {
      console.log("error::",error,CONSTANTS.SEND_ERROR_MAIL);
      
      if(CONSTANTS.SEND_ERROR_MAIL){
      console.log("error::2",error,CONSTANTS.SEND_ERROR_MAIL);

       await sendErrorMail({errorDetails:error.message})
      }
      errorMessage = CONSTANTS.INTERNAL_ERROR_MESSAGE;
    }

    return (errorMessage)
  }

