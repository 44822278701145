import React from 'react'

// Style //
import Styles from "./InputFieldV2.module.css"

const InputFieldV2 = ({type, placeholder, value, onChange, width, height, cols, textArea}) => {
  return (
    <div className={`${Styles.InputFieldV2_cont} h-100`}>
        {!textArea?(
             <input type={type?type:''} placeholder={placeholder?placeholder:''} value={value} style={{
                width: width?width:'',
                height: height?height:''
               }} onChange={(e)=>onChange(e.target.value)}/>
        ):(<textarea cols={cols} placeholder={placeholder?placeholder:''} value={value} style={{
            width: width?width:'',
            height: height?height:''
          }} onChange={(e)=>onChange(e.target.value)}></textarea>)}
    </div>
  )
}

export default InputFieldV2