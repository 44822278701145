import React from 'react'

// React-Router //
import {Navigate, Outlet } from "react-router";

// Utils 
import { isObjectEmpty } from '../services/utils';

// Redux // 
import { useSelector } from "react-redux"

const PrivateRouter = ({children,redirectTo,toOutlet,component}) => {

    const {user} = useSelector((state)=> state.user)
    // Season slice //
    const { selectedSeason } = useSelector((state) => state.season);
    // Tournament slice //
    const { selectedTournament } = useSelector((state) => state.tournament);
    // League slice //
    const { selectedLeague } = useSelector((state) => state.league);

    return(
        // if there is user and (selectedLeague is not empty) and (selectedSeason is not empty)  and (selectedTournament is not empty ) then only show outlet else got to landing
        user ? toOutlet ? <Outlet /> : component  : <Navigate to={redirectTo} />
        // user && !isObjectEmpty(selectedTournament) && !isObjectEmpty(selectedLeague) && !isObjectEmpty(selectedSeason) ? <Outlet/> : <Navigate to={'/'} />
        
     )
}

export default PrivateRouter