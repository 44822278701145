import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// Toast //
import { toast } from "react-toastify";

// Style //
import "./TourSchedule.css";

// Containers //
import { Header, Wraper } from "../../common/containers";

//  Components //
import { UserProfile, DropdownList, SkLoader } from "../../common/components";
import { Loader } from "../../common/components/index";

// pagination //
import Pagination from "rc-pagination";

// Date Picker //
import DateTime from "react-datetime";

// Api's //
import { getTourSchedule } from "../../api/tournamentApi";

import moment from "moment";

// Redux Action //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../redux/actions/utilsAction";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedSeasonAction } from "../../redux/actions/seasonAction";
import { useSearchParams } from "react-router-dom";
import {
  setSelectedTournamentAction,
  setShowCreateTournamentAction,
} from "../../redux/actions/tournamentAction";
import Register from "../../common/components/Modals/payment/Register/Register";

const TourSchedule = () => {
  const navigator = useNavigate();
  const { error, loading } = useSelector((state) => state.utils);
  const { selectedSeason, allSeasons } = useSelector((state) => state.season);
  const { selectedTournament } = useSelector((state) => state.tournament);

  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const manageView = searchParams.get("manageView") ? true : false;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tourSchedule, setTourSchedule] = useState([]);

  const [perPage, setPerPage] = useState(8);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [filter, setFilter] = useState("");
  const [manageAccess, setManageAccess] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  // handlers //
  const handelDropDownOnClick = (season) => {
    tourScheduleCall(season, startDate, endDate);
  };
  const isValidEndDate = (current) => {
    return current.isAfter(startDate);
  };

  const handelFilterClear = (val) => {
    console.log("handle filter::", startDate, endDate);
    setStartDate(null);
    setEndDate(null);
    setFilter(setStartDate);
    tourScheduleCall(selectedSeason, null, null, true);
  };
  const handelStartDateChange = (date) => {
    setStartDate(date);
    handelFilterSubmit(date, endDate);
  };
  const handelEndDateChange = (date) => {
    setEndDate(date);
    handelFilterSubmit(startDate, date);
  };
  const handelFilterSubmit = (startD, endD) => {
    tourScheduleCall(selectedSeason, startD, endD);
  };
  const handelGotoLeaderBoard = (tour) => {
    navigator(`/results/tournament?tournamentId=${tour.id}`);
  };
  const handelGoToRegister = (tour) => {
    dispatch(setSelectedTournamentAction(tour));
    setShowRegister(true);
  };
  const handelGoToMange = (tour) => {
    dispatch(setSelectedTournamentAction(tour));
    navigator(`/manage-tournament`);
  };

  const tourScheduleCall = async (season, startD, endD, reset = false) => {
    try {
      dispatch(requestSent());
      dispatch(setSelectedSeasonAction(season));
      const data = await getTourSchedule({
        seasonId: season.id,
        date_from: startD || reset ? startD : startDate,
        date_to: endD || reset ? endD : endDate,
      });
      setManageAccess(data.manageAccess);
      setTourSchedule(data.data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(responseRecived());
      dispatch(receivedError(error))
    }
  };

  const reLoadComponent = () => {
    tourScheduleCall(selectedSeason, startDate, endDate);
  };

  useEffect(() => {
    tourScheduleCall(selectedSeason, startDate, endDate);
  }, [filter, selectedTournament]);

  return (
    <React.Fragment>
      <Register
        showRegister={showRegister}
        setShowRegister={setShowRegister}
        reLoadComponent={reLoadComponent}
      />
      <Header button={true} isManager={manageAccess}/>
      <Wraper
        dropDown={{
          title: selectedSeason.name,
          items: allSeasons,
          handelOnClick: handelDropDownOnClick,
        }}
        otherElem={
          <React.Fragment>
            <div className="tournament-type_cont">
              <div className="tournament-type">
                <p>Completed Tournament</p>
                <span className="completed-tournament"></span>
              </div>
              <div className="tournament-type">
                <p>Major Tournament</p>
                <span className="major-tournament"></span>
              </div>
              <div className="tournament-type">
                <p>Women Tournament</p>
                <span className="women-tournament"></span>
              </div>
            </div>
            {manageView ? (
              ""
            ) : (
              <div className="floating_cont">
                <div className="col-10 d-flex">
                  <div className="custom-date_slector">
                    <DateTime
                      key={"startDate" + startDate}
                      value={startDate}
                      onChange={(date) => handelStartDateChange(date)}
                      inputProps={{ placeholder: "Start Date" }}
                      timeFormat={false}
                      dateFormat="DD/MM/YYYY"
                      closeOnSelect={true}
                    />
                  </div>
                  <div className="custom-date_slector">
                    <DateTime
                      value={endDate}
                      onChange={(date) => handelEndDateChange(date)}
                      inputProps={{ placeholder: "End Date" }}
                      timeFormat={false}
                      dateFormat="DD/MM/YYYY"
                      closeOnSelect={true}
                      key={"endDate" + endDate}
                      isValidDate={isValidEndDate}
                    />
                  </div>
                </div>
                <button
                  className="btn-style btn-orange  "
                  onClick={handelFilterClear}
                >
                  Clear
                </button>
              </div>
            )}
          </React.Fragment>
        }
        endButton={manageView}
        endButtonTitle={"Create Tournament"}
        endButtonOnclick={() => {
          dispatch(setShowCreateTournamentAction(true));
        }}
      >
        {loading ? (
          <SkLoader limit={20} height={30} mt={2} />
        ) : (
          <table className="table tour_schedule_table  table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Tournament</th>
                <th scope="col">Venue</th>
                <th scope="col">Champions</th>
                <th scope="col">Time</th>
                <th scope="col"></th>
                
              </tr>
            </thead>

            <tbody className="w-100">
              {tourSchedule.map((tour, index) => {
                return (
                  <tr
                    className={`tournament-schedules ${
                      tour.tournament_leaderboards &&
                      tour.tournament_leaderboards.length > 0
                        ? "completed-tournament"
                        : tour.is_mazor
                        ? "major-tournament"
                        : tour.is_ladies_tourney
                        ? "women-tournament"
                        : ""
                    }`}
                    key={tour.id}
                  >
                    <td>{moment(tour.start_date).format("DD/MM/YYYY")}</td>
                    <td>{tour.t_name}</td>
                    <td>
                      {tour.golf_course ? tour.golf_course.course_name : "-"}
                    </td>

                    <td>
                      {tour.tournament_leaderboards &&
                      tour.tournament_leaderboards.length > 0 ? (
                        <UserProfile
                          name={
                            tour.tournament_leaderboards["0"].player.username
                          }
                          redirection={true}
                          userId={tour.tournament_leaderboards["0"].player_id}
                          nickName={
                            tour.tournament_leaderboards["0"].player.email
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>{moment(tour.start_date).format("HH:mm:ss")}</td>

                    <td className="w-25">
                      {tour.tournament_leaderboards &&
                      tour.tournament_leaderboards.length ? (
                        <button
                          className={"btn-style"}
                          onClick={() => {
                            handelGotoLeaderBoard(tour);
                          }}
                        >
                          Leaderboard
                        </button>
                      ) : manageAccess ? (
                        ""
                      ) : tour.isRegistered ? (
                        <button
                          className={"btn-style"}
                          style={{ backgroundColor: "#a3a1a1" }}
                          onClick={() => {
                            toast.warning(
                              "You have already registerd in this tournament"
                            );
                          }}
                        >
                          Register
                        </button>
                      ) : (
                        <button
                          className={"btn-style"}
                          onClick={() => {
                            handelGoToRegister(tour);
                          }}
                        >
                          Register
                        </button>
                      )}
                      {manageAccess ? (
                        <button
                          className={"btn-style btn-green ms-1"}
                          onClick={() => {
                            handelGoToMange(tour);
                          }}
                        >
                          Manage
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {tourSchedule.length === 0 ? (
          <h3 className="text-center">No Tournaments Found for this season</h3>
        ) : (
          ""
        )}
      </Wraper>
    </React.Fragment>
  );
};

export default TourSchedule;
