import React, { useEffect, useState } from "react";

// Style //
import "./Players.css";

// Containers //
import { Header, Wraper } from "../../common/containers";

//  Components //
import { UserProfile, DropdownList, SkLoader } from "../../common/components";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { getAllPlayers } from "../../api/usersApi";

// pagination //
import Pagination from "rc-pagination";

// Hooks //
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { createSearchParams } from "react-router-dom";

const Players = () => {
  // Hooks //
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [allPlayers, setAllPlayers] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const { error, loading } = useSelector((state) => state.utils);
  const navigate = useNavigate();
  const [comparePlayerObject, setComparePlayerObject] = useState({});

  // handelers
  const PerPageChange = (value) => {
    setLimit(value);
    const newPerPage = Math.ceil(allPlayers.count / value);
    if (pageNo > newPerPage) {
      setPageNo(newPerPage);
    }
  };

  const PaginationChange = (page, pageSize) => {
    setPageNo(page);
    setLimit(pageSize);
  };
  const [compareListOpen, setCompareListOpen] = useState(false);

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };

  const handelSearch = (val) => {
    setSearch(val);
  };

  const handelCompare = () => {
    let idString = ''

    Object.keys(comparePlayerObject).forEach((key,index)=>{
      if(index){
        idString += `,${key}`
      }else{
        idString += `${key}`
      }
    })
    navigate({pathname:'/player-comparesion',search:encodeURIComponent(idString).toString()})
    // navigate('/player-comparesion',)
  }

  const handelAddToCompare = (player) => {
    if (comparePlayerObject[player.id]) {
      let newObj = { ...comparePlayerObject };
      delete newObj[player.id];
      setComparePlayerObject(newObj);
    } else {
      if (Object.keys(comparePlayerObject).length === 4) {
        return toast.warning("Max 4 players can be compaired !");
      }
      let newObj = { ...comparePlayerObject };
      newObj[player.id] = player;
      setComparePlayerObject(newObj);
    }
  };

  // apiCalls
  const getAllPlayersCall = async (pageNo, limit, search) => {
    try {
      dispatch(requestSent());
      const data = await getAllPlayers({ pageNo, limit, search });
      console.log("data::", data);
      setAllPlayers(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // useEffect
  useEffect(() => {
    getAllPlayersCall(pageNo, limit, search);
  }, [pageNo, limit, search]);

  return (
    <React.Fragment>
      <div className="compare_box_cont">
        <button
          className={`compare_box_dropdown ${compareListOpen ? "open" : ""}`}
          onClick={() => setCompareListOpen(!compareListOpen)}
        >
          Compare Players {comparePlayerObject && Object.keys(comparePlayerObject).length ? `(${Object.keys(comparePlayerObject).length})`:''} <i className="fa-solid fa-chevron-down"></i>
        </button>

        <div
          className={`list_of_players ${compareListOpen ? "open" : ""}`}
          style={{
            height:
              compareListOpen && comparePlayerObject
                ? `${(Object.keys(comparePlayerObject).length * 12) / 4 + 5}vw`
                : "",
          }}
        >
          <div className="list_cont">
            {Object.keys(comparePlayerObject)?.length ? (
              <React.Fragment>
                {Object.keys(comparePlayerObject).map((playerId) => (
                  <div className="player_row d-flex ">
                    <UserProfile
                      name={comparePlayerObject[playerId].username}
                    />
                    <i className="fa-solid fa-trash-can" onClick={()=>{handelAddToCompare(comparePlayerObject[playerId])}}></i>
                  </div>
                ))}
                <div className="btn_cont">
                  <button className="btn-style btn-green compare_btn" onClick={handelCompare}>
                    Compare
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <p className="text-center">Please add players first</p>
            )}
          </div>
        </div>
      </div>
      <Header button={true} />
      <Wraper
        title={"Leaderboard"}
        otherElem={
          <React.Fragment>
            <div className="search-player_cont">
              <input
                type="text"
                placeholder="Search Player..."
                onChange={(e) => {
                  handelSearch(e.target.value);
                }}
              />
              <button className="search-btn">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </React.Fragment>
        }
      >
        {!loading && allPlayers.rows ? (
          <div id="players">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Players</th>
                  <th scope="col">Email</th>
                  <th scope="col">Handicap</th>
                  <th scope="col">HGA Bar</th>
                  <th scope="col">Points</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {allPlayers.rows.map((player) => (
                  <tr key={player.id}>
                    <td>
                      <UserProfile name={player.username} userId={player.id} redirection={true}/>
                    </td>
                    <td>{player.email ? player.email : "-"}</td>
                    <td>{player.handicap ? player.handicap : "-"}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <button
                        className="btn-style btn-orange w-75"
                        onClick={() => {
                          handelAddToCompare(player);
                        }}
                      >
                        {comparePlayerObject[player.id]
                          ? "Remove from compare"
                          : "Add to compare"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-filter-info">
              <Pagination
                className="pagination-data"
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total}`
                }
                onChange={PaginationChange}
                total={allPlayers.count ? allPlayers.count : 0}
                current={pageNo}
                pageSize={limit}
                showSizeChanger={false}
                itemRender={PrevNextArrow}
                onShowSizeChange={PerPageChange}
              />
            </div>
          </div>
        ) : (
          <SkLoader limit={10} height={25} mt={2} />
        )}
      </Wraper>
    </React.Fragment>
  );
};

export default Players;
