import React from "react";

// Style //
import "./ManageStandings.css";

// Container //
import { Header } from "../../../../common/containers/index";

// Components //
import { Sidebar, UserProfile } from "../../../../common/components/index";

const ManageStandings = () => {
  const DUMMY_DATA = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div>
      <Header />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar />
        </div>
        <div className="manage_sec_page_cont">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Register A Player</h2>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Rank This Week</th>
                <th scope="col">Players</th>
                <th scope="col">Rank last Tournament</th>
                <th scope="col">Season Points</th>
                <th scope="col">Handicap</th>
                <th scope="col">Sex</th>
                <th scope="col">Tourneys Played</th>
                <th scope="col">HGA Victories</th>
                <th scope="col">HGA TOp 3s</th>
                <th scope="col">Deflict</th>
              </tr>
            </thead>
            <tbody>
              {
                DUMMY_DATA.map((item)=>(
              <tr>
                <td>{item}</td>
                <td className="user_profile_td">
                  <UserProfile
                    name={"Player Name"}
                    nickName={"Nick Name"}
                    profileImg={
                      "https://www.gannett-cdn.com/presto/2021/04/21/NCOD/6e4079a3-4d31-405f-b621-acf4e2ae011e-USATSI_15749516.jpg"
                    }
                  />
                </td>
                <td>16</td>
                <td>10</td>
                <td>1</td>
                <td>Male</td>
                <td>1704</td>
                <td>1</td>
                <td>1</td>
                <td>0</td>
              </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManageStandings;
