import axios from "axios";

// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";

export const getAllSeasons = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      if (token) {
        let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/find-seasons`;
        let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
        console.log("find-seasons api called");
        let response = await axios.get(url, {
          headers: {
            Authorization: bearerToken,
          },
          params,
        });

        let data = response.data.data.map((season)=>{
            return{
                id:season.id,
                item1:season.name,
                ...season
            }
        })
        resolve(data);
      }

    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};

export const getSeasonTourResults = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/season-results`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};

export const createSeason = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-season`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
