import React from "react";

//  Style //
import "./Footer.css"

const Footer = () => {
  return (
    <footer>
      <p>
        HGA Tour © 2023 All Rights Reserved Terms of use and Privacy policy
      </p>
    </footer>
  );
};

export default Footer;
