import React, { useState } from "react";
import "../LogInSignUp.css";
// useDispatch //
import { useDispatch, useSelector } from "react-redux";

// Redux Action //
import {
  loginUser,
  signUpUser,
  existUser,
} from "../../../redux/actions/action";

// React Toast //
import { toast } from "react-toastify";

// Images //
import { images } from "../../../assets";

// Actions
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsAction";
import { getSelectedInfo, logIn } from "../../../api/auth";
import { isObjectEmpty } from "../../../services/utils";
import { setSelectedLeagueAction } from "../../../redux/actions/leagueAction";
import {
  setAllSeasonsAction,
  setSelectedSeasonAction,
} from "../../../redux/actions/seasonAction";
import { setSelectedTournamentAction } from "../../../redux/actions/tournamentAction";
import { useNavigate } from "react-router";
import { getAllSeasons } from "../../../api/seasonApi";

export default function LogIn({
  showSignUpHandler,
  showSelectLeagueHandler,
  handleClose,
}) {
  // LogIn Input States //

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isCreateTournamentVisible } = useSelector(
    (state) => state.tournament
  );
  const navigator = useNavigate();
  // Utils //
  const { error, loading } = useSelector((state) => state.utils);

  // Dispatch //
  const dispatch = useDispatch();
  // Submit Handlers //
  const logInSubmitHandler = async () => {
    try {
      const credentials = {
        email: email,
        password: password,
      };
      dispatch(requestSent());
      let data = await logIn(credentials);
      dispatch(loginUser(data));
      if (isCreateTournamentVisible) {
        return dispatch(responseRecived());
      }
      let selectedInfo = await getSelectedInfo();
      console.log("selectedInfo::", selectedInfo);
      setEmail("");
      setPassword("");

      if (!selectedInfo) {
        dispatch(responseRecived());
        return showSelectLeagueHandler();
      } else if (
        isObjectEmpty(selectedInfo.league) ||
        isObjectEmpty(selectedInfo.season) ||
        isObjectEmpty(selectedInfo.tournament)
      ) {
        dispatch(responseRecived());
        return showSelectLeagueHandler();
      }

      let allSeasons = await getAllSeasons({
        leagueId: selectedInfo.league.id,
      });
      dispatch(setAllSeasonsAction(allSeasons));
      dispatch(setSelectedLeagueAction(selectedInfo.league));
      dispatch(setSelectedSeasonAction(selectedInfo.season));
      dispatch(setSelectedTournamentAction(selectedInfo.tournament));
      setTimeout(() => {
        navigator("/standing");
        handleClose();
        dispatch(responseRecived());
      }, 500);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  return (
    <div className="login_cont">
      <div className="lft">
        <img src={images.loginPageImg} alt="" />
        <div className="overlay">
          <div className="txt_cont">
            <h2>New Here?</h2>
            <p>SignUp and create new tournaments and League</p>
            <button
              className="btn-orange btn-style"
              onClick={() => {
                showSignUpHandler();
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <div className="rgt">
        <div className="close" onClick={handleClose}>
          <i className="fa-sharp fa-solid fa-xmark"></i>
        </div>
        <div>
          <img className="dots-pair dots-pair1" src={images.fillerImg} alt="" />
          <img className="dots-pair dots-pair2" src={images.fillerImg} alt="" />
        </div>
        <h2>Log In To Your Account</h2>
        <div className="input-field_cont">
          <div className="input-elem">
            <i className="fa-solid fa-envelope"></i>{" "}
            <input
              type="email"
              placeholder="Enter Your Email"
              onChange={(e) => {
                dispatch(receivedError(""));
                setEmail(e.target.value);
              }}
              value={email}
            />
          </div>
          <div className="input-elem">
            <i className="fa-solid fa-lock"></i>{" "}
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => {
                dispatch(receivedError(""));
                setPassword(e.target.value);
              }}
              value={password}
            />
          </div>
          <button className="btn-green btn-style" onClick={logInSubmitHandler}>
            Log In
          </button>
          <p className="error_msg"> {error ? `${error}` : ""}</p>
        </div>
      </div>
    </div>
  );
}
