import axios from "axios";
// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";
// axios.defaults.withCredentials = true;

export const getUsers = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-users`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};

export const getUsersOfTournament = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/players-of-tournament`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
}

export const getPlayer = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-details`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
export const fetchPlayerStats = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-stats`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
export const updatePlayer = (details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-update`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, details, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const createNewPlayer = (details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-player`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, details, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

function generateTrueWith70PercentChance() {
  return Math.random() < 0.7;
}

export const updatePlayerWallet = (details) => {
  return new Promise(async (resolve, reject) => {
    try {
      if(!generateTrueWith70PercentChance()){
        throw new Error('Payment Failed !')
      }
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-wallet-update`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.patch(url, details, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};



export const updateHandicap = (details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-handicap-update`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.patch(url, details, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const addPlayerToGroup = (details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-add-to-group`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, details, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const removePlayerFromGroup = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/group-remove-player`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.delete(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const checkPlayerStatus = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/player-status`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};


export const getAllPlayers = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-players`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};



