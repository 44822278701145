import React, { useEffect, useState } from "react";

// Style //
import "./../../../create-tournament/CreateTournament.css";
import "react-datetime/css/react-datetime.css";

// Date picker //
import DateTime from "react-datetime";

// Icons //
import { icons, images } from "../../../../assets/index";

import {
  createSudoTournament,
  createTournament,
  updateTournament,
} from "../../../../api/tournamentApi";
import { getAllGolfCourse } from "../../../../api/golfCourseApi";

import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DropDownWithSearch } from "../../../../common/components";
import moment from "moment";


import { useNavigate } from "react-router";
import Constant from "../../../../services/Constant";
import { setSelectedTournamentAction } from "../../../../redux/actions/tournamentAction";

const UpdateTournament = ({ handleClose,tournament,getTournamentDetailCall }) => {
  // hooks
  
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(moment(tournament.start_date).format('YYYY-MM-DD HH:MM:SS'));
  const [registrationStartDate, setRegistrationStartDate] = useState(moment(tournament.registration_start_date).format('YYYY-MM-DD'));
  const [registrationEndDate, setRegistrationEndDate] = useState(moment(tournament.registration_end_date).format('YYYY-MM-DD'));
  const { user } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    tournament_name: tournament.t_name,
    date: moment(tournament.start_date).format(Constant.DATE_TIME_FORMAT),
    status:tournament.status,
    course: tournament.golf_course.id,
    HGA_coins: tournament.registration_fee,
    registration_start_date: moment(tournament.registration_start_date).format(Constant.DATE_FORMAT),
    registration_end_date: moment(tournament.registration_end_date).format(Constant.DATE_FORMAT),
    is_ladies_tourney:tournament.is_ladies_tourney,
    is_away_course: tournament.is_away_course,
    is_mazor: tournament.is_mazor,
  });
  const [golfCourseSearch, setGolfCourseSearch] = useState("");
  const [golfCourseArray, setGolfCourseArray] = useState([]);
  // const [selectedGolfCourse, setSelectedGolfCourse] = useState({});
  const [selectedGolfCourse, setSelectedGolfCourse] = useState({id:tournament.golf_course.id});
  const { selectedLeague } = useSelector((state) => state.league);
  const { selectedSeason } = useSelector((state) => state.season);
  const [updateFlag,setUpdateFlag] = useState(false)
  // hooks
  const dispatch = useDispatch();
  const navigator = useNavigate();
  // Api Calls
  const updateTournamentCall = async (body) => {
    try {
      console.log("body::", body);
      dispatch(requestSent());
      let data = {};
      data = await updateTournament(body);
      dispatch(responseRecived());

      toast.success(
        "Tournament updated successfully !",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      handleClose();
      setGolfCourseSearch("");
      setStartDate("");
      setRegistrationStartDate("");
      setRegistrationEndDate("");
      setSelectedGolfCourse({});
      setPage(0);
      setFormData({
          tournament_name:'',
          date:'',
          course:'',
          status:'',
          HGA_coins:'',
          registration_start_date:'',
          registration_end_date:'',
          is_ladies_tourney:false,
          is_away_course:false,
          is_mazor:false,
      });
      getTournamentDetailCall(tournament.id)
      // navigator('/manage-tournament')
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getGolfCoursesCall = async () => {  
    try {
      dispatch(requestSent());
      const data = await getAllGolfCourse({ search: golfCourseSearch });
    //   console.log("data::::::::::::::::::",data)
    //   setGolfCourseArray(data);
    dispatch(responseRecived());
       
    console.log("in commented code ==================")
    } catch (error) {
        console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler
  const handelSearchChange = (val) => {
    setGolfCourseSearch(val);
    getGolfCoursesCall(val);
  };

  const status=['Not Started',
    'Onging',
    'Completed']

  const handelChangeSelection = (item) => {
    console.log("item::", item);
    setUpdateFlag(true)
    setSelectedGolfCourse(item);
  };
  const isValidRegistrationEndDate = (current) => {
    return current.isAfter(registrationStartDate);
  };
  function handleSubmit() {
    if (page < 3) {
      setPage(page + 1);
    } else {
      if(!updateFlag){
        console.log("sbfsjdhbgkjsdhf")
        toast.warning(
          "Please update any field first !",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return
      }
      let body = {
        tournament_id: tournament.id,
        tournament_name: formData.tournament_name,
        start_date: startDate,
        registration_start_date: registrationStartDate,
        registration_last_date: registrationEndDate,
        number_of_players: formData.players,
        course_id: selectedGolfCourse.id,
        status:formData.status,
        registration_fee: formData.HGA_coins,
        players_per_group: formData.players_per_group,
        tee_time_difference: formData.tee_time_difference,
        is_ladies_tourney: formData.is_ladies_tourney,
        is_away_course: formData.is_away_course,
        is_mazor: formData.is_mazor,
      };
      updateTournamentCall(body);
    }
  }

  useEffect(() => {
    console.log('hiii')
    getGolfCoursesCall();
  },[]);

  return (
    <div className="multistep-form_cont form_common_style">
      <div className="filler_elem">
        <img src={images.fillerImg} alt="" />
        <img src={images.fillerImg} alt="" />
      </div>
      <div className="header">
        <div className="close" onClick={handleClose}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <h2> Update Tournament </h2>
      </div>
      <div className="progress-and-form_cont">
        <div className="progress_cont">
          <div className="progress_line">
            <div
              className={`steps ${
                page === 0 ? "filling" : page > 0 ? "completed" : ""
              }`}
            >
              1
            </div>
            <div
              className={`steps ${
                page === 1 ? "filling" : page > 1 ? "completed" : ""
              }`}
            >
              2
            </div>
            <div
              className={`steps ${
                page === 2 ? "filling" : page > 2 ? "completed" : ""
              }`}
            >
              3
            </div>
            <div
              className={`steps ${
                page === 3 ? "filling" : page > 3 ? "completed" : ""
              }`}
            >
              Go
            </div>
            <div
              className="progress"
              style={{
                height: `${30 * page}%`,
              }}
            ></div>
          </div>
        </div>
        <div className="form_cont">
          {page == 0 ? (
            <div className="input-field_cont">
              <p>State The Name Of Your Tournament ?</p>
              <div className="input-elem">
                <input
                  type="text"
                  placeholder="Name Of Tournament"
                  onChange={(elem) => {
                    console.log("elem::", formData, elem);
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      tournament_name: elem.target.value,
                    });
                  }}
                  value={formData.tournament_name}
                />
              </div>
              <p>What will be registration fee ?</p>
              <div className="input-elem number-input-elem w-100">
                <img src={icons.Coins} alt="" />
                <input
                  type="number"
                  placeholder="Enter Hga Coins"
                  onChange={(e) =>{
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      HGA_coins: e.target.value,
                    })}
                  }
                  value={formData.HGA_coins}
                />
              </div>

            </div>
          ) : page == 1 ? (
            <div className="input-field_cont">
              <p>What Date will the Tournament be? </p>
              <div className="input-elem number-input-elem custom-date_slector">
                <i className="fa-solid fa-calendar-days"></i>
                <DateTime
                  value={formData.date}
                  format={Constant.DATE_TIME_FORMAT}
                  onChange={(e) => {
                    setStartDate(moment(e).format(Constant.DB_DATE_TIME_FORMAT));
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      date: e,
                    });
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  closeOnSelect={true}
                />
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </div>

              <p>Registration Start Date</p>
              <div className="input-elem number-input-elem custom-date_slector">
                <i className="fa-solid fa-calendar-days"></i>
                <DateTime
                  value={formData.registration_start_date}
                  onChange={(e) => {
                    setRegistrationStartDate(
                      moment(e).format(Constant.DB_DATE_FORMATE)
                    );
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      registration_start_date: e,
                    });
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  timeFormat={false}
                  dateFormat={Constant.DATE_FORMAT}
                  className="rdtOpenTop"
                  closeOnSelect={true}
                />
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </div>
              <p>Registration End Date</p>
              <div className="input-elem number-input-elem custom-date_slector">
                <i className="fa-solid fa-calendar-days"></i>
                <DateTime
                  value={formData.registration_end_date}
                  onChange={(e) => {
                    setRegistrationEndDate(
                      moment(e).format(Constant.DB_DATE_FORMATE)
                    );
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      registration_end_date: e,
                    });
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  className="rdtOpenTop"
                  closeOnSelect={true}
                  isValidDate={isValidRegistrationEndDate}
                />
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </div>
            </div>
          ) : (
            <div className="input-field_cont">
              <p>State the Golf Course where the Tourney will be played</p>
              <div className="mb-3">
                <DropDownWithSearch
                  title={
                    selectedGolfCourse.course_name
                      ? selectedGolfCourse.course_name
                      : tournament.golf_course && tournament.golf_course.course_name ? tournament.golf_course.course_name : "Not Selected"
                  }
                  listItems={golfCourseArray}
                  placeholder="Search Golf Course..."
                  headerOptions={["Golf Course", "Country"]}
                  handelChangeSelection={handelChangeSelection}
                  value={golfCourseSearch}
                  onChange={handelSearchChange}
                />
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={formData.is_mazor}
                  id="is_it_mazor"
                  onChange={() =>{
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      is_mazor: true,
                    })}
                  }
                />
                <label className="form-check-label" for="is_it_mazor">
                  <p>Is it a Mazor?</p>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={formData.is_away_course}
                  id="away_course"
                  onChange={() =>{
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      is_away_course: true,
                    })}
                  }
                />
                <label className="form-check-label" for="away_course">
                  <p>Is it Away Course Tournament ?</p>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={formData.is_ladies_tourney}
                  id="ladies_tournament"
                  onChange={() =>{
                    setUpdateFlag(true)
                    setFormData({
                      ...formData,
                      is_ladies_tourney: true,
                    })}
                  }
                />
                <label className="form-check-label" for="ladies_tournament">
                  <p>Is it Ladies Tournament ?</p>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="btn_cont">
        {page > 0 ? (
          <button className="btn-style" onClick={() => setPage(page - 1)}>
            Prev
          </button>
        ) : (
          <button className="btn-style" onClick={handleClose}>
            Close
          </button>
        )}
        <button
          className={`btn-style ${page < 3 ? "btn-orange" : "btn-green"}`}
          onClick={handleSubmit}
          
        >
          {page < 3 ? "Next" : "Submit"}
        </button>
      </div>
      {/* {conditionalComponent()}
      <div className="d-flex">
        {page > 0 && <button className="btn" onClick={() => setPage(page - 1)}>Back</button>}
        <button className="btn" onClick={handleSubmit}>
          {page === 0 || page === 1 ? "Next" : "Submit"}
        </button>
      </div> */}
    </div>
  );
};

export default UpdateTournament;
