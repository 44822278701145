import React, { useEffect, useState } from "react";
import moment from "moment";
// Style //
import "./Tournament.css";

import { images } from "../../../../assets/index";

// Pages //
import { Landing } from "../../../index";

// Container //
import { Header } from "../../../../common/containers/index";

// Components //
import {
  Loader,
  Sidebar,
  SkLoader,
} from "../../../../common/components/index";
import { ModalContainer } from "../../../../common/containers/index";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";

// APIs //

import Constant from "../../../../services/Constant";
import { getTournamentDetails } from "../../../../api/tournamentApi";
import UpdateTournament from "./UpdateTournament";
import { setSelectedTournamentAction } from "../../../../redux/actions/tournamentAction";

const TournamentInfo = () => {
  // hooks
  const { loading, error } = useSelector((state) => state.utils);
  const {selectedTournament} = useSelector((state)=>state.tournament)

  const [showUpdateTournament, setShowUpdateTournament] = useState(false);


  const dispatch = useDispatch();
  const [tournamentDetails, setTournamentDetails] = useState({});
  if ({}) {
  }
  const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8,
  };

  // apiCalls
  const getTournamentDetailCall = async (tournamentId,firstCall=false) => {
    try {
      dispatch(requestSent());
      const data = await getTournamentDetails({ tournamentId });
      console.log("getTournamentDetails::", data);
      setTournamentDetails(data);
      if(!firstCall){
        dispatch(setSelectedTournamentAction(data))
      }
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };


  // handlers

  
  //On Page Components


  // useEffects
  useEffect(() => {
    getTournamentDetailCall(selectedTournament.id,true);
  }, []);

  return (
    <div>
        <React.Fragment>
          <Header heading={selectedTournament.t_name?selectedTournament.t_name:'Tournament name'}/>
          <div className="d-flex side_tab_page_cont justify-content-between ">
            <div className="manage_sec_side_bar_wraper">
              <Sidebar inMangeTournament={true} />
            </div>
            <div className="manage_sec_page_cont" id="tour-info">
              <div className="header d-flex justify-content-between align-items-center">
                <h2>Tournament Info</h2>
                <button className="btn-style btn-green" onClick={()=>{setShowUpdateTournament(true)}}> Update </button>
              </div>
              {loading ? (
                <SkLoader limit={15} height={30} mt={2} />
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td>Tournament Name -</td>
                      <td>
                        {tournamentDetails.t_name
                          ? tournamentDetails.t_name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Golf Course -</td>
                      <td>
                        {tournamentDetails.golf_course && tournamentDetails.golf_course.course_name
                          ? tournamentDetails.golf_course.course_name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Start Date -</td>
                      <td>
                        {tournamentDetails.start_date
                          ? moment(tournamentDetails.start_date).format(Constant.DATE_FORMAT)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Tee Time -</td>
                      <td>
                      {tournamentDetails.start_date
                        ? moment(tournamentDetails.start_date).format(Constant.DATE_TIME_FORMAT)
                        : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Players -</td>
                      <td>
                        {tournamentDetails.players || tournamentDetails.players==0 ? tournamentDetails.players : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Groups -</td>
                      <td>
                        {tournamentDetails.groups || tournamentDetails.groups == 0 ? tournamentDetails.groups : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Registration Fee -</td>
                      <td>
                        {tournamentDetails.registration_fee || tournamentDetails.registration_fee==0
                          ? tournamentDetails.registration_fee
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Registration Start Date -</td>
                      <td>
                        {tournamentDetails.registration_start_date
                          ? moment(tournamentDetails.registration_start_date).format(Constant.DATE_FORMAT)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Registration End Date -</td>
                      <td>
                        {tournamentDetails.registration_end_date
                          ? moment(tournamentDetails.registration_end_date).format(Constant.DATE_FORMAT)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Description -</td>
                      <td>
                        {tournamentDetails.description
                          ? tournamentDetails.description
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              
            </div>
          </div>
          {showUpdateTournament ? (<ModalContainer show={showUpdateTournament}>
            <UpdateTournament 
            handleClose={()=>{setShowUpdateTournament(false)}}
            tournament={tournamentDetails}
            getTournamentDetailCall={getTournamentDetailCall}
            />
      </ModalContainer>) : ""}
          
        </React.Fragment>
  
    </div>
  );
};

export default TournamentInfo;
