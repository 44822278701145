module.exports = {
    INTERNAL_ERROR_MESSAGE:"Some thing went wrong, please try after some time !",
    MANIPULATED_ERRORS:{
        authorizationError:'Please Login !'
    },
    REFRESH_TOKEN:'refresh',
    ERRORS_LIST:{
        NO_TEE_DETAILS_FOUND:'No tee Details found for selected Golf Course !',
        NO_RESPONSE_RECIEVED:"Server is down for now,please try after some time !",
        UNABLE_TO_CONNECT_TO_SERVER:'Unable to Connect to server for now, please try after some time !',
        FILL_ALL_FIELDS:'Please fill all fields !',
    },
    DATE_FORMAT:'MMMM Do, YYYY',
    DATE_TIME_FORMAT:'MMMM Do, YYYY HH:mm:ss',
    DB_DATE_TIME_FORMAT:'YYYY-MM-DD HH:MM',
    DB_DATE_FORMATE:'YYYY-MM-DD',
    TIME_FORMAT:'hh:mm A',
    SEASON_STATUS:{
        NOT_STARTED:0,
        ONGOING:1,
        COMPLETED:2
    },
    TRANSACTION_TYPE :{
        DEBIT : 'D',
        CREDIT : 'C'
    },
    SEASON_STATUS_CONSTANT:['Not Started','Ongoing','Completed'],
    SEND_ERROR_MAIL:false,
    CONTENT:{
        QUOTE_BOX:'Plese add your quote because,\r\n Your words have the power to heal, to motivate, and to ignite the flames of passion. Write with purpose, and let your voice resonate with the hearts of others , click on update profile button to add your thought'
    }
}


