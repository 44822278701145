import { actionType } from "../actionType";

const initialState = {
  selectedTournament: {},
  isCreateTournamentVisible: false,
  indepentCreationMode: false,
};

function tournamentReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.TOURNAMENT.SELECT_TOURNAMENT:
      return {
        ...state,
        selectedTournament: action.payload,
      };
    case actionType.TOURNAMENT.SHOW_CREATE_TOURNAMENT:
      return {
        ...state,
        isCreateTournamentVisible: action.payload,
      };
    case actionType.TOURNAMENT.INDEPENDENT_CREATION_MODE_ON:
      return {
        ...state,
        indepentCreationMode: true,
      };
    case actionType.TOURNAMENT.INDEPENDENT_CREATION_MODE_OFF:
      return {
        ...state,
        indepentCreationMode: false,
      };
    default:
      return state;
  }
}

export default tournamentReducer;
