import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { receivedError } from "../../../../redux/actions/utilsAction";
import { getCsvRawDataApi } from "../../../../api/tournamentApi";
import { SkLoader } from "../../../../common/components";
import ContentLoader from "react-content-loader";

function CSVButton() {
  // hooks
  const dispatch = useDispatch();
  const { selectedTournament } = useSelector((state) => state.tournament);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [processedData, setProcessedData] = useState([]);

  // Convert data to CSV format

  // apiCalls
  const getCsvRawDataCall = async () => {
    try {
      setButtonLoading(true);
      let data = await getCsvRawDataApi({
        tournamentId: selectedTournament.id,
      });
      var output = Papa.unparse(data, {
        quoteChar: '"', // Enclose fields in quotes
        delimiter: ",",
      });
      setProcessedData(output);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  // useEffect
  useEffect(() => {
    getCsvRawDataCall();
  }, []);
  return (
    <div>
      {buttonLoading ? (
        <div className="btn-style btn-green">
         
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      ) : (
        <div className="btn-style btn-green">
          <CSVLink
            data={processedData}
            enclosingCharacter={`'`}
            filename="data.csv"
            style={{ color: "white" }}
          >
            Download CSV
          </CSVLink>
        </div>
      )}
    </div>
  );
}

export default CSVButton;
