import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// Style //
import "./season-standings.css";

// Containers //
import { Header, Wraper } from "../../common/containers";

//  Components //
import {
  Loader,
  UserProfile,
  DropDownWithSearch,
  SkLoader,
} from "../../common/components/index";

// pagination //
import Pagination from "rc-pagination";

import { useDispatch, useSelector } from "react-redux";

// Redux Action //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../redux/actions/utilsAction";

// Api's //
import { getSeasonStanding } from "../../api/leaderBoardApi";
import { setSelectedSeasonAction } from "../../redux/actions/seasonAction";
import { images } from "../../assets";

const SeasonStandings = () => {
  const { error, loading } = useSelector((state) => state.utils);
  const { selectedTournament } = useSelector((state) => state.tournament);
  const { selectedLeague } = useSelector((state) => state.league);
  const { selectedSeason, allSeasons } = useSelector((state) => state.season);
  const dispatch = useDispatch();
  const [seasonStanding, setSeasonStanding] = useState([]);
  const [searchDropDownValue, setSearchDropDownValue] = useState("");
  const [showBadges, setShowBadges] = useState(false);
  // Api Calls
  const seasonStandingCall = async (season) => {
    try {
      dispatch(setSelectedSeasonAction(season));
      dispatch(requestSent());

      const data = await getSeasonStanding({ seasonId: season.id });
      setSeasonStanding(data.seasonScores);
      setShowBadges(
        !(
          data.seasonScores[0]?.rank == 1 &&
          data.seasonScores[1]?.rank == 1 &&
          data.seasonScores[2]?.rank == 1
        )
      );
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(responseRecived());
      dispatch(receivedError(error));
    }
  };

  // handlers //
  const handelDropDownOnClick = (season) => {
    seasonStandingCall(season);
  };
  useEffect(() => {
    console.log("selectedSeason::", selectedSeason);
    seasonStandingCall(selectedSeason);
  }, []);

  const [perPage, setPerPage] = useState(8);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  {
    /*const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(seasonStanding.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };*/
  }

  {
    /*  const getData = (current, pageSize) => {
    // Normally you should get the data from the server

    return seasonStanding && seasonStanding.length > 0
      ? seasonStanding.slice((current - 1) * pageSize, current * pageSize)
      : [];
  };*/
  }

  {
    /*  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };*/
  }

  {
    /*const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };*/
  }

  return (
    <>
      <Header button={true} />
      <Wraper
        title={"Leaderboard"}
        dropDown={{
          title: selectedSeason.name,
          items: allSeasons,
          handelOnClick: handelDropDownOnClick,
        }}
      >
        {loading ? (
          <SkLoader limit={20} height={30} mt={2} />
        ) : (
          <div className="scroll-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  {showBadges && <th scope="col">Rank This Week</th>}

                  <th scope="col">Players</th>
                  <th scope="col">Rank last Tournament</th>
                  <th scope="col">Season Points</th>
                  <th scope="col">Handicap</th>

                  <th scope="col">Tourneys Played</th>
                  <th scope="col">HGA Victories</th>
                  <th scope="col">Top 3s</th>
                  <th scope="col">Deficit</th>
                </tr>
              </thead>
              <tbody>
                {seasonStanding.map((data, index) => {
                  return (
                    <tr key={data.id}>
                      {showBadges && (
                        <td scope="row">
                          <div className="badge_cont">
                            {data.rank == 1 ? (
                              <img src={images.goldBadge} alt="" />
                            ) : data.rank == 2 ? (
                              <img src={images.silverBadge} alt="" />
                            ) : data.rank == 3 ? (
                              <img src={images.bronzBadge} alt="" />
                            ) : (
                              ""
                            )}
                            <span className="ranking">{data.rank}</span>

                            {/* <img src={images.goldBadge} alt="" /> */}
                          </div>
                        </td>
                      )}

                      <td>
                        <UserProfile
                          name={data.username}
                          nickName={"Nick Name"}
                          userId={data.id}
                          redirection={true}
                        />
                      </td>
                      <td>
                        {data.last_tournament_rank
                          ? data.last_tournament_rank
                          : "-"}
                      </td>
                      <td>{data.season_points ? data.season_points : "-"}</td>
                      <td>{data.handicap ? data.handicap : "-"}</td>

                      <td>
                        {data.tournaments ? data.tournaments.length : "-"}
                      </td>
                      <td>{data.hga_victories ? data.hga_victories : "-"}</td>
                      <td>{data.hga_top3 ? data.hga_top3 : "-"}</td>
                      <td>
                        {seasonStanding &&
                        seasonStanding.length > 0 &&
                        data.season_points
                          ? seasonStanding[0].season_points -
                            Number(data.season_points)
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {seasonStanding.length === 0 ? (
          <h3 className="text-center">No LeaderBoard Found for this season</h3>
        ) : (
          ""
        )}
      </Wraper>
    </>
  );
};

export default SeasonStandings;
