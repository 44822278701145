import React, { useEffect, useState } from "react";

// Style //
import "./CreateTournament.css";
import "react-datetime/css/react-datetime.css";

// Date picker //
import DateTime from "react-datetime";

// Icons //
import { icons, images } from "../../assets/index";

import {
  createSudoTournament,
  createTournament,
} from "../../api/tournamentApi";
import { getAllGolfCourse } from "../../api/golfCourseApi";

import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DropDownWithSearch } from "../../common/components";
import { isObjectEmpty } from "../../services/utils";
import moment from "moment";
import {
  independentCreationOff,
  setSelectedTournamentAction,
  setShowCreateTournamentAction,
} from "../../redux/actions/tournamentAction";
import { useNavigate } from "react-router";
import Constant from "../../services/Constant";
import { changeShowSignup } from "../../redux/actions/action";
import { setSelectedLeagueAction } from "../../redux/actions/leagueAction";
const CreateTournament = ({ handleClose, showSignUpHandler }) => {
  // hooks
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const { user } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    tournament_name: "",
    players: "",
    date: "",
    city: "",
    course: "",
    HGA_coins: "",
    registration_start_date: "",
    registration_end_date: "",
    tee_time_difference: "",
    players_per_group: "4",
    is_ladies_tourney: false,
    is_away_course: false,
    is_mazor: false,
  });
  const [golfCourseSearch, setGolfCourseSearch] = useState("");
  const [golfCourseArray, setGolfCourseArray] = useState([]);
  // const [selectedGolfCourse, setSelectedGolfCourse] = useState({});
  const [selectedGolfCourse, setSelectedGolfCourse] = useState({ id: 2 });
  const { selectedLeague } = useSelector((state) => state.league);
  const { selectedSeason } = useSelector((state) => state.season);
  const { indepentCreationMode } = useSelector((state) => state.tournament);

  // hooks
  const dispatch = useDispatch();
  const navigator = useNavigate();
  // Api Calls
  const createTournamentCall = async (body) => {
    try {
      console.log("body::", body);
      dispatch(requestSent());
      let data = {};
      if (user) {
        data = await createTournament(body);
        dispatch(responseRecived());
        toast.success("Tournament created successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        data = await createSudoTournament(body);
        dispatch(responseRecived());
        toast.success(
          "Tournament created successfully,Please sign up to continue !",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      dispatch(setSelectedTournamentAction(data));
      dispatch(setShowCreateTournamentAction(false));
      dispatch(independentCreationOff());
      handleClose();
      setGolfCourseSearch("");
      setStartDate("");
      setRegistrationStartDate("");
      setRegistrationEndDate("");
      setSelectedGolfCourse({});
      setPage(0);
      setFormData({
        tournament_name: "",
        players: "",
        date: "",
        city: "",
        course: "",
        HGA_coins: "",
        registration_start_date: "",
        registration_end_date: "",
        tee_time_difference: "",
        players_per_group: "4",
        is_ladies_tourney: false,
        is_away_course: false,
        is_mazor: false,
      });
      // navigator('/manage-tournament')
      showSignUpHandler();
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getGolfCoursesCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getAllGolfCourse({ search: golfCourseSearch });
      setGolfCourseArray(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler
  const handelSearchChange = (val) => {
    setGolfCourseSearch(val);
    getGolfCoursesCall(val);
  };

  const handelChangeSelection = (item) => {
    console.log("item::", item);
    setSelectedGolfCourse(item);
  };
  const isValidRegistrationEndDate = (current) => {
    return (
      current.isAfter(registrationStartDate) &&
      current.isSameOrBefore(startDate)
    );
  };
  const isValidRegistrationStartDate = (current) => {
    return current.isSameOrBefore(startDate);
  };

  function handleSubmit() {
    if (page < 2) {
      setPage(page + 1);
    } else {
      // if (
      //   !formData.tournament_name ||
      //   !formData.players ||
      //   !startDate ||
      //   !formData.HGA_coins ||
      //   !registrationStartDate ||
      //   !registrationEndDate ||
      //   !formData.players_per_group ||
      //   !formData.tee_time_difference ||
      //   isObjectEmpty(selectedGolfCourse)
      // ) {
      //   dispatch(receivedError(Constant.ERRORS_LIST.FILL_ALL_FIELDS))
      //   return;
      // }
      let body = {
        tournament_name: formData.tournament_name,
        start_date: startDate,
        registration_start_date: registrationStartDate,
        registration_last_date: registrationEndDate,
        number_of_players: formData.players,
        types_of_holes: 1,
        course_id: selectedGolfCourse.id,
        //is_completed:,
        registration_fee: formData.HGA_coins,
        players_per_group: formData.players_per_group,
        tee_time_difference: formData.tee_time_difference,
        is_ladies_tourney: formData.is_ladies_tourney,
        is_away_course: formData.is_away_course,
        is_mazor: formData.is_mazor,
      };
      if (selectedLeague && !indepentCreationMode) body["league_id"] = selectedLeague.id;
      if (selectedSeason && !indepentCreationMode) body["season_id"] = selectedSeason.id;
      createTournamentCall(body);
    }
  }

  useEffect(() => {
    getGolfCoursesCall();
  }, []);

  return (
    <div className="multistep-form_cont form_common_style">
      <div className="filler_elem">
        <img src={images.fillerImg} alt="" />
        <img src={images.fillerImg} alt="" />
      </div>
      <div className="header">
        <div
          className="close"
          onClick={() => {
            dispatch(independentCreationOff());
            handleClose();
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <h2> Create New Tournament </h2>
      </div>
      <div className="progress-and-form_cont">
        <div className="progress_cont">
          <div className="progress_line">
            <div
              className={`steps ${
                page === 0 ? "filling" : page > 0 ? "completed" : ""
              }`}
            >
              1
            </div>
            <div
              className={`steps ${
                page === 1 ? "filling" : page > 1 ? "completed" : ""
              }`}
            >
              2
            </div>
            <div
              className={`steps ${
                page === 2 ? "filling" : page > 2 ? "completed" : ""
              }`}
            >
              3
            </div>
            {/*<div
              className={`steps ${
                page === 3 ? "filling" : page > 3 ? "completed" : ""
              }`}
            >
              4
            </div>*/}
            <div
              className="progress"
              style={{
                height: `${40 * page}%`,
              }}
            ></div>
          </div>
        </div>
        <div className="form_cont">
          {page == 0 ? (
            <div className="input-field_cont">
              <p>State The Name Of Your Tournament</p>
              <div className="input-elem">
                <input
                  type="text"
                  placeholder="Name Of Tournament"
                  onChange={(elem) => {
                    console.log("elem::", formData, elem);

                    setFormData({
                      ...formData,
                      tournament_name: elem.target.value,
                    });
                  }}
                  value={formData.tournament_name}
                />
              </div>
              <p>How many players will be in the tournament?</p>
              <div className="input-elem number-input-elem">
                <i className="fa-sharp fa-solid fa-people-group"></i>
                <input
                  id={2}
                  type="number"
                  placeholder="Enter No. of Players"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      players: e.target.value,
                    })
                  }
                  value={formData.players}
                />
              </div>
              <p>Registration Fee ?</p>
              <div className="input-elem number-input-elem w-100">
                <img src={icons.Coins} alt="" />
                <input
                  type="number"
                  placeholder="Enter Hga Coins"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      HGA_coins: e.target.value,
                    })
                  }
                  value={formData.HGA_coins}
                />
              </div>
            </div>
          ) : page == 1 ? (
            <div className="input-field_cont">
              <p>State the Golf Course where the Tourney will be played</p>
              <div className="mb-3">
                <DropDownWithSearch
                  title={
                    selectedGolfCourse.course_name
                      ? selectedGolfCourse.course_name
                      : "Not Selected"
                  }
                  listItems={golfCourseArray}
                  placeholder="Search Golf Course..."
                  headerOptions={["Golf Course", "Country"]}
                  handelChangeSelection={handelChangeSelection}
                  value={golfCourseSearch}
                  onChange={handelSearchChange}
                />
              </div>

              <p>Tee Time Difference(in mins)</p>
              <div className="input-elem number-input-elem w-100">
                <i className="fa-solid fa-clock"></i>
                <input
                  type="number"
                  placeholder="Enter Tee Time in mins"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tee_time_difference: e.target.value,
                    })
                  }
                  value={formData.tee_time_difference}
                />
              </div>

              <p>Players Per Group</p>
              <div className="input-elem number-input-elem w-100">
                <i className="fa-sharp fa-solid fa-people-group"></i>
                <input
                  type="number"
                  placeholder="Players per group"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      players_per_group: e.target.value,
                    })
                  }
                  value={formData.players_per_group}
                />
              </div>
            </div>
          ) : (
            <div className="input-field_cont">
              <p>What Date will the Tournament be? </p>
              <div className="input-elem number-input-elem custom-date_slector">
                <i className="fa-solid fa-calendar-days"></i>
                <DateTime
                  value={formData.date}
                  format="DD-MM-YYYY"
                  onChange={(e) => {
                    setStartDate(moment(e).format("YYYY-MM-DD HH:MM:SS"));
                    setFormData({
                      ...formData,
                      date: e,
                    });
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  closeOnSelect={true}
                />
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </div>

              <p>Registration Start Date</p>
              <div className="input-elem number-input-elem custom-date_slector">
                <i className="fa-solid fa-calendar-days"></i>
                <DateTime
                  value={formData.registration_start_date}
                  onChange={(e) => {
                    setRegistrationStartDate(
                      moment(e).format("YYYY-MM-DD HH:MM:SS")
                    );

                    setFormData({
                      ...formData,
                      registration_start_date: e,
                    });
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  className="rdtOpenTop"
                  closeOnSelect={true}
                  isValidDate={isValidRegistrationStartDate}
                />
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </div>
              <p>Registration End Date</p>
              <div className="input-elem number-input-elem custom-date_slector">
                <i className="fa-solid fa-calendar-days"></i>
                <DateTime
                  value={formData.registration_end_date}
                  onChange={(e) => {
                    setRegistrationEndDate(
                      moment(e).format("YYYY-MM-DD HH:MM:SS")
                    );

                    setFormData({
                      ...formData,
                      registration_end_date: e,
                    });
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  className="rdtOpenTop"
                  closeOnSelect={true}
                  isValidDate={isValidRegistrationEndDate}
                />
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </div>

              {/*<div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={formData.is_mazor}
                id="is_it_mazor"
                onChange={() =>
                  setFormData({
                    ...formData,
                    is_mazor: true,
                  })
                }
              />
              <label className="form-check-label" for="is_it_mazor">
                <p>Is it a Mazor?</p>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={formData.is_away_course}
                id="away_course"
                onChange={() =>
                  setFormData({
                    ...formData,
                    is_away_course: true,
                  })
                }
              />
              <label className="form-check-label" for="away_course">
                <p>Is it Away Course Tournament ?</p>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={formData.is_ladies_tourney}
                id="ladies_tournament"
                onChange={() =>
                  setFormData({
                    ...formData,
                    is_ladies_tourney: true,
                  })
                }
              />
              <label className="form-check-label" for="ladies_tournament">
                <p>Is it Ladies Tournament ?</p>
              </label>
              </div>*/}
            </div>
          )}
        </div>
      </div>
      <div className="btn_cont">
        {page > 0 ? (
          <button className="btn-style" onClick={() => setPage(page - 1)}>
            Prev
          </button>
        ) : (
          <button className="btn-style" onClick={()=>{
            dispatch(independentCreationOff())
            handleClose()
          }}>
            Close
          </button>
        )}
        <button
          className={`btn-style ${page < 2 ? "btn-orange" : "btn-green"}`}
          onClick={handleSubmit}
        >
          {page < 2 ? "Next" : "Submit"}
        </button>
      </div>
      {/* {conditionalComponent()}
      <div className="d-flex">
        {page > 0 && <button className="btn" onClick={() => setPage(page - 1)}>Back</button>}
        <button className="btn" onClick={handleSubmit}>
          {page === 0 || page === 1 ? "Next" : "Submit"}
        </button>
      </div> */}
    </div>
  );
};

export default CreateTournament;
