import React, { useEffect, useState } from "react";
import moment from "moment";
// Style //
import "./UploadScores.css";

// Components //
import { Header } from "../../../../common/containers/index";
import { Sidebar } from "../../../../common/components/index";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CSVButton from "./downloadcsv";
import PaginationComponent from "../../../../common/components/pagination/Pagination";
import Constant from "../../../../services/Constant";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import {
  deleteUploadApi,
  generateLeaderBordApi,
  getUploadHistory,
} from "../../../../api/tournamentApi";
import { toast } from "react-toastify";
const UploadScores = () => {
  const DUMMY_DATA = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  //  Hooks //
  const { selectedTournament } = useSelector((state) => state.tournament);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [uploadHistory, setUploadHistory] = useState({ count: 0, rows: [] });
  const dispatch = useDispatch();
  const [prefix, setPrefix] = useState("");
  const [buttonLoading, setButtonLoading] = useState({});
  // handelers
  const handelDelete = (uploadId, uploadIndex) => {
    deleteUploadHistoryCall(uploadId, uploadIndex);
  };
  const handelGenerateLeaderBoard = () => {
    console.log('in handelGenerateLeaderBoard')
    generateLeaderBordCall(selectedTournament.id)
  };

  // api call

  const generateLeaderBordCall = async(tournamentId) => {
    try {
      dispatch(requestSent())
      let data = await generateLeaderBordApi({tournament_id:tournamentId})
      console.log('data::',data)
      console.log('in generateLeaderBordCall')
      
      dispatch(responseRecived())

    } catch (error) {
      console.log('error::',error)
      dispatch(receivedError(error))
      dispatch(responseRecived())
    }
  }
  const deleteUploadHistoryCall = async (uploadId, uploadIndex) => {
    try {
      setButtonLoading((preState) => {
        preState[uploadId] = true;
        return preState;
      });
      let data = await deleteUploadApi({ uploadId });
      toast.success("Scores deleted successfully !");
      setUploadHistory((preState) => {
        let newRows = [...uploadHistory.rows];
        newRows[uploadIndex]["deleted"] = moment().format();
        return { ...preState, rows: newRows };
      });

      setButtonLoading((preState) => {
        let newState = { ...preState };
        newState[uploadId] = false;
        return newState;
      });
    } catch (error) {
      console.log("error::", error);

      setButtonLoading((preState) => {
        let newState = { ...preState };
        newState[uploadId] = false;
        return newState;
      });

      dispatch(receivedError(error));
    }
  };

  const getUploadHistoryCall = async () => {
    try {
      dispatch(requestSent());
      let data = await getUploadHistory({
        tournamentId: selectedTournament.id,
        limit,
        pageNo,
      });
      setUploadHistory(data.data);
      let newButtonLoading = {};
      data.data.rows.forEach((file) => (newButtonLoading[file.id] = false));
      setPrefix(data.prefix);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // useEffect
  useEffect(() => {
    getUploadHistoryCall();
  }, [limit, pageNo]);

  return (
    <div>
      <Header heading={selectedTournament.t_name} />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar inMangeTournament={true} />
        </div>
        <div className="manage_sec_page_cont d-flex flex-column justify-content-between">
          <div>
            <div className="header d-flex justify-content-between">
              <h2>Uploaded Scores History</h2>
              {selectedTournament.tournament_leaderboards && selectedTournament.tournament_leaderboards.length > 0 ?(''):(<div className="d-flex flex-row">
              <button
                className="btn-style btn-orange mx-2"
                onClick={handelGenerateLeaderBoard}
              >
                Generate LeaderBoard
              </button>
              <CSVButton />
              <button
                className="btn-style btn-green mx-2"
                onClick={() =>
                  navigate(
                    "/manage-tournament/upload-scores/upload-scores-file"
                  )
                }
              >
                Upload Scores
              </button>
            </div>)  }
              
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">File Name</th>
                  <th scope="col">No. Of Players</th>
                  <th scope="col"> Uploade Date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {uploadHistory.rows && uploadHistory.rows.length ? (
                  uploadHistory.rows.map((file, index) => (
                    <tr key={file.id}>
                      <td>{file.file_name}</td>
                      <td>{file.no_of_players}</td>
                      <td>
                        {file.createdAt
                          ? moment(file.createdAt).format(
                              Constant.DATE_TIME_FORMAT
                            )
                          : "-"}
                      </td>
                      <td>
                        <button className="btn-style btn-orange  mx-1" >
                        <a className="text-decoration-none text-white" href={`${prefix}${file.file_path}`}>Download</a>
                        </button>
                        {selectedTournament.tournament_leaderboards && selectedTournament.tournament_leaderboards.length > 0 ? (''): !file.deleted ? (
                          buttonLoading[file.id] ? (
                            <button className="btn-style btn-orange ">
                              <i className="fa-solid fa-circle-notch fa-spin"></i>
                            </button>
                          ) : (
                            <button
                              className="btn-style btn-orange "
                              onClick={() => {
                                handelDelete(file.id, index);
                              }}
                            >
                              Delete
                            </button>
                          )
                        ) : (
                          <button
                            className="btn-style btn-orange "
                            style={{
                              backgroundColor: "#8d3c10",
                            }}
                            onClick={() => {
                              toast.warn(
                                "Scores are already deleted for this file"
                              );
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <h3 className="text-center mt-2">
                        No Upload History found for this Tournament
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <PaginationComponent
            count={uploadHistory.count}
            limit={limit}
            setLimit={setLimit}
            pageNo={pageNo}
            setPageNo={setPageNo}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadScores;
