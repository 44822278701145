import './App.css';

// Router //
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

// BootStrap Css //
import 'bootstrap/dist/css/bootstrap.min.css';

// Router //
import { PrivateRouter } from './routes/index';

// Redux // 
import { useSelector } from "react-redux"

// Components //
import { Navbar, Footer, Loader } from "./common/components/index"

// pages //
import { SeasonStandings, TourSchedule, TournamentResult, TournamentLeaderboard, Players, HgaWallet, Landing, TourInfo, ManageSeasons, ManageStandings, RegisterPlayers, Sponsors, TournamentGroups, ScoreSheet, ManageTournament, Groups, TournamentInfo, ManageTournamentGroups, RegisterPlayersForm, MangeTournamentRegisterPlayersForm, MangeTournamentSponsor, UploadScores, UploadScoresFile, MyProfile, PlayerComparison } from "./pages/index"

// Use
import { useEffect } from 'react';

// Dispatch //
import { useDispatch } from 'react-redux';

// Reduc Actions //
import { existUser } from "./redux/actions/action"

// Toastify //
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Date Picker Css //
import "react-datetime/css/react-datetime.css";
import Constant from './services/Constant';
import GolfCourses from './pages/golf-courses/GolfCourses';
import GolfCourseSheet from './pages/golf-courses/GolfCourseSheet';


function App() {

  const { user } = useSelector((state) => state.user)
  const { error } = useSelector((state) => state.utils)
  useEffect(() => {
    if (error && error != '') {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error])

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar isUser={user} />
        <ToastContainer />
        <div id='page-wrapers'>
          <Routes>
            {/*<Route path='/score-sheet' element={<ScoreSheet />} />*/}
            <Route path='/' >
              <Route path='/' element={<Landing />} />
              <Route path='/standing' element={<PrivateRouter component={<SeasonStandings />} redirectTo={'/'} />} />
              <Route path='/schedule' element={<PrivateRouter component={<TourSchedule />} redirectTo={'/'} />} />
              <Route path='/results' element={<PrivateRouter toOutlet={true} redirectTo={'/'} />}>
                <Route index path="/results/" element={<TournamentResult />} />
                <Route path='/results/tournament' element={<TournamentLeaderboard />} />
                <Route path='/results/tournament-groups' element={<TournamentGroups />} />
                <Route path='/results/groups-scorecard' element={<ScoreSheet />} />
              </Route>
              <Route path='/manage-my-tournament' element={<ManageTournament />} />
              <Route path='/players' element={<PrivateRouter component={<Players />} redirectTo={'/'} />} />
              <Route path='/wallet' element={<HgaWallet />} />
              <Route path='/groups' element={<PrivateRouter component={<Groups />} redirectTo={'/'} />} />
              <Route path='/manage-tour' >
                <Route path="/manage-tour" element={<Navigate to="/manage-tour/tour-info" replace />} />
                <Route path='/manage-tour/tour-info' element={<TourInfo />} />
                <Route path='/manage-tour/seasons' element={<ManageSeasons />} />
                <Route path='/manage-tour/register-player' >
                  <Route path='/manage-tour/register-player' element={<RegisterPlayers />} />
                 {/* <Route path='/manage-tour/register-player/form' element={<RegisterPlayersForm />} /> */}
                </Route>
                <Route path='/manage-tour/standings' element={<ManageStandings />} />
                <Route path='/manage-tour/sponsors' element={<Sponsors />} />
              </Route>
              <Route path='/manage-tournament' element={<PrivateRouter toOutlet={true} redirectTo={'/'} />}>
                <Route path='/manage-tournament' element={<Navigate to="/manage-tournament/tournament-info" replace />} />
                <Route path='/manage-tournament/tournament-info' element={<TournamentInfo />} />
                <Route path='/manage-tournament/groups' element={<ManageTournamentGroups />} />
                <Route path='/manage-tournament/register-player' element={<MangeTournamentRegisterPlayersForm />} />
                <Route path='/manage-tournament/sponsors' element={<MangeTournamentSponsor />} />
                <Route path='/manage-tournament/upload-scores'>
                  <Route path='/manage-tournament/upload-scores' element={<UploadScores />} />
                  <Route path='/manage-tournament/upload-scores/upload-scores-file' element={<UploadScoresFile />} />
                </Route>
              </Route>
              <Route path='/profile' element={<MyProfile />} />
            </Route>
            
            <Route path='/player-comparesion' element={<PlayerComparison />} />
            <Route path='/golf-courses'>
                  <Route path='/golf-courses' element={<GolfCourses />} />
                  <Route path='/golf-courses/course-sheet' element={<GolfCourseSheet />} />
            </Route>
            

          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;