import React, { useEffect, useState } from "react";
import moment from "moment";
// Style //
import "./Groups.css";

// Container //
import { Header, ModalContainer } from "../../../../common/containers/index";

// Components //
import { Sidebar, SkLoader } from "../../../../common/components/index";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import { getAllSeasons } from "../../../../api/seasonApi";
import {
  setAllSeasonsAction,
  setSelectedSeasonAction,
} from "../../../../redux/actions/seasonAction";
import Constant from "../../../../services/Constant";
import { useNavigate } from "react-router";
import { getTournamentGroups } from "../../../../api/tournamentApi";
import CreateGroup from "./CreateGroup";

const ManageTournamentGroups = () => {
  // Hooks

  const { selectedTournament } = useSelector((state) => state.tournament);
  const { loading } = useSelector((state) => state.utils);

  const [tournamentGroups, setTournamentGroups] = useState([]);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [showCreateGroup, setShowCreartGroup] = useState(false);

  // Api Calls
  const getTournamentGroupsCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getTournamentGroups(selectedTournament.id);
      setTournamentGroups(data);
      dispatch(responseRecived());
      console.log("tournamentGroups::", data);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handelers
  const handelDetailsOnClick = (group) => {
    navigator("/groups");
  };
  

  // useEffect
  useEffect(() => {
    getTournamentGroupsCall();
  }, []);

  return (
    <div>
      <Header heading={selectedTournament.t_name}/>
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar inMangeTournament={true} />
        </div>
        <div className="manage_sec_page_cont" id="manage-seasons">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Groups and Registration</h2>
            <button
              className="btn-style btn-green"
              onClick={() => {
                setShowCreartGroup(true);
              }}
            >
              Add Group
            </button>
          </div>
          {loading ? (
            <SkLoader limit={8} height={30} mt={2} />
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Group</th>
                  <th scope="col">Number Of Players</th>
                  <th scope="col">Tee Time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tournamentGroups.map((group) => (
                  <tr key={group.id}>
                    <td>{group.name ? group.name : "-"}</td>

                    <td>{group.players ? group.players.length : '-'}</td>
 
                    <td>
                      {group.tee_time
                        ? moment(group.tee_time).format(
                            Constant.DATE_TIME_FORMAT
                          )
                        : "-"}
                    </td>

                    <td>
                      <button
                        className="btn-style btn-orange"
                        onClick={() => {
                          handelDetailsOnClick(group);
                        }}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
            
          )}
          {tournamentGroups && tournamentGroups.length==0 ? (<h2 className="text-center">No groups Found Please Create Groups First</h2>):('')}
        </div>
        <ModalContainer show={showCreateGroup}>
          <CreateGroup
            handleClose={() => {
              setShowCreartGroup(false);
            }}
            getTournamentGroupsCall={getTournamentGroupsCall}
          />
        </ModalContainer>
      </div>
    </div>
  );
};

export default ManageTournamentGroups;
