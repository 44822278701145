// Style //
import "./DropDownWithSearch.css";

// Component //
import { UserProfile } from "../index";
import React, { useState, useRef, useEffect } from "react";
import { capitalizeFirstLetter } from "../../../services/utils";

function DropDownWithSearch({
  listItems,
  title,
  placeholder,
  dropdownPlaceholder,
  headerOptions,
  handelChangeSelection,
  onChange,
  value,
  player,
  openTop,
  isDualResponsible = false,
  width
}) {
  const [isChecked, setIsChecked] = useState(false);

  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <React.Fragment>
      {!isDualResponsible ? (
        <React.Fragment>
          <div
            className={`${
              isChecked ? "open" : ""
            } seasons-dropdown_cont search_dropdown_cont`}
            ref={dropdownRef}
          >
            <div className="sec-center">
              <button
                className="dropdown-btn"
                onClick={() => setIsChecked(!isChecked)}
              >
                <span>
                  {title ? (
                    capitalizeFirstLetter(title)
                  ) : (
                    <span className="dropdownPlaceholder">
                      {dropdownPlaceholder}
                    </span>
                  )}
                </span>
                <i className="fa-sharp fa-solid fa-caret-down"></i>
              </button>
              <div
                className={`${isChecked ? "checked " : ""} ${
                  openTop ? "open-top" : ""
                } section-dropdown`}
              >
                <div className="search_cont">
                  <input
                    type="text"
                    placeholder={`${placeholder}`}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                  />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <div className="link_header">
                  {headerOptions?.map((header, index) => (
                    <p key={index}>{header}</p>
                  ))}
                </div>
                <div className="link_cont">
                  {player
                    ? player?.map((item, index) => (
                        <div
                          className="items items_with_players"
                          key={item.id ? item.id : index}
                          onClick={() => {
                            handelChangeSelection(item);
                            setIsChecked(!isChecked);
                          }}
                        >
                          {/* name, nickName, profileImg, linkTo */}
                          <UserProfile
                            nickName={item?.nickName}
                            name={
                              item
                                ? item.username
                                  ? item.username
                                  : item.name
                                : ""
                            }
                            profileImg={item?.profileImg}
                          />
                          <span>
                            {item && item.handicap ? item.handicap : "-"}
                          </span>
                        </div>
                      ))
                    : ""}
                  {listItems && listItems.length > 0
                    ? listItems?.map((item, index) => (
                        <div
                          className="items"
                          key={item.id}
                          onClick={() => {
                            handelChangeSelection(item);
                            setIsChecked(!isChecked);
                          }}
                        >
                          <span>{capitalizeFirstLetter(item.item1)}</span>
                          <span>{capitalizeFirstLetter(item.item2)}</span>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className={`${
              isChecked ? "open" : ""
            } seasons-dropdown_cont search_dropdown_cont`}
            ref={dropdownRef}
            style={{width:width}}
          >
            <div className="sec-center">
              <div className="search_cont" >
                <input
                  type="text"
                  
                  placeholder={`${placeholder}`}
                  onChange={(e) => {
                    if (e.target.value && !isChecked) {
                      setIsChecked(true);
                    }
                    if (!e.target.value) {
                      setIsChecked(false);
                    }
                    onChange(e.target.value);
                  }}
                  value={value}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <div
                className={`${isChecked ? "checked " : ""} ${
                  openTop ? "open-top" : ""
                } section-dropdown`}
              >
                <div className="link_header">
                  {headerOptions?.map((header, index) => (
                    <p key={index}>{header}</p>
                  ))}
                </div>
                <div className="link_cont">
                  {player
                    ? player?.map((item, index) => (
                        <div
                          className="items items_with_players"
                          key={item.id ? item.id : index}
                          onClick={() => {
                            handelChangeSelection(item);
                            setIsChecked(!isChecked);
                          }}
                        >
                          {/* name, nickName, profileImg, linkTo */}
                          <UserProfile
                            nickName={item?.nickName}
                            name={
                              item
                                ? item.username
                                  ? item.username
                                  : item.name
                                : ""
                            }
                            profileImg={item?.profileImg}
                          />
                          <span>
                            {item && item.handicap ? item.handicap : "-"}
                          </span>
                        </div>
                      ))
                    : ""}
                  {listItems && listItems.length > 0
                    ? listItems?.map((item, index) => (
                        <div
                          className="items"
                          key={item.id}
                          onClick={() => {
                            handelChangeSelection(item);
                            setIsChecked(!isChecked);
                          }}
                        >
                          <span>{capitalizeFirstLetter(item.item1)}</span>
                          <span>{capitalizeFirstLetter(item.item2)}</span>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default DropDownWithSearch;
