import axios from "axios";

// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";

export const getAllLeagues = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      if (token) {
        let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-all-leagues`;
        let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;

        let response = await axios.get(url, {
          headers: {
            Authorization: bearerToken,
          },
          params,
        });
        let data = response.data.data.map((league, index) => {
          return {
            item1: league.league_name ? league.league_name.toUpperCase() : '-',
            item2: league.country ? league.country.toUpperCase() : '-',
            id: league.id,
          };
        });
        resolve(data);
      }
      

    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};

export const getMyLeagues = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      if (token) {
        let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-my-leagues`;
        let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;

        let response = await axios.get(url, {
          headers: {
            Authorization: bearerToken,
          },
        });

        resolve(response.data.data);
      }
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};

export const getSelectedLeague = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      if(token){
        let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-selected-league`;
        let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
  
        let response = await axios.get(url, {
          headers: {
            Authorization: bearerToken,
          },
          params,
        });
  
        resolve(response.data.data);
      }
      
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};

export const createLeague= (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-league`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const updateLeague= (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/update-league`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.patch(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};
export const addManagersApi= (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/add-managers`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const createSudoLeague = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-sudo-league`;
      
      let response = await axios.post(url, body, {
        withCredentials: true,
        crossDomain: true,
        header: {
          "Content-Type": "application/x-www-form-urlencoded",
          Origin: "http://localhost:3000",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const getTourDetails = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      if(token){
        let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/tour-for-manage`;
        let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
        let response = await axios.get(url, {
          headers: {
            Authorization: bearerToken,
          },
          params,
        });
  
        resolve(response.data.data);
      }
      
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
