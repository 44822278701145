import axios from "axios";

// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";

export const sendErrorMail = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
        const token = localStorage.getItem("reset_token");
        if(token){
          let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/send-error-mail`;
          let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
       
          let response = await axios.get(url, {
            headers: {
            
            },
            params,
          });
    
          resolve(response.data.data);
        }
        
      }catch (error) {
        console.log("error::",error);
        let errorMessage = await catchHandle(error)
        reject(errorMessage)
    }
  });
};

export const getSettings = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-settings`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};
