import axios from "axios";

// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";

export const getSeasonStanding = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/show-leaderboard`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};

export const getScoreSheet = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/show-scorecard`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      console.log("data::",response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};
