import { signUp } from "../../api/auth";
import { actionType } from "../actionType";

const initialState = {
  loading: false,
  user: null,
  error: null,
  showLogin: false,
  showSignup: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    // ================= Sign Up ===============//

    case actionType.SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SHOW_SINGUP:
      return {
        ...state,
        showSignup: action.payload,
      };

    case actionType.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case actionType.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };

    // ==================== Log In =================//

    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case actionType.SHOW_LOGIN:
      return {
        ...state,
        showLogin: action.payload,
      };

    case actionType.LOG_OUT:
      return {
        ...state,
        user: action.payload,
      };

    // ==================== Existing User =================//

    default:
      return state;
  }
}

export default userReducer;
