import React, { useEffect, useState } from "react";

// Style //
import "./TournamentGroups.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsAction";
import { getTournamentGroupsWithWinningDetails } from "../../../api/tournamentApi";

import { toast } from "react-toastify";
import { Loader, UserProfile } from "../../../common/components";
import { Header, Wraper } from "../../../common/containers";
import { useNavigate, useSearchParams } from "react-router-dom";
import Constant from "../../../services/Constant";
export default function TournamentGroups() {
  // hooks
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.utils);
  const [tournamentGroups, setTournamentGroups] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  //api calls
  const getTournamentGroupsCall = async () => {
    try {
      const tournamentId = searchParams.get("tournamentId");
      console.log("tournamentId::", tournamentId);
      dispatch(requestSent());
      const data = await getTournamentGroupsWithWinningDetails(tournamentId);
      setTournamentGroups(data);
      dispatch(responseRecived());
      console.log("tournamentGroups::", data);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler

  // useEffects
  useEffect(() => {
    getTournamentGroupsCall();
  }, []);
  return (
    <div id="tournament_leader_board">
      {loading ? <Loader /> : ""}

      <Header
        floatingElem={true}
        heading={tournamentGroups.t_name ? tournamentGroups.t_name : '-'}
        subHeading={
          tournamentGroups.golf_course
            ? tournamentGroups.golf_course.course_name
              ? tournamentGroups.golf_course.course_name
              : '-'
            : '-'
        }
      >
        <div className="floating_cont">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Players</th>
                <th scope="col">Groups</th>
                <th scope="col">Champions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {tournamentGroups
                    ? moment(tournamentGroups.start_date).format("DD/MM/YYYY")
                    : '-'}
                </td>
                <td>
                  {tournamentGroups && tournamentGroups.tournament_leaderboards
                    ? tournamentGroups.tournament_leaderboards.length
                    : '-'}
                </td>
                <td>
                  {tournamentGroups && tournamentGroups.group_details
                    ? tournamentGroups.group_details.length
                    : '-'}
                </td>
                <td>
                  {tournamentGroups.winnerData ? (
                    <UserProfile
                      name={
                        tournamentGroups.winnerData.username
                          ? tournamentGroups.winnerData.username
                          : "Na"
                      }
                      nickName={
                        tournamentGroups.winnerData.email
                          ? tournamentGroups.winnerData.email
                          : "Na"
                      }
                      redirection={true}
                      userId={tournamentGroups.winnerData.id}
                    />
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Header>

      <Wraper
      >
        <table className="table table-striped group-table">
          <thead>
            <tr>
              
              <th scope="col">Group</th>
              <th scope="col">Players</th>
              <th scope="col">Tee Time</th>
              <th scope="col">Scorecard</th>
            </tr>
          </thead>
          <tbody>
            {tournamentGroups.group_details &&
            tournamentGroups.group_details.length > 0
              ? tournamentGroups.group_details.map((group, index) => {
                  return (
                    <tr className={`tournament-schedules`} key={group.id}>
                      
                      <td>{group.name ? group.name : '-'}</td>
                      <td>
                        {group.no_of_players ? group.no_of_players : "0"}
                      </td>
                      <td>{group.tee_time ? moment(group.tee_time).format(Constant.DATE_TIME_FORMAT) : '-'}</td>
                      <td>
                        <button
                          className="btn-orange btn-style"
                          onClick={() => {
                            navigator(
                              `/results/groups-scorecard?groupId=${group.id}&tournamentId=${group.tournament_id}`
                            );
                          }}
                        >
                          Show Scorecard
                        </button>
                      </td>
                    </tr>
                  );
                })
              : "Scores Not Updated Yet !!"}
          </tbody>
        </table>
      </Wraper>
    </div>
  );
}
