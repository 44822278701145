import React, { useState, useEffect } from "react";
import moment from "moment";
// Style //
import "./MyProfile.css";
// Components //
import {
  DropDownWithSearch,
  DropdownList,
  InputField,
  InputFieldV2,
  SkLoader,
} from "../../common/components";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { ModalContainer } from "../../common/containers";
import { images } from "../../assets/index";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { fetchPlayerStats, getPlayer, updatePlayer } from "../../api/usersApi";
import Constant from "../../services/Constant";
import { getAllGolfCourse } from "../../api/golfCourseApi";
import { getAllLeagues } from "../../api/leagueApi";
import { toast } from "react-toastify";
import { isObjectEmpty } from "../../services/utils";

const MyProfile = () => {
  const [percentage, setPercentage] = useState("0%");
  const [updateModal, setUpdateModal] = useState(false);
  const [isEditProfilePic, setIsEditProfilePic] = useState(false);
  const dispatch = useDispatch();
  const [playerDetail, setPlayerDetails] = useState({});
  const [formData, setFormData] = useState({});
  const [selectedGolfCourse, setSelectedGolfCourse] = useState({ id: 2 });
  const [golfCourseArray, setGolfCourseArray] = useState([]);
  const [golfCourseSearch, setGolfCourseSearch] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState({
    id: null,
    league_name: "Not Selected",
  });

  const [allLeagues, setAllLeagues] = useState([
    {
      id: null,
      league_name: "Not Selected",
      item1: "Not Selected",
      item2: "-",
    },
  ]);
  const [search, setSearch] = useState("");
  const { allSeasons, selectedSeason: globalSelectedSeason } = useSelector(
    (state) => state.season
  );
  const [selectedSeason, setSelectedSeason] = useState(globalSelectedSeason);
  const [playerStats, setPlayerStats] = useState({});
  const [playerStatsLoading, setPlayerStatsLoading] = useState(false);

  // handler
  const handelSearchChange = (val) => {
    setGolfCourseSearch(val);
    getGolfCoursesCall(val);
  };

  const handelChangeSelection = (item) => {
    console.log("item::", item);
    setSelectedGolfCourse(item);
  };
  const handelDropDownOnClick = (season) => {
    setSelectedSeason(season);
  };
  const handelSearchLeagueChange = (val) => {
    setSearch(val);
    getAllLeaguesCall(val);
  };

  const handelChangeSelectionLeague = (item) => {
    let selected = { id: item.id, league_name: item.item1 };
    setSelectedLeague(selected);
  };
  // apiCalls
  const getPlayerDetailsCall = async () => {
    try {
      dispatch(requestSent());
      let data = await getPlayer();
      let total = 0;
      let filled = 0;
      Object.keys(data).forEach((key) => {
        total += 1;
        if (data[key]) {
          filled += 1;
        }
      });
      let p = Math.ceil((filled * 100) / total);
      let sp = `${p}%`;
      setPercentage(sp);
      setPlayerDetails(data);
      setFormData(data);
      if(data.homeCourse){
        setSelectedGolfCourse(data.homeCourse)
      }
      if(data.homeLeague){
        setSelectedLeague(data.homeLeague)
      }
      console.log("data::", data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getGolfCoursesCall = async () => {
    try {
      setButtonLoading(true);
      const data = await getAllGolfCourse({ search: golfCourseSearch });
      setGolfCourseArray(data);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const getAllLeaguesCall = async (val = "") => {
    try {
      setButtonLoading(true);
      const data = await getAllLeagues({ search: val });
      console.log("data::", data);
      setAllLeagues([
        {
          id: null,
          item1: "Not Selected",
          item2: "-",
          league_name: "Not Selected",
        },
        ...data,
      ]);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError());
      setButtonLoading(false);
    }
  };

  const updateProfileCall = async () => {
    try {
      setButtonLoading(true);
      let details = {
        ...formData,
        playerId: formData.id,
        home_tour: selectedLeague.id,
        home_golf_club: selectedGolfCourse.id,
      };
      delete details.id;
      delete details.wallet;
      delete details.createdAt;
      delete details.updatedAt;
      delete details.homeLeague;
      delete details.homeCourse;

      let data = await updatePlayer(details);
      toast.success("Player updated successfully");
      setUpdateModal(false);
      getPlayerDetailsCall();
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      setButtonLoading(false);
      dispatch(receivedError(error));
    }
  };

  const fetchPlayerStatsCall = async (seasonId) => {
    try {
      setPlayerStatsLoading(true);
      let data = await fetchPlayerStats({ seasonId });
      console.log("data::a", data);
      if (data) {
        setPlayerStats(data);
      } else {
        setPlayerStats({});
      }
      setPlayerStatsLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setPlayerStatsLoading(false);
    }
  };

  // useEffect
  useEffect(() => {
    if (updateModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [updateModal]);

  useEffect(() => {
    getPlayerDetailsCall();
    getGolfCoursesCall();
    getAllLeaguesCall();
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(selectedSeason)) {
      fetchPlayerStatsCall(selectedSeason.id);
    } else if (!isObjectEmpty(globalSelectedSeason)) {
      fetchPlayerStatsCall(globalSelectedSeason.id);
    }
  }, [selectedSeason]);

  return (
    <>
      <ModalContainer show={updateModal}>
        <div className="form_common_style update_profile_modal">
          <div className="orange_dots">
            <img src={images.fillerImg} className="dots_btm" alt="" />
            <img src={images.fillerImg} className="dots_top" alt="" />
          </div>
          <div className="header">
            <h2>Update Profile</h2>
          </div>
          <div className="form_cont">
            <div className="update_top_sec d-flex justify-content-between">
              <div className="update_profile_image">
                {isEditProfilePic ? (
                  <div className="buttons_cont">
                    <label className="edit" htmlFor="edit-profile">
                      <i className="fa-solid fa-pencil"></i>
                    </label>
                    <input id="edit-profile" name="edit-profile" type="file" />
                    <label className="delete">
                      <i className="fa-solid fa-trash-can"></i>
                    </label>
                  </div>
                ) : (
                  ""
                )}

                <img
                  onClick={() => setIsEditProfilePic(!isEditProfilePic)}
                  src="https://i.insider.com/60a51f80e25d05001880c6ec?width=1136&format=jpeg"
                  alt=""
                />
              </div>
              <div style={{ "margin-right": "4rem" }}>
                <InputFieldV2
                  placeholder={"Update Your Quote"}
                  value={formData.quote}
                  onChange={(val) => {
                    setFormData({ ...formData, quote: val });
                  }}
                  textArea={true}
                  width={"47.5vw"}
                  height={"100%"}
                />
              </div>
            </div>
            <div className="update_btm_sec ">
              <div className="d-flex justify-content-between">
                <div className="info_cont mt-5 ">
                  <div className="info_header">
                    <div className="line"></div>
                    <p>Main Info</p>
                    <div className="line"></div>
                  </div>
                  <div className="info_field">
                    <p className="key">Full Name </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Full Name"}
                        onChange={(val) => {
                          setFormData({ ...formData, name: val });
                        }}
                        value={formData.name}
                      />
                    </p>
                  </div>

                  <div className="info_field">
                    <p className="key">Nick Name </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Nick Name"}
                        value={formData.username}
                        onChange={(val) => {
                          setFormData({ ...formData, username: val });
                        }}
                      />
                    </p>
                  </div>
                  <div className="info_field">
                    <p className="key">Residential Address </p>
                    <p className="value">
                      <InputFieldV2
                        value={formData.address}
                        placeholder={"Update Residential Address"}
                        onChange={(val) => {
                          setFormData({ ...formData, address: val });
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div
                  className="info_cont mt-5 "
                  style={{ "margin-right": "4rem" }}
                >
                  <div className="info_header">
                    <div className="line"></div>
                    <p>Contact Info</p>
                    <div className="line"></div>
                  </div>
                  <div className="info_field">
                    <p className="key">Mobile No.</p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Movile No."}
                        value={formData.phone}
                        onChange={(val) => {
                          setFormData({ ...formData, phone: val });
                        }}
                      />
                    </p>
                  </div>
                  <div className="info_field">
                    <p className="key">Email </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Email"}
                        value={formData.email}
                        onChange={(val) => {
                          setFormData({ ...formData, email: val });
                        }}
                      />
                    </p>
                  </div>

                  <div className="info_field">
                    <p className="key">Residential Country </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Residential Country"}
                        value={formData.country}
                        onChange={(val) => {
                          setFormData({ ...formData, country: val });
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="info_cont mt-5">
                  <div className="info_header">
                    <div className="line"></div>
                    <p>Personal Info</p>
                    <div className="line"></div>
                  </div>
                  <div className="info_field " id="update-player">
                    <p className="key">Date Of Birth </p>
                    <div className="input_field_cont  number-input-elem custom-date_slector w-50">
                      <DateTime
                        value={formData.dob}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            dob: moment(val).format("YYYY-MM-DD"),
                          });
                        }}
                        format="DD-MM-YYYY"
                        inputProps={{
                          placeholder: "Select Date",
                          readOnly: true,
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                      />
                      <i className="fa-sharp fa-solid fa-caret-down"></i>
                    </div>
                  </div>
                  <div className="info_field">
                    <p className="key">Country Of Birth </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Country Of Birth"}
                        value={formData.birth_country}
                        onChange={(val) => {
                          setFormData({ ...formData, birth_country: val });
                        }}
                      />
                    </p>
                  </div>
                  <div className="info_field">
                    <p className="key">
                      Favorite Club
                      <span>( eg- Putter / Driver / Ir.on )</span>
                    </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Golf Club"}
                        value={formData.fav_golf_club}
                        onChange={(val) => {
                          setFormData({ ...formData, fav_golf_club: val });
                        }}
                      />
                    </p>
                  </div>
                  <div className="info_field">
                    <p className="key">Favorite Player All Time </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Favorite Player"}
                        value={formData.fav_player_all_time}
                        onChange={(val) => {
                          setFormData({
                            ...formData,
                            fav_player_all_time: val,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="info_field">
                    <p className="key">
                      Favorite Player <span>(to watch currently)</span>
                    </p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Favorite Player"}
                        value={formData.fav_player}
                        onChange={(val) => {
                          setFormData({ ...formData, fav_player: val });
                        }}
                      />
                    </p>
                  </div>

                  <div className="info_field">
                    <p className="key">Gaming Club Brands</p>
                    <p className="value">
                      <InputFieldV2
                        placeholder={"Update Gaming Club Brands"}
                        value={formData.club_brand}
                        onChange={(val) => {
                          setFormData({ ...formData, club_brand: val });
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div
                  className="info_cont mt-5 "
                  style={{ "margin-right": "4rem" }}
                >
                  <div className="info_header">
                    <div className="line"></div>
                    <p>Home Club</p>
                    <div className="line"></div>
                  </div>
                  <div className="info_field">
                    <p className="key">Home Golf Club </p>
                    <div className="zindex-dropdown">
                      <DropDownWithSearch
                        title={
                          selectedGolfCourse.course_name
                            ? selectedGolfCourse.course_name
                            : "Not Selected"
                        }
                        listItems={golfCourseArray}
                        placeholder="Search Golf Course..."
                        headerOptions={["Golf Course", "Country"]}
                        handelChangeSelection={handelChangeSelection}
                        value={golfCourseSearch}
                        onChange={handelSearchChange}
                      />
                    </div>
                  </div>
                  <div className="info_field">
                    <p className="key">Home Tour / League</p>
                    <DropDownWithSearch
                      title={selectedLeague.league_name}
                      listItems={allLeagues}
                      placeholder="Search League..."
                      headerOptions={["Leagues", "Country"]}
                      handelChangeSelection={handelChangeSelectionLeague}
                      value={search}
                      onChange={handelSearchLeagueChange}

                      // onChange={setSearchDropDownValue}
                    />
                  </div>
                  <div className="info_field">
                    <p className="key">Home Club HDCP </p>
                    <p className="value">
                      <InputFieldV2
                        width={"5vw"}
                        value={formData.home_club_hdcp}
                        onChange={(val) => {
                          setFormData({ ...formData, home_club_hdcp: val });
                        }}
                      />
                    </p>
                  </div>
                  <div className="info_field">
                    <p className="key">Ghin </p>
                    <p className="value">
                      <InputFieldV2
                        width={"5vw"}
                        value={formData.ghin}
                        onChange={(val) => {
                          setFormData({ ...formData, ghin: val });
                        }}
                      />
                    </p>
                  </div>

                  <div className=" mt-5">
                    <div className="info_header">
                      <div className="line"></div>
                      <p>Special Fields</p>
                      <div className="line"></div>
                    </div>

                    <div className="info_field">
                      <p className="key">Job Title </p>
                      <p className="value">
                        <InputFieldV2
                          placeholder={"Update Job Title"}
                          value={formData.job_title}
                          onChange={(val) => {
                            setFormData({ ...formData, job_title: val });
                          }}
                        />
                      </p>
                    </div>

                    <div className="info_field">
                      <p className="key">Job Market place </p>
                      <p className="value">
                        <InputFieldV2
                          placeholder={"Update Job Market place"}
                          value={formData.job_place}
                          onChange={(val) => {
                            setFormData({ ...formData, job_place: val });
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_cont">
            <button
              className="btn-style btn-orange"
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </button>
            {buttonLoading ? (
              <button className="btn-style btn-green">
                {" "}
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              </button>
            ) : (
              <button
                className="btn-style btn-green"
                onClick={() => {
                  updateProfileCall();
                }}
              >
                {" "}
                Save Changes{" "}
              </button>
            )}
          </div>
        </div>
      </ModalContainer>
      <div className="profile_top_sec_wraper">
        <div
          className="lft"
          style={{
            width: "20%",
          }}
        >
          <div className="profile_img">
            <img
              src="https://www.gannett-cdn.com/-mm-/cbee42056681c8e0d9f20671152b69632694d1e8/c=0-196-3779-2322/local/-/media/2020/11/21/Savannah/ghows_gallery_ei-GA-200709618-35b670d0.jpg?width=1200&disable=upscale&format=pjpg&auto=webp"
              alt=""
            />
          </div>
          <div className="progress_cont">
            <div className="progressBar">
              <div
                className="progress-bar"
                style={{
                  background: `radial-gradient(closest-side, #16232B 79%, transparent 80% 100%), conic-gradient(#7E9D10 ${percentage}, #303D45 0)`,
                }}
              >
                <span>{percentage}</span>
              </div>
            </div>
            <h2>Complete Your Profile</h2>
          </div>
          <div className="btn_cont">
            <button className="btn-style btn-green">Switch League</button>
            <button
              className="btn-style btn-orange"
              onClick={() => setUpdateModal(true)}
            >
              Update Profile
            </button>
          </div>
        </div>
        <div className="mid" style={{ width: "35%" }}>
          <div className="user_name_and_quot_cont">
            <h1>{playerDetail.name ? playerDetail.name : "-"}</h1>
            <p>@{playerDetail.username ? playerDetail.username : "-"}</p>
            <p className="quats">
              <i>
                {playerDetail.quote
                  ? playerDetail.quote
                  : Constant.CONTENT.QUOTE_BOX}
              </i>
            </p>
          </div>
          <div className="info_cont mt-5 w-100">
            <div className="info_header">
              <div className="line"></div>
              <p>Contact Info</p>
              <div className="line"></div>
            </div>
            <div className="info_field">
              <p className="key">Mobile No. </p>
              <p className="value">
                {playerDetail.phone ? playerDetail.phone : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Email </p>
              <p className="value">
                {playerDetail.email ? playerDetail.email : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Residential Address </p>
              <p className="value">
                {playerDetail.address ? playerDetail.address : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Residential Country</p>
              <p className="value">
                {playerDetail.country ? playerDetail.country : "-"}
              </p>
            </div>
          </div>
        </div>
        <div
          className="rgt"
          style={{
            width: "33%",
            borderLeft: ".2rem solid",
            borderColor: "#7E9D10",
            paddingLeft: "1rem",
          }}
        >
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Stats</h2>
            <DropdownList
              listItems={allSeasons}
              title={
                selectedSeason.name ? selectedSeason.name : "Select Season"
              }
              handelOnClick={handelDropDownOnClick}
            />
          </div>
          <div className="stats_cont">
            {playerStatsLoading ? (
              <div className="mt-4">
                <SkLoader limit={7} mt={2} height={40} />
              </div>
            ) : (
              <React.Fragment>
                <div className="stats d-flex">
                  <p>Season Victories </p>
                  <p>
                    {playerStats.season_victories
                      ? playerStats.season_victories
                      : 0}
                  </p>
                </div>
                <div className="stats d-flex">
                  <p>Season Top3s </p>
                  <p>
                    {playerStats.season_top3s ? playerStats.season_top3s : 0}
                  </p>
                </div>
                <div className="stats d-flex">
                  <p>Scoring Avg </p>
                  <p>
                    {playerStats.season_score_average
                      ? playerStats.season_score_average
                      : 0}
                  </p>
                </div>
                <div className="stats d-flex">
                  <p>Points Avg </p>
                  <p>
                    {playerStats.season_points_average
                      ? playerStats.season_points_average
                      : 0}
                  </p>
                </div>
                <div className="stats d-flex">
                  <p>Total Birdies </p>
                  <p>
                    {playerStats.total_birdies ? playerStats.total_birdies : 0}
                  </p>
                </div>
                <div className="stats d-flex">
                  <p>Total Eagles </p>
                  <p>
                    {playerStats.total_eagles ? playerStats.total_eagles : 0}
                  </p>
                </div>
                <div className="stats d-flex">
                  <p>Holes In Ones </p>
                  <p>
                    {playerStats.total_hole_in_ones
                      ? playerStats.total_hole_in_ones
                      : 0}
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="profile_top_sec_wraper">
        <div className="more_info_cont d-flex w-100 justify-content-between">
          <div className="info_cont">
            <div className="info_header">
              <div className="line"></div>
              <p>Personal Info</p>
              <div className="line"></div>
            </div>
            <div className="info_field">
              <p className="key">Date of Birth </p>
              <p className="value">
                {playerDetail.dob
                  ? moment(playerDetail.dob).format(Constant.DATE_FORMAT)
                  : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Country Of Birth </p>
              <p className="value">
                {playerDetail.birth_country ? playerDetail.birth_country : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">
                Favorite Golf Club <span>( eg- Putter / Driver / Iron )</span>
              </p>
              <p className="value">
                {playerDetail.fav_golf_club ? playerDetail.fav_golf_club : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">
                Favorite Player <span>(to watch currently)</span>
              </p>
              <p className="value">
                {playerDetail.fav_golf_club ? playerDetail.fav_golf_club : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Hole In Ones </p> <p className="value">2</p>
            </div>
            <div className="info_field">
              <p className="key">Gaming Club Brands </p>
              <p className="value">
                {playerDetail.club_brand ? playerDetail.club_brand : "-"}
              </p>
            </div>
          </div>
          <div className="info_cont">
            <div className="info_header">
              <div className="line"></div>
              <p>Special Fields</p>
              <div className="line"></div>
            </div>
            <div className="info_field">
              <p className="key">Job Title</p>
              <p className="value">
                {playerDetail.job_title ? playerDetail.job_title : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Job Market place </p>
              <p className="value">
                {playerDetail.job_place ? playerDetail.job_place : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Annual Golf Rounds Played</p>
              <p className="value">
                {playerDetail.annual_rounds ? playerDetail.annual_rounds : "-"}{" "}
                <span>( Estimate )</span>
              </p>
            </div>
          </div>
          <div className="info_cont">
            <div className="info_header">
              <div className="line"></div>
              <p>Home Club</p>
              <div className="line"></div>
            </div>
            <div className="info_field">
              <p className="key">Home Golf Club </p>
              <p className="value">
                {selectedGolfCourse.course_name ? selectedGolfCourse.course_name : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Home Tour / League </p>
              <p className="value">
                {selectedLeague.league_name ? selectedLeague.league_name : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Home Club HDCP </p>{" "}
              <p className="value">
                {playerDetail.home_club_hdcp
                  ? playerDetail.home_club_hdcp
                  : "-"}
              </p>
            </div>
            <div className="info_field">
              <p className="key">Ghin </p>{" "}
              <p className="value">
                {playerDetail.ghin ? playerDetail.ghin : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
