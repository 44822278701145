import React, { useEffect, useState } from "react";
import "./SelectLeague.css";
import { images } from "../../assets";
import {
  getAllLeagues,
  getMyLeagues,
  getSelectedLeague,
} from "../../api/leagueApi";

import {
  getAllSeasons
} from "../../api/seasonApi";
// Toast //
import { toast } from "react-toastify";

// useDispatch //
import { useDispatch, useSelector } from "react-redux";

// components //
import { DropDownWithSearch, Loader } from "../../common/components";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";

// Redux Action //
import { setSelectedLeagueAction } from "../../redux/actions/leagueAction";
import {setSelectedSeasonAction,setAllSeasonsAction } from "../../redux/actions/seasonAction"
import {setSelectedTournamentAction} from "../../redux/actions/tournamentAction"

import { useNavigate } from "react-router";
import { setSelectedInfoToBackend } from "../../api/auth";

export default function SelectLeague({ handleClose }) {
  // useState
  const [allLeagues, setAllLeagues] = useState([
    {
      id: null,
      league_name: "Not Selected",
      item1:"Not Selected",
      item2:'-',
    },
  ]);
  const [myLeagues, setMyLeagues] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedLeague, setSelectedLeague] = useState({
    id: null,
    league_name: "Not Selected",
  });

  // useNavigate
  const navigator = useNavigate()

  // useDispatch
  const dispatch = useDispatch();

  // useSlector
  const { error, loading } = useSelector((state) => state.utils);

  // handler
  const handelSearchChange = (val) => {
    setSearch(val);
    getAllLeaguesCall(val);
  };
  const handelChangeSelection = (item) => {
    let selected = { id: item.id, league_name: item.item1 };
    setSelectedLeague(selected);
  };
  const handelSubmit = (leagueDetails) => {
    getSelectedLeagueCall(leagueDetails);
  };

  // api calls
  const getAllLeaguesCall = async (val = "") => {
    try {
      dispatch(requestSent());
      const data = await getAllLeagues({ search: val });
      console.log("data::", data);
      setAllLeagues([
        {
          id: null,
          item1:"Not Selected",
          item2:'-',
          league_name: "Not Selected",
        },
        ...data,
      ]);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getMyLeaguesCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getMyLeagues();
      console.log("data::", data);
      setMyLeagues(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError());
      dispatch(responseRecived());
    }
  };
  const getSelectedLeagueCall = async (leagueDetails) => {
    try {
      if (leagueDetails.id) {
        dispatch(requestSent());
        let league = await getSelectedLeague({ leagueId: leagueDetails.id });
        if(!( league.tournaments && league.tournaments.length>0 && league.tournaments[0].season )){
          dispatch(responseRecived());
         return toast.error("This league don't have any active Seasons and Tournaments", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        let allSeasons = await getAllSeasons({leagueId:leagueDetails.id});
        let selectedInfoStatus = await setSelectedInfoToBackend({league_id:league.id,season_id:league.tournaments[0].season.id,tournament_id:league.tournaments[0].id})
        dispatch(setAllSeasonsAction(allSeasons))
        dispatch(setSelectedLeagueAction(league));
        dispatch(setSelectedSeasonAction(league.tournaments[0].season))
        dispatch(setSelectedTournamentAction(league.tournaments[0]))
        dispatch(responseRecived());
        navigator('/standing')
      }
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  useEffect(() => {
    getAllLeaguesCall();
    getMyLeaguesCall();
  }, []);

  const myLeaguesComponent = () => {
    console.log("leagues::", myLeagues);
    return myLeagues.map((league, index) => {
      return (
        <div key={league.id} className="league-detail" onClick={()=>{handelSubmit(league)}}>
          <p>{league.league_name ? league.league_name.toUpperCase() : '-'}</p>
          <p>{league.country ? league.country.toUpperCase() : '-'}</p>
        </div>
      );
    });
  };
  return (
    <div className="select_league_cont">
      <div className="lft">
        <div>
          <img className="dots-pair dots-pair1" src={images.fillerImg} alt="" />
        </div>

        <h5>Your Leagues</h5>
        <div className="leagues">
          {loading ? (
            <Loader />
          ) : myLeagues.length > 0 ? (
            myLeaguesComponent()
          ) : (
            "No Previous Leagues Found"
          )}
        </div>
      </div>
      <div className="cen"></div>
      <div className="rgt">
        <div className="close" onClick={()=>handleClose()}>
          {/*<i className="fa-sharp fa-solid fa-xmark"></i>*/}
        </div>

        <div>
          <img className="dots-pair dots-pair2" src={images.fillerImg} alt="" />
        </div>

        <div className="upr">
          <h5>You Can Select League</h5>
          <div className="dropdown-container">
            <DropDownWithSearch
              title={selectedLeague.league_name}
              listItems={allLeagues}
              placeholder="Search League..."
              headerOptions={["Leagues", "Country"]}
              handelChangeSelection={handelChangeSelection}
              value={search}
              onChange={handelSearchChange}
              // onChange={setSearchDropDownValue}
            />
          </div>
          <div className="btn-container">
            <button
              className="btn-style btn-orange col-12"
              onClick={()=>{handelSubmit(selectedLeague)}}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="middel"> </div>
        <div className="lwr">
          <div className="heading">
            <h5>Create Your Own League</h5>
          </div>
          <div className="btn-container">
            <button className="btn-style btn-green col-12">
              Create League
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
