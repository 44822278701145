import React from 'react'
import styles from "./InputField.module.css"

const InputField = ({placeholder, value, onChange, type, width, fontSize,isDisabled=false}) => {
  return (
     <div className={styles.field_cont} style={{
        width: width?width:"100%"
     }}>
          <input type={type?type:"text"} className={styles.input} placeholder={placeholder?placeholder:""} value={value?value:''} onChange={(e)=>onChange(e.target.value)} style={{
            fontSize: fontSize?fontSize:".9rem"
          }} disabled={isDisabled}/>
     </div>
  )
}

export default InputField