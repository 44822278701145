import { actionType } from "../actionType";

// api //
import {signUp, logIn} from "../../api/auth"
import { getSelectedLeague } from "../../api/leagueApi";

// ================ Tournament Actions =================//

export const setSelectedTournamentAction = (tournament) => {
    return {
        type: actionType.TOURNAMENT.SELECT_TOURNAMENT,
        payload:tournament
      }
}
export const setShowCreateTournamentAction = (value) => {
  return {
      type: actionType.TOURNAMENT.SHOW_CREATE_TOURNAMENT,
      payload:value
    }
}
export const independentCreationOn = () => {
  return {
      type: actionType.TOURNAMENT.INDEPENDENT_CREATION_MODE_ON
    }
}

export const independentCreationOff = () => {
  return {
      type: actionType.TOURNAMENT.INDEPENDENT_CREATION_MODE_OFF
    }
}
