import React from "react";

// Style //
import "./ScoreSheet.css";

// Images //
import { images } from "../../assets/index";

// Containers //
import { Wraper } from "../../common/containers";

// Components //
import { Loader, UserProfile } from "../../common/components/index";

// Hooks //
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Apis
import { getScoreSheet } from "../../api/leaderBoardApi";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const ScoreSheet = () => {
  // hooks
  const [totalElemWidth, setTotalElemWidth] = useState(null);
  const [scoreSheetData, setScoreSheetData] = useState([]);
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.utils);
  let [searchParams, setSearchParams] = useSearchParams();
  // api calls
  const getScoreSheetCall = async () => {
    try {
      dispatch(requestSent());
      const groupId = searchParams.get("groupId");
      const tournamentId = searchParams.get("tournamentId");
      const data = await getScoreSheet({ groupId, tournamentId });
      setScoreSheetData(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const widthOfTotalElem = useRef();

  // useEffects
  useEffect(() => {
    getScoreSheetCall();
  }, []);
  // useEffect(() => {
  //   if (widthOfTotalElem.current) {
  //     setTotalElemWidth(widthOfTotalElem?.current.offsetWidth);
  //   }
  // }, [widthOfTotalElem]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div id="score-sheet">
          <div className="header">
            <div className="floating_doted_img">
              <img className="img1" src={images.fillerImg} alt="" />
              <img className="img2" src={images.fillerImg} alt="" />
            </div>
            <div className="content">
              <div className="text_cont">
                <h2>
                  {scoreSheetData.tournamentInfo
                    ? scoreSheetData.tournamentInfo.name
                    : "-"}
                </h2>
              </div>
              <div className="text_cont">
                <h2>
                  {scoreSheetData.courseInfo
                    ? scoreSheetData.courseInfo.name
                    : "-"}
                </h2>
              </div>
              <div className="text_cont">
                <h2>
                  Group Name :{" "}
                  {scoreSheetData.groupInfo
                    ? scoreSheetData.groupInfo.name
                    : "-"}
                </h2>
              </div>
            </div>
          </div>
          <Wraper otherClassName="wraper_pading">
            <div className=" w-100">
              <table className="w-100 p-2">
                <thead className="w-100">
                  {scoreSheetData.holesInfo &&
                  scoreSheetData.holesInfo.length > 0 ? (
                    <React.Fragment>
                    <tr className="w-100">
                      <td className="col-3 text-center">Hole Number</td>
                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index < 9) {
                              return (
                                <div className="col-1">{hole.hole_no}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">F9 TOTAL</div>
                        </div>
                      </td>

                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index >= 9) {
                              return (
                                <div className="col-1">{hole.hole_no}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">B9 TOTAL</div>
                        </div>
                      </td>

                      <td className="col-1 text-center">TOTAL</td>
                    </tr>
                    <tr className="w-100">
                      <td className="col-3 text-center">Par</td>
                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index < 9) {
                              return (
                                <div className="col-1">{hole.hole_par}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">{scoreSheetData.courseInfo.front9TotalPar}</div>
                        </div>
                      </td>

                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index >= 9) {
                              return (
                                <div className="col-1">{hole.hole_par}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">{scoreSheetData.courseInfo.back9TotalPar}</div>
                        </div>
                      </td>

                      <td className="col-1 text-center">{Number(scoreSheetData.courseInfo.front9TotalPar)+Number(scoreSheetData.courseInfo.back9TotalPar)}</td>
                    </tr>
                    <tr className="w-100">
                      <td className="col-3 text-center">Men's Handicap</td>
                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index < 9) {
                              return (
                                <div className="col-1">{hole.difficulty}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">-</div>
                        </div>
                      </td>

                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index >= 9) {
                              return (
                                <div className="col-1">{hole.difficulty}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">-</div>
                        </div>
                      </td>

                      <td className="col-1 text-center">-</td>
                    </tr>
                    <tr className="w-100">
                      <td className="col-3 text-center">Women's Handicap</td>
                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index < 9) {
                              return (
                                <div className="col-1">{hole.difficulty_w}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">-</div>
                        </div>
                      </td>

                      <td className="col-4">
                        <div className="row">
                          {scoreSheetData.holesInfo.map((hole, index) => {
                            if (index >= 9) {
                              return (
                                <div className="col-1">{hole.difficulty}</div>
                              );
                            }
                          })}
                          <div className="col-3 text-center">-</div>
                        </div>
                      </td>

                      <td className="col-1 text-center">-</td>
                    </tr>
                    </React.Fragment>
                  ) : (
                    <tr>
                      <td className="col-3">Hole Number</td>

                      <td className="col-4">
                        <div className="row">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((hole, index) => {
                            if (index < 9) {
                              return <div className="col-1">{hole}</div>;
                            }
                          })}
                          <div className="col-3 text-center">F9 TOTAL</div>
                        </div>
                      </td>

                      <td className="col-4">
                        <div className="row">
                          {[10, 11, 12, 13, 14, 15, 16, 17, 18].map(
                            (hole, index) => {
                              if (index < 9) {
                                return <div className="col-1">{hole}</div>;
                              }
                            }
                          )}
                          <div className="col-3 text-center">B9 TOTAL</div>
                        </div>
                      </td>

                      <td className="col-1 text-center">TOTAL</td>
                    </tr>
                  )}
                </thead>
              </table>
            </div>
            {scoreSheetData.playersInfo &&
            scoreSheetData.playersInfo.length > 0 ? (
              <div>
                {scoreSheetData.playersInfo.map((player) => {
                  return (
                    <div key={player.id} className="w-100">
                      <table className="w-100 p-2">
                        <tbody>
                          <tr>
                            <td className="col-2" rowSpan={2}>
                              <UserProfile
                                name={player.username}
                                profileImg={""}
                                userId={player.id}
                                redirection={true}
                              />
                            </td>

                            <td className="col-1">
                              Stroke ({player.total_handicap_strokes})
                            </td>
                            <td className="col-4">
                              <div className="row">
                                {player.scores.map((score, index) => {
                                  if (index < 9) {
                                    return (
                                      <div className="col-1">
                                        {score.stroke}<sup className="fw-bold">{score.handicap_strokes ? score.handicap_strokes : ''}</sup>
                                      </div>
                                    );
                                  }
                                })}
                                <div className="col-3 text-center">{player.front9TotalStrokes} </div>
                              </div>
                            </td>

                            <td className="col-4">
                              <div className="row">
                                {player.scores.map((score, index) => {
                                  if (index >= 9) {
                                    return (
                                      <div className="col-1" >
                                        {score.stroke}<sup className="fw-bold">{score.handicap_strokes ? score.handicap_strokes : ''}</sup>
                                      </div>
                                    );
                                  }
                                })}
                                <div className="col-3 text-center">{player.back9TotalStrokes} </div>
                              </div>
                            </td>

                            <td className="col-1 text-center">
                              {Number(player.front9TotalStrokes) + Number(player.back9TotalStrokes)}
                            </td>
                          </tr>
                          <tr>
                            <td className="col-1">Points</td>

                            <td className="col-4">
                              <div className="row">
                                {player.scores.map((score, index) => {
                                  if (index < 9) {
                                    return (
                                      <div className="col-1">
                                        {score.points}
                                      </div>
                                    );
                                  }
                                })}
                                <div className="col-3 text-center">{player.front9TotalPoints} </div>
                              </div>
                            </td>

                            <td className="col-4">
                              <div className="row">
                                {player.scores.map((score, index) => {
                                  if (index >= 9) {
                                    return (
                                      <div className="col-1">
                                        {score.points}
                                      </div>
                                    );
                                  }
                                })}
                                <div className="col-3 text-center">{player.back9TotalPoints}  </div>
                              </div>
                            </td>

                            <td className="col-1 text-center">
                              {Number(player.front9TotalPoints) + Number(player.back9TotalPoints)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div >
                <div className="player_score_cont w-100 ">
                  <div className="player_profile_cont">
                    <UserProfile name={"User Name"} profileImg={""} />
                  </div>
                  <table>
                    <tbody className="w-100">
                      <tr>
                        <td className="player_table_heading">Stroke (NA)</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td
                          className="td_width"
                          style={{
                            width: totalElemWidth,
                          }}
                        >
                          '-'
                        </td>
                      </tr>
                      <tr>
                        <td className="player_table_heading">Points</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td
                          className="td_width"
                          style={{
                            width: totalElemWidth,
                          }}
                        >
                          '-'
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="player_score_cont w-100 ">
                  <div className="player_profile_cont">
                    <UserProfile name={"User Name"} profileImg={""} />
                  </div>
                  <table>
                    <tbody className="w-100">
                      <tr>
                        <td className="player_table_heading">Stroke (NA)</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td
                          className="td_width"
                          style={{
                            width: totalElemWidth,
                          }}
                        >
                          '-'
                        </td>
                      </tr>
                      <tr>
                        <td className="player_table_heading">Points</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td
                          className="td_width"
                          style={{
                            width: totalElemWidth,
                          }}
                        >
                          '-'
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="player_score_cont w-100 ">
                  <div className="player_profile_cont">
                    <UserProfile name={"User Name"} profileImg={""} />
                  </div>
                  <table>
                    <tbody className="w-100">
                      <tr>
                        <td className="player_table_heading">Stroke (NA)</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td
                          className="td_width"
                          style={{
                            width: totalElemWidth,
                          }}
                        >
                          '-'
                        </td>
                      </tr>
                      <tr>
                        <td className="player_table_heading">Points</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td className="td_width">'-'</td>
                        <td
                          className="td_width"
                          style={{
                            width: totalElemWidth,
                          }}
                        >
                          '-'
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Wraper>
        </div>
      )}
    </React.Fragment>
  );
};

export default ScoreSheet;
