import React, { useEffect } from "react";
import moment from "moment";
// Style //
import "./HgaWallet.css";

// Images //
import { images } from "../../assets";

// Components //
import { Header, Wraper, ModalContainer } from "../../common/containers";
import { WalletInput } from "../../common/components";
import DateTime from "react-datetime";
import PaginationComponent from "../../common/components/pagination/Pagination";

// UseState //
import { useState } from "react";
import Constant from "../../services/Constant";
import { useDispatch } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { getTransactions } from "../../api/paymentApi";
import { generateTransactionId } from "../../services/utils";
import { getSettings } from "../../api/utilsApi";
import { getPlayer, updatePlayerWallet } from "../../api/usersApi";

const status = {
  PROCESSING: "PROCESSING",
  SUCCES: "SUCCESS",
  FAILED: "FAILED",
};


const HgaWallet = () => {
  const tableRows = [1, 2, 3, 4, 5, 6, 7, 8];

  const [transactionType, setTransactionType] = useState(
    Constant.TRANSACTION_TYPE.CREDIT
  );

  // Hooks //
  const [purcheseModal, setPurcheseModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [hgaCoins, setHgaCoins] = useState("");
  const [startDate, setStartDate] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [localLoading, setLocalLoading] = useState(false);
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const [alltransactions, setAllTransactions] = useState({});
  const [registerStatus, setRegisterStatus] = useState(status.PROCESSING);
  const [purchaseVisiblity, setPurchaseVisiblity] = useState(false);
  const [oneCoinInDollar, setOneCoinInDollar] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [authUserDetails, setAuthUserDetails] = useState({});


  // api calls
  const getTransactionsCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getTransactions({
        startDate,
        endDate,
        transactionType,
        limit,
        pageNo,
      });
      console.log("dataL::", data);
      setAllTransactions(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const getSettingCall = async () => {
    try {
      setButtonLoading(true);
      const data = await getSettings();
      console.log("data::", data);
      setOneCoinInDollar(1/data["dollar_in_hga_coin"]);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const purchaseCoinCall = async (coins) => {
    setRegisterStatus(status.PROCESSING);
    try {
      setLocalLoading(true);
      const data = await updatePlayerWallet({
        coins: coins,
      })
      setAuthUserDetails(data);
      setTimeout(() => {
        setRegisterStatus(status.SUCCES);
      }, 2000);
      setPurchaseVisiblity(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setPurchaseVisiblity(false);
      setTimeout(() => {
        setRegisterStatus(status.FAILED);
      }, 2000);
    }
  };

  const getplayerDetailsCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getPlayer();
      setAuthUserDetails(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handelers
  const isValidEndDate = (current) => {
    return current.isAfter(startDate);
  };

  const handelHgaCoinsUpdation = (coins) => {
    setHgaCoins(coins);
    setAmount(Number(coins) * Number(oneCoinInDollar));
  };


  // useEffect
  useEffect(() => {
    getTransactionsCall();
  }, [startDate, endDate, transactionType, limit,pageNo]);

  useEffect(()=>{
    getSettingCall();
    getplayerDetailsCall();
  },[])

  return (
    <React.Fragment>
      <ModalContainer show={localLoading}>
        <div className="form_common_style loader_modal">
          <div className="filler_dots_cont">
            {registerStatus === status.PROCESSING ? (
              <div className="gray_dots">
                <img src={images.grayDots} className="dots_btm" alt="" />
                <img src={images.grayDots} className="dots_top" alt="" />
              </div>
            ) : registerStatus === status.SUCCES ? (
              <div className="green_dots">
                <img src={images.greenDots} className="dots_btm" alt="" />
                <img src={images.greenDots} className="dots_top" alt="" />
              </div>
            ) : (
              <div className="orange_dots">
                <img src={images.orangeDots} className="dots_btm" alt="" />
                <img src={images.orangeDots} className="dots_top" alt="" />
              </div>
            )}
          </div>
          {registerStatus === status.PROCESSING ? (
            ""
          ) : (
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                setLocalLoading(false);
              }}
            ></i>
          )}
          <div className="loader_gif_cont">
            {registerStatus === status.SUCCES ? (
              <img src={images.succes} alt="" />
            ) : registerStatus === status.PROCESSING ? (
              <img src={images.loagerGif} alt="" />
            ) : (
              <img src={images.failed} alt="" />
            )}
          </div>
          {registerStatus === status.SUCCES ? (
            <React.Fragment>
              <p>Hga Coins are successfully added to your account</p>
              {/*<h2>Tournament Name</h2>*/}
            </React.Fragment>
          ) : registerStatus === status.PROCESSING ? (
            <h2>Payment Is Processing</h2>
          ) : (
            <h2>Payment Failed</h2>
          )}
        </div>
      </ModalContainer>
      <ModalContainer show={purchaseVisiblity}>
        <div className="purchese_coin_cont form_common_style loader_modal">
          <div className="filler_dots_cont">
            <div className="orange_dots">
              <img src={images.fillerImg} className="dots_btm" alt="" />
              <img src={images.fillerImg} className="dots_top" alt="" />
            </div>
          </div>
          <div className="header">
            <h2> Purchese HGA Coins </h2>
            <i
              className="fa-solid fa-xmark close_btn"
              onClick={() => setPurchaseVisiblity(false)}
            ></i>
          </div>
          <div className="wallet_input_cont">
            <WalletInput
              type={"text"}
              coinLogo={true}
              placeholder={"HGA Coins"}
              width={"100%"}
              fieldRed={true}
              value={hgaCoins}
              onChange={handelHgaCoinsUpdation}
              alignCenter={true}
            />

            <div className="divider_line">
              <div className="line"></div>
              <span>OR</span>
              <div className="line"></div>
            </div>
            <WalletInput
              type={"text"}
              coinLogo={false}
              placeholder={"Amount in Dollars"}
              width={"100%"}
              fieldRed={true}
              value={amount}
              disabled={true}
              alignCenter={true}
            />
            <button
              className="btn-style btn-green w-100 mt-5"
              onClick={() => {
                purchaseCoinCall(hgaCoins);
              }}
            >
              Purchase Coins
            </button>
          </div>
        </div>
      </ModalContainer>
      <Header button={true} />
      <div id="hga-wallet">
        <div className="cont">
          <div className="lft">
            <div>
              <img
                className="dots-pair dots-pair1"
                src={images.fillerImg}
                alt=""
              />
              <img
                className="dots-pair dots-pair2"
                src={images.fillerImg}
                alt=""
              />
            </div>
            <div className="wallet_wraper">
              <div className="total-balance_cont">
                <div className="coin-logo">
                  <img src={images.coinLogo} alt="" />
                </div>
                <div className="txt">
                  <p>Total HGA Coins</p>
                  <h2>{authUserDetails.wallet}</h2>
                </div>
              </div>
              <button
                className="btn-style btn-orange mt-5"
                onClick={() => setPurchaseVisiblity(true)}
              >
                Purchase HGA Coins
              </button>
            </div>
          </div>
          <div className="rgt">
            <div className="tabs_cont">
              <div className="history_tab_cont">
                <div className="top-tab_cony">
                  <h2
                    className={`${
                      transactionType === Constant.TRANSACTION_TYPE.CREDIT
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      setTransactionType(Constant.TRANSACTION_TYPE.CREDIT)
                    }
                  >
                    Credited HGA Coins
                  </h2>
                  <h2
                    className={`${
                      transactionType === Constant.TRANSACTION_TYPE.DEBIT
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      setTransactionType(Constant.TRANSACTION_TYPE.DEBIT)
                    }
                  >
                    Dedited HGA Coins
                  </h2>
                </div>
                <div className="filter_cont">
                  <div className="d-flex">
                    <div className="date_selector">
                      <div className="custom-date_slector d-flex justify-content-between">
                        <i className="fa-solid fa-calendar"></i>
                        <DateTime
                          key={"startDate" + startDate}
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                          inputProps={{ placeholder: "From" }}
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          closeOnSelect={true}
                        />
                        <i className="fa-solid fa-caret-down"></i>
                      </div>
                    </div>

                    <div className="date_selector">
                      <div className="custom-date_slector d-flex justify-content-between">
                        <i className="fa-solid fa-calendar"></i>
                        <DateTime
                          key={"endDate" + endDate}
                          value={endDate}
                          onChange={(date) => setEndDate(date)}
                          inputProps={{ placeholder: "To" }}
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          isValidDate={isValidEndDate}
                          closeOnSelect={true}
                        />
                        <i className="fa-solid fa-caret-down"></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <button
                      className=" btn-orange btn-style"
                      type="clear"
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="table_cont">
                  {transactionType === Constant.TRANSACTION_TYPE.CREDIT ? (
                    <div className="tab" id="tab-one">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Date</th>
                            <th scope="col">Coins</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            {/*<th scope="col">Invoice</th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {alltransactions.rows && alltransactions.count
                            ? alltransactions.rows.map((transaction) => (
                                <tr key={transaction.id}>
                                  <td>{generateTransactionId()}</td>
                                  <td>
                                    {transaction.createdAt
                                      ? moment(transaction.createdAt).format(
                                          Constant.DATE_TIME_FORMAT
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {transaction.coins
                                      ? transaction.coins
                                      : "-"}
                                  </td>
                                  <td>
                                    {transaction.amount
                                      ? `$ ${transaction.amount}`
                                      : "-"}
                                  </td>
                                  <td className="green_color">Success</td>
                                  {/*<td>
                                    <button className="btn-style btn-orange">
                                      <a
                                        className="text-decoration-none text-white"
                                        href="http://localhost:4000/InvoiceSimple-PDF-Template.pdf"
                                      >
                                        Invoice
                                        <i
                                          className="fas fa-file-download"
                                          style={{ marginLeft: "1rem" }}
                                        ></i>
                                      </a>
                                    </button>
                                    </td>*/}
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="tab" id="tab-two">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Date</th>
                            <th scope="col">Coins</th>
                            <th scope="col">Tournament</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {alltransactions.rows && alltransactions.count
                            ? alltransactions.rows.map((transaction) => (
                                <tr key={transaction.id}>
                                  <td>{generateTransactionId()}</td>
                                  <td>
                                    {transaction.createdAt
                                      ? moment(transaction.createdAt).format(
                                          Constant.DATE_TIME_FORMAT
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {transaction.coins
                                      ? transaction.coins
                                      : "-"}
                                  </td>
                                  <td>
                                    {transaction.tournament?.t_name
                                      ? transaction.tournament?.t_name
                                      : "-"}
                                  </td>
                                  <td className="green_color">Success</td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <PaginationComponent
                  count={alltransactions.count}
                  limit={limit}
                  setLimit={setLimit}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HgaWallet;
