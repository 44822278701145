import React from "react";

// Style //
import "./GolfCourseSheet.css";

// Images //
import { images } from "../../assets/index";

// Containers //
import { Wraper } from "../../common/containers";

// Components //
import { Loader, UserProfile } from "../../common/components/index";

// Hooks //
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Apis
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsAction";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { getGolfScoreSheet } from "../../api/golfCourseApi";
const playersInfo = [
  {
    id: 1,
    username: "Player 1",
    total_handicap_strokes: "-",
    total_strokes: "-",
    scores: [
      {
        hole_id: 1,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 2,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 3,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 4,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 5,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 6,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 7,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 8,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 9,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 10,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 11,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 12,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 13,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 14,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 15,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 16,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 17,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 18,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
    ],
    front9TotalPoints: '-',
    back9TotalPoints: '-',
    front9TotalStrokes: '-',
    back9TotalStrokes: '-',
  },
  {
    id: 2,
    username: "Player 2",
    total_handicap_strokes: "-",
    total_strokes: "-",
    scores: [
      {
        hole_id: 1,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 2,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 3,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 4,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 5,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 6,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 7,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 8,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 9,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 10,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 11,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 12,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 13,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 14,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 15,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 16,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 17,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 18,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
    ],
    front9TotalPoints: '-',
    back9TotalPoints: '-',
    front9TotalStrokes: '-',
    back9TotalStrokes: '-',
  },
  {
    id: 3,
    username: "Player 3",
    total_handicap_strokes: "-",
    total_strokes: "-",
    scores: [
      {
        hole_id: 1,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 2,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 3,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 4,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 5,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 6,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 7,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 8,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 9,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 10,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 11,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 12,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 13,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 14,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 15,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 16,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 17,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
      {
        hole_id: 18,
        handicap_strokes: "-",
        handicap: "-",
        stroke: "-",
        points: "-",
      },
    ],
    front9TotalPoints: '-',
    back9TotalPoints: '-',
    front9TotalStrokes: '-',
    back9TotalStrokes: '-',
  },
 
  
];
const GolfCourseSheet = () => {
  // hooks
  const [scoreSheetData, setScoreSheetData] = useState({});
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.utils);
  let [searchParams, setSearchParams] = useSearchParams();
  // api calls
  const getGolfScoreSheetCall = async () => {
    try {
      dispatch(requestSent());
      const courseId = searchParams.get("courseId");
      const data = await getGolfScoreSheet({ courseId });
      setScoreSheetData(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // useEffects
  useEffect(() => {
    getGolfScoreSheetCall();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div id="score-sheet">
          <div className="header">
            <div className="floating_doted_img">
              <img className="img1" src={images.fillerImg} alt="" />
              <img className="img2" src={images.fillerImg} alt="" />
            </div>
            <div className="content">
              <div className="text_cont">
                <h2>
                  {scoreSheetData.course_name
                    ? scoreSheetData.course_name
                    : "-"}
                </h2>
              </div>
            </div>
          </div>
          <Wraper otherClassName="wraper_pading">
            <div className=" w-100">
              <table className="w-100 p-2">
                <thead className="w-100">
                  {scoreSheetData.holes && scoreSheetData.holes.length > 0 ? (
                    <React.Fragment>
                      <tr className="w-100">
                        <td className="col-3 text-center">Hole Number</td>
                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index < 9) {
                                return (
                                  <div className="col-1">{hole.hole_no}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">F9 TOTAL</div>
                          </div>
                        </td>

                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index >= 9) {
                                return (
                                  <div className="col-1">{hole.hole_no}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">B9 TOTAL</div>
                          </div>
                        </td>

                        <td className="col-1 text-center">TOTAL</td>
                      </tr>
                      <tr className="w-100">
                        <td className="col-3 text-center">Par</td>
                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index < 9) {
                                return (
                                  <div className="col-1">{hole.hole_par}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">
                              {scoreSheetData.front9Par
                                ? scoreSheetData.front9Par
                                : "-"}
                            </div>
                          </div>
                        </td>

                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index >= 9) {
                                return (
                                  <div className="col-1">{hole.hole_par}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">
                              {scoreSheetData.back9Par
                                ? scoreSheetData.back9Par
                                : "-"}
                            </div>
                          </div>
                        </td>

                        <td className="col-1 text-center">
                          {Number(scoreSheetData.front9Par) +
                            Number(scoreSheetData.back9Par)}
                        </td>
                      </tr>
                      <tr className="w-100">
                        <td className="col-3 text-center">Men's Handicap</td>
                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index < 9) {
                                return (
                                  <div className="col-1">{hole.difficulty}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">-</div>
                          </div>
                        </td>

                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index >= 9) {
                                return (
                                  <div className="col-1">{hole.difficulty}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">-</div>
                          </div>
                        </td>

                        <td className="col-1 text-center">-</td>
                      </tr>
                      <tr className="w-100">
                        <td className="col-3 text-center">Women's Handicap</td>
                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index < 9) {
                                return (
                                  <div className="col-1">
                                    {hole.difficulty_w}
                                  </div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">-</div>
                          </div>
                        </td>

                        <td className="col-4">
                          <div className="row">
                            {scoreSheetData.holes.map((hole, index) => {
                              if (index >= 9) {
                                return (
                                  <div className="col-1">{hole.difficulty}</div>
                                );
                              }
                            })}
                            <div className="col-3 text-center">-</div>
                          </div>
                        </td>

                        <td className="col-1 text-center">-</td>
                      </tr>
                    </React.Fragment>
                  ) : (
                    <tr>
                      <td className="col-3">Hole Number</td>

                      <td className="col-4">
                        <div className="row">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((hole, index) => {
                            if (index < 9) {
                              return <div className="col-1">{hole}</div>;
                            }
                          })}
                          <div className="col-3 text-center">F9 TOTAL</div>
                        </div>
                      </td>

                      <td className="col-4">
                        <div className="row">
                          {[10, 11, 12, 13, 14, 15, 16, 17, 18].map(
                            (hole, index) => {
                              if (index < 9) {
                                return <div className="col-1">{hole}</div>;
                              }
                            }
                          )}
                          <div className="col-3 text-center">B9 TOTAL</div>
                        </div>
                      </td>

                      <td className="col-1 text-center">TOTAL</td>
                    </tr>
                  )}
                </thead>
              </table>
            </div>

            <div>
              {playersInfo.map((player) => {
                return (
                  <div key={player.id} className="w-100">
                    <table className="w-100 p-2">
                      <tbody>
                        <tr>
                          <td className="col-2" rowSpan={2}>
                            <UserProfile
                              name={player.username}
                              profileImg={""}
                            />
                          </td>

                          <td className="col-1">
                            Stroke ({player.total_handicap_strokes})
                          </td>
                          <td className="col-4">
                            <div className="row">
                              {player.scores.map((score, index) => {
                                if (index < 9) {
                                  return (
                                    <div className="col-1">
                                      {score.stroke}
                                      
                                    </div>
                                  );
                                }
                              })}
                              <div className="col-3 text-center">
                                {player.front9TotalStrokes}
                              </div>
                            </div>
                          </td>

                          <td className="col-4">
                            <div className="row">
                              {player.scores.map((score, index) => {
                                if (index >= 9) {
                                  return (
                                    <div className="col-1">
                                      {score.stroke}
                                      
                                    </div>
                                  );
                                }
                              })}
                              <div className="col-3 text-center">
                                {player.back9TotalStrokes}
                              </div>
                            </div>
                          </td>

                          <td className="col-1 text-center">
                            {'-'}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-1">Points</td>

                          <td className="col-4">
                            <div className="row">
                              {player.scores.map((score, index) => {
                                if (index < 9) {
                                  return (
                                    <div className="col-1">{score.points}</div>
                                  );
                                }
                              })}
                              <div className="col-3 text-center">
                                {player.front9TotalPoints}
                              </div>
                            </div>
                          </td>

                          <td className="col-4">
                            <div className="row">
                              {player.scores.map((score, index) => {
                                if (index >= 9) {
                                  return (
                                    <div className="col-1">{score.points}</div>
                                  );
                                }
                              })}
                              <div className="col-3 text-center">
                                {player.back9TotalPoints}
                              </div>
                            </div>
                          </td>

                          <td className="col-1 text-center">
                            {'-'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          </Wraper>
        </div>
      )}
    </React.Fragment>
  );
};

export default GolfCourseSheet;
