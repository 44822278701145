import React, { useEffect, useState } from "react";
// import "./CreateGroup.css";
import "../../../league/create-league/CreateLeague.css";

// useDispatch //
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
// import { images } from "../../../assets";
import DateTime from "react-datetime";
import moment from "moment";

import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import { toast } from "react-toastify";
import Constant from "../../../../services/Constant";
import {
  createGroup,
  getTournamentDetails,
} from "../../../../api/tournamentApi";
import { DropDownWithSearch } from "../../../../common/components";
export default function CreateGroup({ handleClose, getTournamentGroupsCall }) {
  // useState
  const [groupName, setGroupName] = useState("");
  const [groupTeeTime, setGroupTeeTime] = useState(null);
  const [groupTeeTimeToShow, setGroupTeeTimeToShow] = useState(null);
  const [teesData, setTeesData] = useState([]);
  const [selectedTee, setSlectedTee] = useState({});
  const [search, setSearch] = useState("");

  // useNavigate
  const navigator = useNavigate();

  // useDispatch
  const dispatch = useDispatch();

  // useSlector
  const { loading } = useSelector((state) => state.utils);
  const { selectedTournament } = useSelector((state) => state.tournament);

  // api calls
  const createGroupCall = async (details) => {
    try {
      console.log("body::", details);
      dispatch(requestSent());
      let data = {};
      data = await createGroup(details);
      dispatch(responseRecived());
      handleClose();
      setGroupName("");
      setGroupTeeTime(null);
      toast.success("Group created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getTournamentGroupsCall();
      // navigator('/manage-tournament')
    } catch (error) {
      console.log("error in call ::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler

  function handleSubmit() {
    createGroupCall({
      tournament_id: selectedTournament.id,
      name: groupName,
      tee_time: groupTeeTime,
      tee_id:selectedTee.id
    });
  }

  function handelSearchChange(val) {
    setSearch(val);
  }

  // const isValidTeetime = (current) => {
  //   return current.isAfter(selectedTournament.startDate);
  // }

  // api call
  const getTournamentDetailsCall = async () => {
    try {
      dispatch(requestSent());
      let data = await getTournamentDetails({
        tournamentId: selectedTournament.id,
      });
      if (!data.golf_course?.tees || data.golf_course.tees.length === 0) {
        throw new Error(Constant.ERRORS_LIST.NO_TEE_DETAILS_FOUND);
      }
      let processedData = data.golf_course.tees.map((tee) => {
        return { ...tee, item1: tee.tee_type, item2: tee.rating_for_men };
      });
      console.log("processedData::", processedData)

      setTeesData(processedData);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // useEffect
  useEffect(() => {
    getTournamentDetailsCall();
  }, []);

  return (
    <div className="create_league_form form_common_style" id="create-league">
      {/*<div className="filler_elem">
        <img src={images.fillerImg} alt="" />
        <img src={images.fillerImg} alt="" />
  </div>*/}
      <div className="header">
        <h2> Create New Group </h2>
      </div>
      <div className="form_cont">
        <div className="input_lable_cont">
          <p>Group Name :</p>
          <div className="input_field_cont">
            <input
              type="text"
              className="input_field"
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Tee Name</p>
          <div className="input_field_cont">
            <DropDownWithSearch
              title={selectedTee.tee_type ? selectedTee.tee_type : ""}
              openTop={false}
              onChange={handelSearchChange}
              placeholder="Search Players"
              dropdownPlaceholder={"Select Player"}
              headerOptions={["Tee Type", "Rating men"]}
              listItems={teesData}
              value={search}
              handelChangeSelection={(tee) => {
                setSlectedTee(tee);
              }}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Tee Time</p>
          <div className="input-elem number-input-elem custom-date_slector">
            <i className="fa-solid fa-calendar-days"></i>

            <DateTime
              value={groupTeeTimeToShow}
              dateFormat={Constant.DATE_FORMAT}
              timeFormat={Constant.TIME_FORMAT}
              inputProps={{ placeholder: "Select Date", readOnly: true }}
              onChange={(e) => {
                console.log(
                  "e,el::",
                  e,
                  e.format(Constant.DB_DATE_TIME_FORMAT)
                );
                setGroupTeeTime(e.format(Constant.DB_DATE_TIME_FORMAT));
                setGroupTeeTimeToShow(e);
              }}
              // isValidDate={isValidTeetime}
              closeOnSelect={true}
            />
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </div>
        </div>
      </div>

      <div className="btn_cont">
        <button className="btn-style btn-orange" onClick={handleClose}>
          Close
        </button>
        <button className="btn-style btn-green" onClick={handleSubmit}>
          Create
        </button>
      </div>
    </div>
  );
}
