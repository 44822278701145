import React, { useState } from "react";
// useDispatch //
import { useDispatch, useSelector } from "react-redux";

// Redux Action //
import {
  loginUser,
  signUpUser,
  existUser,
} from "../../../redux/actions/action";

// Images //
import { images } from "../../../assets";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../redux/actions/utilsAction";
import { signUp } from "../../../api/auth";
import Constant from "../../../services/Constant";
import { isObjectEmpty } from "../../../services/utils";
import { useNavigate } from "react-router";

export default function SignUp({ showLogInHandler,showSelectLeagueHandler, handleClose }) {
  // Utils //
  const {selectedTournament} = useSelector((state)=>state.tournament)
  const {selectedLeague} = useSelector((state)=>state.league)

  // Dispatch //
  const dispatch = useDispatch();

  // Sign up Input States //
  const [userName, setuserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nickName, setNickName] = useState("");
  const navigator = useNavigate()
  // Submit Handlers //

  const signUpSubmitHandler = async () => {
    try {
      const data = {
        name: userName,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        nickName: nickName,
      };

      if (data.password !== data.confirmPassword) {
        dispatch(receivedError("Password and Confirm Password Not matched !"))
        return;
      }
      dispatch(requestSent());
      let user = await signUp(data);
      dispatch(signUpUser(user));
      setuserName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        console.log("selectedTournament::",selectedTournament);
        if(!isObjectEmpty(selectedTournament)){
          navigator('/manage-my-tournament')
          handleClose()
        }else if(!isObjectEmpty(selectedLeague)){
          navigator('/manage-tour')
          handleClose()
        }else{
          showSelectLeagueHandler()
          // navigator('/')
        }
        
        dispatch(responseRecived());
      }, 500);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
    }
  };

  return (
    <div className="login_cont">
      <div className="lft">
        <img src={images.loginPageImg} alt="" />
        <div className="overlay">
          <div className="txt_cont">
            <h2>Already Signed Up?</h2>
            <p>Log In and create new tournaments and League</p>
            <button className="btn-orange btn-style" onClick={showLogInHandler}>
              Log In
            </button>
          </div>
        </div>
      </div>
      <div className="rgt">
        <div className="close" onClick={handleClose}>
          <i className="fa-sharp fa-solid fa-xmark"></i>
        </div>
        <div>
          <img className="dots-pair dots-pair1" src={images.fillerImg} alt="" />
          <img className="dots-pair dots-pair2" src={images.fillerImg} alt="" />
        </div>
        <h2>Create Your Account</h2>
        <div className="input-field_cont">
        
        <div className="input-elem">
        <i className="fa-solid fa-user"></i>
            <input
              type="text"
              placeholder="User Name"
              onChange={(e) => setuserName(e.target.value)}
              value={userName}
            />
          </div>
          {/* <div className="input-elem">
                    <input type="text" placeholder="Your Last Name" />
                  </div> */}
          <div className="input-elem">
            <i className="fa-solid fa-address-card"></i>
            <input
              type="text"
              placeholder="Enter a unique nick name"
              onChange={(e) => setNickName(e.target.value)}
              value={nickName}
            />
          </div>

          {/*<p className="error_msg"> {error ? `* ${error.message}` : ""}</p>*/}
          <div className="input-elem">
            <i className="fa-solid fa-envelope"></i>
            <input
              type="email"
              placeholder="Enter Your Email"
              onChange={(e) => {
                dispatch(receivedError(''))
                setEmail(e.target.value)}}
              value={email}
            />
          </div>
          <div className="input-elem">
            <i className="fa-solid fa-lock"></i>
            <input
              type="password"
              placeholder="Create Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="input-elem">
            <i className="fa-solid fa-lock"></i>
            <input
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </div>
          <button
            className="btn-orange btn-style"
            onClick={signUpSubmitHandler}
          >
            Sign Up
          </button>
          
        </div>
      </div>
    </div>
  );
}
