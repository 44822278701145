import axios from "axios";
import { catchHandle } from "../errorHandlers/errorHandler";

export const logIn = async(params)=>{
  return new Promise(async (resolve,reject)=>{
    try{
      let response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/login`,
        data: {
            email: params.email,
            password: params.password,
        }
      })
      resolve(response.data)
    }catch(error){
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  })
   
}

export const signUp = (params)=>{
  
  return new Promise(async (resolve,reject)=>{
    try {
      console.log(params.name);
      let response = await axios({
        method: "POST",
        withCredentials: true,
        crossDomain: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/signup`,
        data: {
            name: params.name,
            email: params.email,
            password: params.password,
            confirm_password: params.confirmPassword,
            nick_name:params.nickName,
        }
      })
      resolve(response.data.data)
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
    
  })
}


export const setSelectedInfoToBackend = (body)=>{
  
  return new Promise(async (resolve,reject)=>{
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-all-golf-courses`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: bearerToken,
        },
        url: `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/selected-info`,
        data:body,
      })
      resolve(response.data)
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
    
  })
}

export const getSelectedInfo = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/selected-info`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      
      resolve(response.data.data);
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};