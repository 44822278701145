import React from "react";
import Pagination from "rc-pagination";
import "../../../pages/players/Players.css";
export default function PaginationComponent({ count, pageNo, setPageNo, limit ,setLimit }) {
  // handelers
  const PerPageChange = (value) => {
    setLimit(value);
    const newPerPage = Math.ceil(count / value);
    if (pageNo > newPerPage) {
      setPageNo(newPerPage);
    }
  };

  const PaginationChange = (page, pageSize) => {
    setPageNo(page);
    setLimit(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };

  return (
    <div className="table-filter-info">
      <Pagination
        className="pagination-data text-light"
        showTotal={(total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total}`
        }
        onChange={PaginationChange}
        total={count ? count : 0}
        current={pageNo}
        pageSize={limit}
        showSizeChanger={false}
        itemRender={PrevNextArrow}
        onShowSizeChange={PerPageChange}
      />
    </div>
  );
}
