import axios from "axios";
// Redux //
import { useSelector, useDispatch } from "react-redux";
import { catchHandle } from "../errorHandlers/errorHandler";
// axios.defaults.withCredentials = true;


export const deleteUploadApi = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/delete-upload-history`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.delete(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const generateLeaderBordApi = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/generate-leaerboard`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};
export const getUploadHistory = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/show-upload-history`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      resolve(response.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};
export const getTourSchedule = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/tournament-schedule`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      resolve(response.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};
export const getSudoTourSchedule = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/sudo-tournament-schedule`;

      let response = await axios.get(url, {
        headers: {},
        withCredentials: true,
        crossDomain: true,
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const createTournament = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-tournament`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const submitScores = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/submit-tournament-score`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, body, {
        headers: {
          Authorization: bearerToken,
          'Content-Type': 'multipart/form-data'
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const getTournamentDetails = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/tournament-details`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
        },
        params,
      });
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });

};

export const createSudoTournament = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-sudo-tournament`;
      
      let response = await axios.post(url, body, {
        withCredentials: true,
        crossDomain: true,
        header: {
          "Content-Type": "application/x-www-form-urlencoded",
          Origin: "http://localhost:3000",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};


export const updateTournament = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/tournament-update`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.patch(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};

export const getTournamentLeaderBoard = (tournamentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/tournament-results`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params: { tournamentId },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error::",error);
      let errorMessage = await catchHandle(error)
      reject(errorMessage)
    }
  });
};

export const getTournamentGroupsWithWinningDetails = (tournamentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-groups-with-winners`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params: { tournamentId },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};

export const getTournamentGroups = (tournamentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-groups`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params: { tournamentId },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};


export const createGroup = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/create-group`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.post(url, body, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      let errorMessage = await catchHandle(error);
      console.log("errorMessage in api::", errorMessage);
      reject(errorMessage);
    }
  });
};


export const getCsvRawDataApi = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/csv-data`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params,
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};


export const getGroupDetails = (tournamentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("reset_token");
      let url = `${process.env.REACT_APP_BASE_URL_DEVELOPMENT}/get-groups-details`;
      let bearerToken = `Bearer ${token.replace(/['"]+/g, "")}`;
      let response = await axios.get(url, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params: { tournamentId },
      });
      console.log("response::", response);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in api::", error);
      const errorMessage = await catchHandle(error)
      reject(errorMessage);
    }
  });
};

