import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// Toast //
import { toast } from "react-toastify";

// Style //
import "./ManageTournament.css";

// Containers //
import { Header, Wraper } from "../../../common/containers";

//  Components //
import { UserProfile, DropdownList } from "../../../common/components";
import { Loader } from "../../../common/components/index";

// pagination //
import Pagination from "rc-pagination";

// Date Picker //
import DateTime from "react-datetime";

// Api's //
import { getTourSchedule,getSudoTourSchedule } from "../../../api/tournamentApi";

import moment from "moment";

// Redux Action //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../../redux/actions/utilsAction";

import { useDispatch, useSelector } from "react-redux";
import { independentCreationOff, independentCreationOn, setSelectedTournamentAction, setShowCreateTournamentAction } from "../../../redux/actions/tournamentAction";

const ManageTournament = () => {
  const navigator = useNavigate();
  const { error, loading } = useSelector((state) => state.utils);
  const { user } = useSelector((state) => state.user);


  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tourSchedule, setTourSchedule] = useState([]);
  const [perPage, setPerPage] = useState(8);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [filter, setFilter] = useState("");

  // handlers //
  
  const handelFilterClear = (val) => {

    console.log("handle filter::", startDate, endDate);
    setStartDate(null);
    setEndDate(null);
    setFilter(setStartDate);
    tourScheduleCall(null,null,true);
  };
  const handelStartDateChange = (date) => {
    setStartDate(date)
    handelFilterSubmit(date,endDate)
  }
  const handelEndDateChange = (date) => {
    setEndDate(date)
    handelFilterSubmit(startDate,date)
  }
  const handelFilterSubmit = (startD,endD) => {
    tourScheduleCall(startD,endD);
  };
  const handelGoToMange = (tour) => {
    dispatch(setSelectedTournamentAction(tour));
    navigator(`/manage-tournament`);
  };


  const handelOnClickButton = (tour) => {
    if (
      tour.tournament_leaderboards &&
      tour.tournament_leaderboards.length > 0
    ) {
      navigator(`/results/tournament?tournamentId=${tour.id}`);
    } else {
      dispatch(setSelectedTournamentAction(tour))
      navigator('/manage-tournament/register-player')
    }
  };

  const tourScheduleCall = async (startD,endD,reset=false) => {
    try {
      dispatch(requestSent());
      let data 
      if(user){
         data = await getTourSchedule({
          date_from: startD || reset ? startD:startDate,
          date_to: endD || reset ? endD :endDate,
        });
      }else{
        data = await getSudoTourSchedule({
          date_from: startD || reset ? startD:startDate,
          date_to: endD || reset ? endD :endDate,
        });
      }
      console.log("data::", data);
      setTourSchedule(data.data);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error))
      dispatch(responseRecived())
    }
  };
  

  useEffect(() => {
    tourScheduleCall(startDate,endDate);
  }, [filter]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <Header heading={'Mange Tournaments'} button={false} />
      <Wraper
      title={'MANGE TOURNAMENTS'}
        otherElem={
          <React.Fragment>
            <div className="tournament-type_cont">
              
            </div>
            <div className="floating_cont">
              <div className="col-10 d-flex">
                <div className="custom-date_slector">
                  <DateTime
                    key={"startDate" + startDate}
                    value={startDate}
                    onChange={(date) => handelStartDateChange(date)}
                    inputProps={{ placeholder: "Start Date" }}
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect={true}
                  />
                </div>
                <div className="custom-date_slector">
                  <DateTime
                    value={endDate}
                    onChange={(date) => handelEndDateChange(date)}
                    inputProps={{ placeholder: "End Date" }}
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect={true}
                    key={"endDate" + endDate}
                  />
                </div>
              </div>
              <button
                className="btn-style btn-orange  "
                onClick={handelFilterClear}
              >
                Clear
              </button>
            </div>
          </React.Fragment>
        }
        endButton={true}
        endButtonTitle={"Create Tournament"}
        endButtonOnclick={() => {
          dispatch(setShowCreateTournamentAction(true));
          dispatch(independentCreationOn())
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Tournament</th>
              <th scope="col">Venue</th>
              <th scope="col">Champions</th>
              <th scope="col">Time</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {tourSchedule.map((tour, index) => {
              return (
                <tr
                  className={`tournament-schedules`}
                  key={tour.id}
                >
                  <td>{moment(tour.start_date).format("DD/MM/YYYY")}</td>
                  <td>{tour.t_name}</td>
                  <td>
                    {tour.golf_course ? tour.golf_course.course_name : '-'}
                  </td>

                  <td>
                    {tour.tournament_leaderboards &&
                    tour.tournament_leaderboards.length > 0 ? (
                      <UserProfile
                        name={tour.tournament_leaderboards["0"].player.username}
                        nickName={
                          tour.tournament_leaderboards["0"].player.email
                        }
                        userId={tour.tournament_leaderboards["0"].player_id}
                        redirection={true}
                      />

                    ) : (
                      '-'
                    )}
                  </td>

                  <td>{moment(tour.start_date).format("HH:mm:ss")}</td>
                  <td className="w-25">
                    <button
                      className="btn-style"
                      onClick={() => {
                        handelOnClickButton(tour);
                      }}
                    >
                      {tour.tournament_leaderboards &&
                      tour.tournament_leaderboards.length > 0
                        ? "Leaderboard"
                        : "Register"}
                    </button>
                    <button
                          className={"btn-style btn-green ms-1"}
                          onClick={() => {
                            handelGoToMange(tour);
                          }}
                        >
                          Manage
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {tourSchedule.length === 0 ? (
          <h3 className="text-center">You don't have any tournaments to manage</h3>
        ) : (
          ""
        )}
        {/*<div className="table-filter-info">
          <Pagination
            className="pagination-data"
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total}`
            }
            onChange={PaginationChange}
            total={tourSchedule && tourSchedule.length>0 ? tourSchedule.length : 0}
            current={current}
            pageSize={size}
            showSizeChanger={false}
            itemRender={PrevNextArrow}
            onShowSizeChange={PerPageChange}
          />
          </div> */}
      </Wraper>
    </>
  );
};

export default ManageTournament;
