import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// Style //
import "./GolfCourses.css";

// Containers //
import { Header, Wraper } from "../../common/containers";

//  Components //
import {
  Loader,
  UserProfile,
  DropDownWithSearch,
  SkLoader,
} from "../../common/components/index";

import { useDispatch, useSelector } from "react-redux";

// Redux Action //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../redux/actions/utilsAction";

// Api's //

import { images } from "../../assets";
import { getAllGolfCourse } from "../../api/golfCourseApi";
import { useNavigate } from "react-router";

const GolfCourses = () => {
  // hooks
  const { loading } = useSelector((state) => state.utils);
  const [golfCourses, setGolfCourses] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // Api Calls
  const fetchGolfCourses = async () => {
    try {
      dispatch(requestSent());
      let data = await getAllGolfCourse();
      setGolfCourses(data);
      console.log("data::", data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError());
      dispatch(responseRecived());
    }
  };

  // handlers //

  useEffect(() => {
    fetchGolfCourses();
  }, []);

  return (
    <>
      <Header button={false}  heading={"Golf Courses"}/>
      <Wraper >
        {loading ? (
          <SkLoader limit={20} height={30} mt={2} />
        ) : (
          <table className="table table-striped">
            <thead >
              <tr style={{fontSize:'1rem'}}>
              <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Country</th>
                <th scope="col">No Of Holes</th>
                <th scope="col">Course Rating</th>
                <th scope="col">Slope</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {golfCourses.map((course,index)=>{
                return(
                  <tr className="mt-2" style={{fontSize:'.8rem'}}>
                  <td>{index+1}</td>
                    <td>{course.course_name ? course.course_name : '-'}</td>
                    <td>{course.course_location ? course.course_location : '-'}</td>
                    <td>{course.number_of_holes ? course.number_of_holes : '-'}</td>
                    <td>{course.course_rating ? course.course_rating : '-'}</td>
                    <td>{course.slope ? course.slope : '-'}</td>
                    <td><button className="btn-style btn-green" onClick={()=>{navigate(`/golf-courses/course-sheet?courseId=${course.id}`)}}>
                    ScoreCard
                  </button></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}

        {false ? (
          <h3 className="text-center">No LeaderBoard Found for this season</h3>
        ) : (
          ""
        )}
      </Wraper>
    </>
  );
};

export default GolfCourses;
