import React from "react";

import ContentLoader from "react-content-loader";

const TableRowLoader = ({ limit, height, mt ,width }) => {
  return (
    <div>
      {Array.from({ length: limit }, (_, index) => (
        <ContentLoader
          key={index}
          width={width ? width : '100%'}
          height={height ? height : 60}
          className={`mt-${mt ? mt : "3"}`}
          speed={2}
        backgroundColor={'#020F17'}
        foregroundColor={'#303D45'}
        >
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
      ))}
    </div>
  );
};

export default TableRowLoader;
