import React, { useEffect, useState } from "react";

import "./MangeTournamentRegisterPlayersForm.css";

// Components //
import { Header, ModalContainer } from "../../../../common/containers/index";
import {
  Sidebar,
  InputField,
  WalletInput,
  DropDownWithSearch,
} from "../../../../common/components/index";

// Images //
import { images } from "../../../../assets/index";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import {
  createNewPlayer,
  getAllPlayers,
  getPlayer,
  updatePlayer,
  updatePlayerWallet,
} from "../../../../api/usersApi";
import qs from "qs";
import { toast } from "react-toastify";
import { registerPlayer } from "../../../../api/paymentApi";
import { getOneCoinInDollar, getSettings } from "../../../../api/utilsApi";

const status = {
  PROCESSING: "PROCESSING",
  SUCCES: "SUCCESS",
  FAILED: "FAILED",
};

const MangeTournamentRegisterPlayersForm = () => {
  const location = useLocation();
  const { groupId, playerId, existingPlayer } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  // hooks
  const { selectedTournament } = useSelector((state) => state.tournament);
  const [hgaCoins, setHgaCoins] = useState(0);
  const [playerDetails, setPlayerDetails] = useState({});
  const [authUserDetails, setAuthUserDetails] = useState({});
  const [isEnoughCoins, setTsEnoughCoins] = useState(false);
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(status.PROCESSING);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [purchaseVisiblity, setPurchaseVisiblity] = useState(false);
  const [amount, setAmount] = useState(0);
  const [oneCoinInDollar, setOneCoinInDollar] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [allPlayers, setAllPlayers] = useState([]);
  const [search, setSearch] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  // handelers
  const handelOnSelect = (user) => {
    setSelectedPlayer(user);
    setPlayerDetails(user);
    setIsDisabled(true);
  };

  const handelRegisterPlayer = () => {
    registerPlayerCall();
  };
  const enoughCoins = () => {
    return (
      authUserDetails.wallet &&
      Number(authUserDetails.wallet) >= selectedTournament.registration_fee
    );
  };
  const handelHgaCoinsUpdation = (coins) => {
    setHgaCoins(coins);
    setAmount(Number(coins) * Number(oneCoinInDollar));
  };

  // api Calls

  const getplayerDetailsCall = async () => {
    try {
      dispatch(requestSent());
      const data = await getPlayer();
      setAuthUserDetails(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const getAllPlayersCall = async (search) => {
    try {
      setSearchLoading(true);
      let data = { rows: [] };
      if (search) {
        data = await getAllPlayers({ search });
      }
      setAllPlayers(data.rows);
      setSearchLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setSearchLoading(false);
    }
  };

  const getSettingCall = async () => {
    try {
      setButtonLoading(true);
      const data = await getSettings();
      console.log("data::", data);
      setOneCoinInDollar(1/data["dollar_in_hga_coin"]);
      setButtonLoading(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const registerPlayerCall = async () => {
    try {
      setButtonLoading(true);
      let data = {};
      if (!isDisabled) {
        playerDetails['username'] = search
        const createdPlayer = await createNewPlayer(playerDetails);
        data = await registerPlayer({
          tournamentId: selectedTournament.id,
          groupId: groupId,
          playerId: createdPlayer.id,
        });
      } else {
        data = await registerPlayer({
          tournamentId: selectedTournament.id,
          groupId: groupId,
          playerId: selectedPlayer.id,
        });
      }

      getplayerDetailsCall();
      setPlayerDetails({});
      setButtonLoading(false);
      setSearch('');
      setSelectedPlayer({});
      setIsDisabled(false);
      toast.success("Player Registerd successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setButtonLoading(false);
    }
  };

  const purchaseCoinCall = async (coins) => {
    setRegisterStatus(status.PROCESSING);
    try {
      setLocalLoading(true);
      const data = await updatePlayerWallet({
        coins: coins,
      });
      setAuthUserDetails(data);
      setTimeout(() => {
        setRegisterStatus(status.SUCCES);
      }, 2000);
      setPurchaseVisiblity(false);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      setPurchaseVisiblity(false);
      setTimeout(() => {
        setRegisterStatus(status.FAILED);
      }, 2000);
    }
  };

  // useEffect()
  useEffect(() => {
    getplayerDetailsCall();
    getSettingCall();
  }, []);

  return (
    <div>
      <ModalContainer show={localLoading}>
        <div className="form_common_style loader_modal">
          <div className="filler_dots_cont">
            {registerStatus === status.PROCESSING ? (
              <div className="gray_dots">
                <img src={images.grayDots} className="dots_btm" alt="" />
                <img src={images.grayDots} className="dots_top" alt="" />
              </div>
            ) : registerStatus === status.SUCCES ? (
              <div className="green_dots">
                <img src={images.greenDots} className="dots_btm" alt="" />
                <img src={images.greenDots} className="dots_top" alt="" />
              </div>
            ) : (
              <div className="orange_dots">
                <img src={images.orangeDots} className="dots_btm" alt="" />
                <img src={images.orangeDots} className="dots_top" alt="" />
              </div>
            )}
          </div>
          {registerStatus === status.PROCESSING ? (
            ""
          ) : (
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                setLocalLoading(false);
              }}
            ></i>
          )}
          <div className="loader_gif_cont">
            {registerStatus === status.SUCCES ? (
              <img src={images.succes} alt="" />
            ) : registerStatus === status.PROCESSING ? (
              <img src={images.loagerGif} alt="" />
            ) : (
              <img src={images.failed} alt="" />
            )}
          </div>
          {registerStatus === status.SUCCES ? (
            <React.Fragment>
              <p>Hga Coins are successfully added to your account</p>
              {/*<h2>Tournament Name</h2>*/}
            </React.Fragment>
          ) : registerStatus === status.PROCESSING ? (
            <h2>Payment Is Processing</h2>
          ) : (
            <h2>Payment Failed</h2>
          )}
        </div>
      </ModalContainer>
      <ModalContainer show={purchaseVisiblity}>
        <div className="purchese_coin_cont form_common_style loader_modal">
          <div className="filler_dots_cont">
            <div className="orange_dots">
              <img src={images.fillerImg} className="dots_btm" alt="" />
              <img src={images.fillerImg} className="dots_top" alt="" />
            </div>
          </div>
          <div className="header">
            <h2> Purchese HGA Coins </h2>
            <i
              className="fa-solid fa-xmark close_btn"
              onClick={() => setPurchaseVisiblity(false)}
            ></i>
          </div>
          <div className="wallet_input_cont">
            <WalletInput
              type={"text"}
              coinLogo={true}
              placeholder={"HGA Coins"}
              width={"100%"}
              fieldRed={true}
              value={hgaCoins}
              onChange={handelHgaCoinsUpdation}
              alignCenter={true}
            />

            <div className="divider_line">
              <div className="line"></div>
              <span>OR</span>
              <div className="line"></div>
            </div>
            <WalletInput
              type={"text"}
              coinLogo={false}
              placeholder={"Amount in Dollars"}
              width={"100%"}
              fieldRed={true}
              value={amount}
              disabled={true}
              alignCenter={true}
            />
            <button
              className="btn-style btn-green w-100 mt-5"
              onClick={() => {
                purchaseCoinCall(hgaCoins);
              }}
            >
              Purchase Coins
            </button>
          </div>
        </div>
      </ModalContainer>
      <Header
        heading={
          selectedTournament.t_name
            ? selectedTournament.t_name
            : "Tournament Name"
        }
      />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar inMangeTournament={true} />
        </div>
        <div className="manage_sec_page_cont">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Player's Details</h2>
          </div>
          <div className="register_player_form_cont">
            <div className="form_cont">
              <div className="form_input_cont">
                <p>Nick Name -</p>
                <DropDownWithSearch
                  title={selectedPlayer.username ? selectedPlayer.username : ""}
                  onChange={(val) => {
                    setSearch(val);
                    setSelectedPlayer({});
                    setIsDisabled(false);
                    setPlayerDetails({});
                    getAllPlayersCall(val);
                  }}
                  width={"63%"}
                  isDualResponsible={true}
                  placeholder="Search Players"
                  dropdownPlaceholder={"Select Player"}
                  headerOptions={["Player", "HDCP"]}
                  player={allPlayers}
                  value={search}
                  handelChangeSelection={(user) => {
                    handelOnSelect(user);
                  }}
                />
                {/*<InputField
                  placeholder={"Enter Nick Name"}
                  onChange={(value) =>
                    setPlayerDetails((preState) => {
                      return { ...preState, username: value };
                    })
                  }
                  value={playerDetails.username}
                  width={"63%"}
                />*/}
              </div>
              <div className="form_input_cont">
                <p>Player Name -</p>
                <InputField
                  placeholder={"Enter Player Name"}
                  onChange={(value) =>
                    setPlayerDetails((preState) => {
                      return { ...preState, name: value };
                    })
                  }
                  value={playerDetails.name}
                  width={"63%"}
                  isDisabled={isDisabled}
                />
              </div>

              <div className="form_input_cont">
                <p>Email ID -</p>
                <InputField
                  placeholder={"Enter Email ID"}
                  onChange={(value) =>
                    setPlayerDetails((preState) => {
                      return { ...preState, email: value };
                    })
                  }
                  value={playerDetails.email}
                  width={"63%"}
                  type={"Email"}
                  isDisabled={isDisabled}
                />
              </div>
              <div className="form_input_cont">
                <p>Mobile No. -</p>
                <InputField
                  placeholder={"Enter Mobile No"}
                  onChange={(value) =>
                    setPlayerDetails((preState) => {
                      return { ...preState, phone: value };
                    })
                  }
                  value={playerDetails.phone}
                  width={"63%"}
                  type={"Number"}
                  isDisabled={isDisabled}
                />
              </div>
              <div className="form_input_cont ${isDisabled ? 'text-secondary':''}">
                <p>Handicap Points -</p>
                <InputField
                  placeholder={"Enter Handicap Points"}
                  onChange={(value) =>
                    setPlayerDetails((preState) => {
                      return { ...preState, handicap: value };
                    })
                  }
                  value={playerDetails.handicap}
                  width={"63%"}
                  isDisabled={isDisabled}
                />
              </div>
              <div className={`form_input_cont ${isDisabled ? "d-none" : ""}`}>
                <p>Password -</p>
                <InputField
                  placeholder={"Enter Password"}
                  onChange={(value) =>
                    setPlayerDetails((preState) => {
                      return { ...preState, password: value };
                    })
                  }
                  value={playerDetails.password}
                  width={"63%"}
                />
              </div>

              {/*<div className="btn_cont justify-content-end d-flex w-100">
                <button className="btn-style btn-orange" onClick={()=>{handelSaveDetails()}}> Save Details </button>
                </div>*/}
            </div>
            <div className="payment_cont">
              <div className="your_wallet d-flex">
                <p>Your Wallet</p>
                <div className="wallet_value_cont d-flex">
                  <div className="img_cont">
                    <img src={images.coinLogo} alt="" />
                  </div>
                  <p>
                    {authUserDetails.wallet} <span>(Hga Coin)</span>
                  </p>
                </div>
              </div>
              <div className="registration_fee_cont">
                <h2>Registration Fee</h2>
                <div className="btn_and_inp_cont d-flex justify-content-between align-items-center">
                  <WalletInput
                    placeholder={"HGA Coins"}
                    value={selectedTournament.registration_fee}
                    width={"45%"}
                    coinLogo={true}
                    fieldRed={!enoughCoins()}
                    disabled
                  />
                  {enoughCoins() ? (
                    <button
                      className="btn-style btn-green col-5"
                      style={{
                        height: "2.5rem",
                      }}
                      onClick={() => {
                        if(!buttonLoading){
                          handelRegisterPlayer();
                        }
                      }}
                    >
                      {buttonLoading ? "Loading ..." :"Register Player"} 
                    </button>
                  ) : (
                    <button
                      className="btn-style btn-orange col-5"
                      style={{
                        height: "2.5rem",
                      }}
                      onClick={() => {
                        setHgaCoins(
                          Number(selectedTournament.registration_fee) -
                            Number(authUserDetails.wallet)
                        );
                        setAmount(
                          (Number(selectedTournament.registration_fee) -
                            Number(authUserDetails.wallet)) *
                            oneCoinInDollar
                        );
                        setPurchaseVisiblity(true);
                      }}
                    >
                      Purchase Coin
                    </button>
                  )}
                </div>
              </div>
              {enoughCoins() ? (
                ""
              ) : (
                <p className="message">
                  * You don’t have enough coins to register a player please add{" "}
                  {Number(selectedTournament.registration_fee) -
                    Number(authUserDetails.wallet)}{" "}
                  HGA coins
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MangeTournamentRegisterPlayersForm;
