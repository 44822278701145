import React, { useEffect, useState } from "react";
import moment from "moment";
// Style //
import "./TourInfo.css";

import { images } from "../../../../assets/index";
import Multiselect from "multiselect-react-dropdown";
// Pages //
import { Landing } from "../../../index";

// Container //
import { Header } from "../../../../common/containers/index";

// Components //
import {
  DropDownWithSearch,
  Loader,
  Sidebar,
  SkLoader,
  UserProfile,
} from "../../../../common/components/index";
import { ModalContainer } from "../../../../common/containers/index";
import { useDispatch, useSelector } from "react-redux";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

// APIs //
import { addManagersApi, getTourDetails } from "../../../../api/leagueApi";
import { getUsers } from "../../../../api/usersApi";
import UpdateTour from "./updateTour";

const TourInfo = () => {
  // hooks
  const { selectedLeague } = useSelector((state) => state.league);
  const { loading, error } = useSelector((state) => state.utils);
  const [addManagerModal, setAddManagerModal] = useState(false);
  const [search, setSearch] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [usersData, setUsersData] = useState([]);
  const [showUpdateTour, setShowUpdateTour] = useState(false);
  const [managers, setManagers] = useState([]);
  const [state, setState] = useState({
    items: [
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
      { name: "lokesh" },
    ],
    hasMore: true,
  });
  const dispatch = useDispatch();
  const [tourDetails, setTourDetails] = useState({});
  if ({}) {
  }
  const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8,
  };

  // apiCalls
  const getTourDetailCall = async (leagueId) => {
    try {
      dispatch(requestSent());
      const data = await getTourDetails({ leagueId });
      setTourDetails(data);
      if(data && data.managedByPlayer ){
        let managerIds = []
        data.managedByPlayer.forEach((obj)=>{
          managerIds.push(obj.id)
        })
        setManagers(managerIds)
      }
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const addManagersCall = async (leagueId) => {
    try {
      dispatch(requestSent());
      const data = await addManagersApi({
        leagueId,
        managerIds: JSON.stringify(managers),
      });
      dispatch(responseRecived());
      toast.success("managers add successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setAddManagerModal(false);
      getTourDetailCall(leagueId);
    } catch (error) {
      console.log("error::", error);
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };
  const getUserCall = async (searchVal, limitVal) => {
    try {
      setLocalLoading(true);
      setLimit(limitVal);
      let searchValue = searchVal.trim().replace(/[%]+/g, "\\%");
      const data = await getUsers({ search: searchValue, limit: limitVal });
      setUsersData(data.rows);

      setLocalLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLocalLoading(false);
      dispatch(receivedError(error));
    }
  };

  // handlers
  // let fetchMoreData = () => {
  //   // if (state.items.length >= 5000) {
  //   //   setState((preState) => {
  //   //     return { ...preState, hasMore: false };
  //   //   });
  //   //   return;
  //   // }
  //   getUserCall(search, limit + 10);
  // };
  const handelSearchChange = (val) => {
    setSearch(val);
    getUserCall(val, 10);
  };

  let handelOnSelect = (user) => {
    if (managers.includes(user.id)) return;
    setManagers((preState) => {
      return [...preState, user.id];
    });
  };
  let handelOnRemove = (user) => {
    setManagers((preState) => {
      return preState.filter((id) => id != user.id);
    });
  };
  
  //On Page Components
  const ManagerRows = ({ managers: players }) => {
    return players.map((manager, index) => {
      return (
        <tr key={manager.id}>
          <td>{index+1}</td>
          <td>
            <UserProfile
              name={manager.username ? manager.username : "NA"}
              nickName={manager.email ? manager.email : "NA"}
              redirection={true}
              useId={manager.id}
            />
          </td>
          <td className="status">Manager</td>
          <td>
            <button className="btn-style" onClick={()=>{setAddManagerModal(true)}}>Remove</button>
          </td>
        </tr>
      );
    });
  };

  // useEffects

  useEffect(() => {
    getTourDetailCall(selectedLeague.id);
    getUserCall("", 10);
  }, []);

  return (
    <div>
      <ModalContainer show={addManagerModal}>
        <div
          className="form_common_style overflow-hidden"
          id="add_manager_form"
        >
          <div className="filler_elem">
            <img src={images.fillerImg} alt="" />
            <img src={images.fillerImg} alt="" />
          </div>
          <div className="header">
            <h2> Permission To Manage </h2>
          </div>
          {/*<Multiselect
    options={usersData} // Options to display in the dropdown
    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
    onSelect={handelOnSelect} // Function will trigger on select event
    onRemove={handelOnRemove} // Function will trigger on remove event
    displayValue="username" // Property name to display in the dropdown options
    style={mutliSelectObj} 
    hideSelectedList={true}
  />*/}

          <div className="add_manager_cont">
            <div className="dropdown-container w-100">
              <DropDownWithSearch
                onChange={(val) => {
                  handelSearchChange(val);
                }}
                placeholder="Search Players"
                dropdownPlaceholder={"Select Player"}
                headerOptions={["Player", "HDCP"]}
                player={usersData}
                value={search}
                handelChangeSelection={handelOnSelect}
              />
            </div>

            <div className="list_of_users w-100" id="scrollableDiv">
              {usersData
                .filter((obj) => managers.includes(obj.id))
                .map((obj, index) => {
                  return (
                    <div className="user" key={obj.id}>
                      <UserProfile name={obj.username} redirection={true} userId={obj.id} />
                      <button
                        className="btn-style btn-orange add_manager_btn"
                        onClick={() => {
                          handelOnRemove(obj);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
            </div>
            <div className="btn_cont">
              <button
                className="btn-style btn-orange"
                onClick={() => setAddManagerModal(false)}
              >
                Cancel
              </button>
              <button
                className="btn-style btn-green"
                onClick={() => {
                  addManagersCall(selectedLeague.id);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>

      <Header />
      <div className="d-flex side_tab_page_cont justify-content-between ">
        <div className="manage_sec_side_bar_wraper">
          <Sidebar />
        </div>
        <div className="manage_sec_page_cont" id="tour-info">
          <div className="header d-flex justify-content-between align-items-center">
            <h2>Tour Info</h2>
            <button
              className="btn-style btn-green"
              onClick={() => {
                setShowUpdateTour(true);
              }}
            >
              {" "}
              Update Tour{" "}
            </button>
          </div>
          {loading ? (
            <SkLoader limit={7} height={30} mt={2} />
          ) : (
            <table>
              <tbody>
                <tr>
                  <td>League Name -</td>
                  <td>
                    {tourDetails.league_name ? tourDetails.league_name : "Na"}
                  </td>
                </tr>
                <tr>
                  <td>League Owner -</td>
                  <td>{tourDetails.user ? tourDetails.user.username : "Na"}</td>
                </tr>
                <tr>
                  <td>Location -</td>
                  <td>{tourDetails.country ? tourDetails.country : "Na"}</td>
                </tr>
                <tr>
                  <td>Start Date -</td>
                  <td>
                    {tourDetails.start_date
                      ? moment(tourDetails.start_date).format("DD/MM/YYYY")
                      : "Na"}
                  </td>
                </tr>
                <tr>
                  <td>Description -</td>
                  <td>
                    {tourDetails.description ? tourDetails.description : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="header d-flex justify-content-between align-items-center mt-3">
            <h2>Managers</h2>
            <button
              className="btn-style btn-green"
              onClick={() => setAddManagerModal(true)}
            >
              Add Managers
            </button>
          </div>
          {loading ? (
            <SkLoader limit={1} height={30} mt={2} />
          ) : (
            <React.Fragment>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Player</th>
                  <th scope="col">Role</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="manager_table">
                {tourDetails.managedByPlayer &&
                tourDetails.managedByPlayer.length > 0 ? (
                  <ManagerRows managers={tourDetails.managedByPlayer} />
                ) : (
                  ''
                )}
              </tbody>
            </table>
            {tourDetails.managedByPlayer &&
              tourDetails.managedByPlayer.length == 0 ?(
            <p className="text-center">Click on Add Managers button to add managers to the league</p>
              ):('')
            }
            </React.Fragment>
          )}
        </div>
      </div>

      {showUpdateTour ? (
        <ModalContainer show={showUpdateTour}>
          <UpdateTour
            handleClose={() => {
              setShowUpdateTour(false);
            }}
            league={tourDetails}
            getTourDetailCall={getTourDetailCall}
          />
        </ModalContainer>
      ) : (
        ""
      )}
    </div>
  );
};

export default TourInfo;
