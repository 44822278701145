import React, { useEffect, useState } from "react";

// Toast //
import { toast } from "react-toastify";

// Style //
import "./TournamentResult.css";

// Containers //
import { Header, Wraper } from "../../common/containers";

//  Components //
import { UserProfile, DropdownList, SkLoader } from "../../common/components";
import { Loader } from "../../common/components/index";

// pagination //
import Pagination from "rc-pagination";

// Api's //
import { getSeasonTourResults } from "../../api/seasonApi";
// Redux Action //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../redux/actions/utilsAction";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { setSelectedSeasonAction } from "../../redux/actions/seasonAction";
import { useNavigate } from "react-router";


const TournamentResult = () => {
  const navigator = useNavigate()
  const { error, loading } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const { selectedSeason, allSeasons } = useSelector((state) => state.season);
  const [tourResult, setTourResult] = useState([]);
  // handlers //
  const handelDropDownOnClick = (season) => {
    tourResultCall(season.id);
    dispatch(setSelectedSeasonAction(season));
  };
  const handelLeaderboardClick = (tournamentId) => {
    // navigator(`/tournament/result?${tournamentId}`)
    navigator(`/results/tournament?tournamentId=${tournamentId}`)
  }


  const tourResultCall = async (seasonId) => {
    try {
      dispatch(requestSent());
      const data = await getSeasonTourResults({ seasonId });
      console.log("data::", data);
      setTourResult(data);
      dispatch(responseRecived());
    } catch (error) {
      console.log("error::", error);
      dispatch(responseRecived());
      dispatch(receivedError(error));
    }
  };
  
  useEffect(() => {
    console.log("useEffect is calling");
    tourResultCall(selectedSeason.id);
  }, []);
  return (
    <>
      <Header button={true}/>
      <Wraper title={"Tournament Result"} dropDown={{
          title: selectedSeason.name,
          items: allSeasons,
          handelOnClick: handelDropDownOnClick,
        }}>
        { loading ? <SkLoader limit={20} height={30} mt={2}/> :

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Tournament</th>
              <th scope="col">Venue</th>
              <th scope="col">Handicap</th>
              <th scope="col">Champions</th>
              <th scope="col">Time</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {tourResult.map((tour, index) => (
              <tr key={tour.id}>
                <td>{moment(tour.start_date).format("DD/MM/YYYY")}</td>
                <td>{tour.t_name}</td>
                <td>
                  {tour.golf_course ? tour.golf_course.course_name : '-'}
                </td>
                
                <td>-</td>
                <td>
                  {tour.tournament_leaderboards &&
                  tour.tournament_leaderboards.length > 0 ? (
                    <UserProfile
                      name={tour.tournament_leaderboards["0"].player.username}
                      nickName={tour.tournament_leaderboards["0"].player.email}
                      userId={tour.tournament_leaderboards["0"].player_id}
                      redirection={true}
                    />
                  ) : (
                    '-'
                  )}
                </td>

                <td>{moment(tour.start_date).format("HH:mm:ss")}</td>

                <td>
                  <button className="btn-orange btn-style" onClick={() => {handelLeaderboardClick(tour.id)}}>Leaderboard</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       }
       {tourResult.length===0 ? (
        <h3 className="text-center">No Tournaments Found for this season</h3>
      ):''}
      </Wraper>
    </>
  );
};

export default TournamentResult;
