import React, { useEffect, useState } from "react";
import "../../../league/create-league/CreateLeague.css";

// useDispatch //
import { useDispatch, useSelector } from "react-redux";
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router";
import DateTime from "react-datetime";
import moment from "moment";

import { DropDownWithSearch } from "../../../../common/components";

import { toast } from "react-toastify";
import { getCountryArray } from "../../../../services/utils";
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../../../redux/actions/utilsAction";
import { updateLeague } from "../../../../api/leagueApi";
import Constant from "../../../../services/Constant";

export default function UpdateTour({ handleClose, getTourDetailCall, league }) {
  // useState
  const [leagueName, setLeagueName] = useState(league.league_name);
  const [leagueStartDate, setLeagueStartDate] = useState(league.start_date);
  const [showStartDate, setShowStartDate] = useState(moment(league.start_date).format(Constant.DATE_FORMAT));

  const [description, setDescription] = useState(league.description);
  const [search, setSearch] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [selectedCountry, setSelectedConutry] = useState(league.country);
  const [countryArray, setConutryArray] = useState([]);
  // useNavigate
  const navigator = useNavigate();
  const { user } = useSelector((state) => state.user);
  // useDispatch
  const dispatch = useDispatch();

  // useSlector

  // api calls
  const updateLeagueCall = async (details) => {
    try {
      console.log("body::", details);
      if (!updateFlag) {
        return toast.warning("Please update any field first !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch(requestSent());
      let data = await updateLeague(details);

      dispatch(responseRecived());
      toast.success("League Updated successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getTourDetailCall(league.id);
      handleClose();
      setLeagueName("");
      setLeagueStartDate("");
      setDescription("");
      setSelectedConutry("");
      // navigator('/manage-tournament')
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  // handler
  const handelSearchChange = (val) => {
    setSearch(val);
    const countries = getCountryArray();
    let filteredCountries = countries.filter((name) =>
      name.toLowerCase().includes(val.toLowerCase())
    );
    console.log("filteredCountries::", filteredCountries);
    let list = filteredCountries.map((name,index) => {
      return { id: index, item1: name };
    });
    setConutryArray(list);
  };

  const handelChangeSelection = (item) => {
    // let selected = { id: item.id, league_name: item.item1 };
    setSelectedConutry(item.item1);
    setUpdateFlag(true)

  };

  function handleSubmit() {
    // if (
    //  !leagueName ||
    //  !leagueStartDate||
    //  !description
    // ) {
    //   dispatch(receivedError(Constant.ERRORS_LIST.FILL_ALL_FIELDS))
    //   return;
    // }

    updateLeagueCall({
      league_name: leagueName,
      start_date: leagueStartDate,
      description: description,
      country: selectedCountry,
      league_id:league.id
    });
  }

  // useEffect
  useEffect(() => {
    handelSearchChange("");
  }, []);
  return (
    <div className="create_league_form form_common_style" id="create-league">
      {/* <div className="filler_elem">
        <img src={images.fillerImg} alt="" />
        <img src={images.fillerImg} alt="" />
      </div> */}
      <div className="header">
        <h2> Update League </h2>
      </div>
      <div className="form_cont">
        <div className="input_lable_cont">
          <p>League Name -</p>
          <div className="input_field_cont">
            <input
              type="text"
              className="input_field"
              placeholder="Enter League Name"
              value={leagueName}
              onChange={(e) => {
                setUpdateFlag(true)
                setLeagueName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Start Date -</p>
          <div className="input_field_cont  number-input-elem custom-date_slector">
            <DateTime
              value={showStartDate}
              dateFormat={Constant.DATE_FORMAT}
              inputProps={{ placeholder: "Select Date", readOnly: true }}
              timeFormat={false}
              onChange={(e) => {
                setLeagueStartDate(e.format(Constant.DB_DATE_FORMATE));
                setShowStartDate(e)
                setUpdateFlag(true)
                console.log("date::",e);
              }}
              closeOnSelect={true}
            />
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </div>
        </div>
        <div className="input_lable_cont">
          <p>Country -</p>
          <div className="input_field_cont">
            <DropDownWithSearch
              title={selectedCountry}
              listItems={countryArray}
              placeholder="Search Country..."
              dropdownPlaceholder={"Select Country"}
              headerOptions={["Country"]}
              handelChangeSelection={handelChangeSelection}
              value={search}
              onChange={handelSearchChange}
              // onChange={setSearchDropDownValue}
            />
          </div>
        </div>
        <div className="input_lable_cont">
          <p>League Description -</p>
          <div className="input_field_cont">
            <textarea
              type="text"
              className="input_field"
              placeholder="Enter Discription"
              value={description}
              onChange={(e) => {
                setUpdateFlag(true)
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="btn_cont">
        <button className="btn-style btn-orange" onClick={handleClose}>
          Close
        </button>
        <button className="btn-style btn-green" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}
