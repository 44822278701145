import React, { useState, useRef, useEffect } from "react";

// Style //
import "./NavBar.css";
import "./LogInSignUp.css";

// React Router Link //
import { NavLink, Navigate, useNavigate } from "react-router-dom";

// Images //
import { images } from "../../../assets";

// Component //
import { Loader } from "../../components/index";

// Container //
import { ModalContainer } from "../../containers/index";

// useDispatch //
import { useDispatch, useSelector } from "react-redux";

// Redux Action //
import {
  loginUser,
  signUpUser,
  reciveLogOut,
  changeShowLogin,
  changeShowSignup,
} from "../../../redux/actions/action";

import { isObjectEmpty } from "../../../services/utils";
import CommonModals from "../Modals/UnRestricted/CommonModals";
import { changeShowCreateLeague, setSelectedLeagueAction } from "../../../redux/actions/leagueAction";
import { setAllSeasonsAction, setSelectedSeasonAction } from "../../../redux/actions/seasonAction";
import { setSelectedTournamentAction } from "../../../redux/actions/tournamentAction";
import { receivedError } from "../../../redux/actions/utilsAction";
import { catchHandle } from "../../../errorHandlers/errorHandler";

const NavBar = ({ isUser }) => {
  // User Slice//
  const { user } = useSelector((state) => state.user);
  // Season slice //
  const { selectedSeason } = useSelector((state) => state.season);
  // Tournament slice //
  const { selectedTournament } = useSelector((state) => state.tournament);
  // League slice //
  const { selectedLeague } = useSelector((state) => state.league);
  // utils
  const { loading, error } = useSelector((state) => state.utils);
  
  const dropdownRef = useRef()
  // Dispatch //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Modal States //
  const [dropDown, setDropDown] = useState(false);

  // handelers //
  const handelSeasonPresentOrNot = () =>{
    if(isObjectEmpty(selectedSeason)){
      return dispatch(receivedError('Please Select or Create Season First !'))
    }
  }
  const handelClearAfterLogOut = () =>{
    localStorage.clear("reset_token");
    localStorage.clear("persist:root");
    dispatch(changeShowSignup(false));
    dispatch(setSelectedLeagueAction({}));
    dispatch(changeShowCreateLeague(false));
    dispatch(setSelectedSeasonAction({}));
    dispatch(setSelectedTournamentAction({}));
    dispatch(setAllSeasonsAction([]));
    dispatch(reciveLogOut());
  }
      // Log Out //
      const logOutHendler = () => {
        setDropDown(false)
        console.log("logOutHendler::");
        handelClearAfterLogOut()
        setTimeout(() => {
          navigate("/");
        }, 500);
      };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <nav>
        <div className="nav_cont">
          <div className="logo_cont">
            <img src={images.hgaLogo} alt="" />
          </div>
          {user ? (
            <div className="link_cont">
              {!isObjectEmpty(selectedLeague)  ? (
                <ul className="links">
                  <li className="link">
                    <div onClick={() => {handelSeasonPresentOrNot()}} >
                    {isObjectEmpty(selectedSeason)?(
                      <NavLink >
                      Season Standings
                    </NavLink>
                    ):(
                      <NavLink to ={"/standing"}>
                      Season Standings
                      <div className="underline"></div>
                    </NavLink>
                    )}
                    </div>
                    
                  </li>
                  <li className="link">
                  <div onClick={() => {handelSeasonPresentOrNot()}} >
                    {isObjectEmpty(selectedSeason)?(
                      <NavLink  >
                      Tour Schedule
                    </NavLink>
                    ):(
                      <NavLink to ={"/schedule"}>
                      Tour Schedule
                      <div className="underline"></div>
                    </NavLink>
                    )}
                    </div>
                   
                  </li>
                  <li className="link">
                  <div onClick={() => {handelSeasonPresentOrNot()}} >
                  {isObjectEmpty(selectedSeason)?(
                    <NavLink >
                    Tournament Results
                  </NavLink>
                  ):(
                    <NavLink to ={"/results"}>
                    Tournament Results
                    <div className="underline"></div>
                  </NavLink>
                  )}
                  </div>
                  </li>
                  <li className="link">
                    <NavLink to={"/players"}>
                      Players
                      <div className="underline"></div>
                    </NavLink>
                  </li>
                  <li className="link">
                    <NavLink to={"/wallet"}>
                      HGA Coins
                      <div className="underline"></div>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className="links">
                  <li className="link">
                    <NavLink to={"/manage-my-tournament"}>
                      Manage Tournaments
                      <div className="underline"></div>
                    </NavLink>
                  </li>
                  <li className="link">
                    <NavLink to={"/players"}>
                      Players
                      <div className="underline"></div>
                    </NavLink>
                  </li>
                  <li className="link">
                    <NavLink to={"/wallet"}>
                      HGA Coins
                      <div className="underline"></div>
                    </NavLink>
                  </li>
                </ul>
              )}
              <div className="profile_dropdown" ref={dropdownRef}>
                <div className="profile-img_cont" onClick={()=>setDropDown(!dropDown)}></div>
                <div className={`dropdown_list ${dropDown?"show":''}`}>
                  <div className="triangle"></div>
                  <div className="options" onClick={()=>{
                    setDropDown(false) 
                    navigate("/profile")}}>
                    <p>My Profile</p>
                  </div>
                  <div className="options" onClick={()=>{ 
                    navigate('/manage-my-tournament')
                    setDropDown(false)}}>
                    <p>Manage</p>
                  </div>
                  <div className="options" onClick={()=>{
                    setDropDown(false)
                    navigate('/golf-courses')
                  }}>
                    <p>Courses</p>
                  </div>
                  <div className="options" onClick={()=>setDropDown(false)}>
                    <p>Support</p>
                  </div>
                  <div className="options" onClick={logOutHendler}>
                    <button className="btn-style btn-orange">Sign Out</button>
                  </div>
                </div>
              </div>
            </div>
          ) : !isObjectEmpty(selectedTournament) ? (
            <div className="link_cont">
              <ul className="links">
                <li className="link">
                  <NavLink to={"/manage-my-tournament"}>
                    Manage Tournaments
                    <div className="underline"></div>
                  </NavLink>
                </li>
                <li className="link">
                  <NavLink to={"/players"}>
                    Players
                    <div className="underline"></div>
                  </NavLink>
                </li>
                <li className="link">
                  <NavLink to={"/wallet"}>
                    HGA Coins
                    <div className="underline"></div>
                  </NavLink>
                </li>
              </ul>
              <button
                className="btn-style btn-orange mx-2"
                onClick={()=>{dispatch(changeShowLogin(true))}}
              >
                Log In
              </button>
              <button
                className="btn-style btn-orange"
                onClick={()=>{dispatch(changeShowSignup(true))}}
              >
                Sign Up
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn-style btn-orange mx-2"
                onClick={()=>{dispatch(changeShowLogin(true))}}
              >
                Log In
              </button>
              <button
                className="btn-style btn-orange"
                onClick={()=>{dispatch(changeShowSignup(true))}}
              >
                Sign Up
              </button>
            </div>
          )}
        </div>
      </nav>
      {/* if (user is but selected info is not there)  then show (CommonModal select) else (user and selected info) both are  there then show nothing , but if (user is not there) then show (commonmodals of login signup ) */}

      <CommonModals/>
    </>
  );
};

export default NavBar;
