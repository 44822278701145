export const actionType = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    SHOW_SINGUP:'SHOW_SINGUP',
    SHOW_LOGIN:'SHOW_LOGIN',
    LOG_OUT:"LOG_OUT",

    SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
    SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
    SIGN_UP_FAILURE: "SIGN_UP_FAILURE",

    EXISTING_USER_REQUEST: "LOGOUT_REQUEST",
    EXISTING_USER__SUCCESS: "LOGOUT_SUCCESS",
    EXISTING_USER__FAILURE: "LOGOUT_FAILURE",

    UTILS:{
        REQUEST_SENT:'REQUEST_SENT',
        RESPONSE_RECEVIED:'RESPONSE_RECEVIED',
        REQUEST_ERROR:'REQUEST_ERROR',
    },

    LEADERBOARRD:{
        SEASON_STANDING_REQUEST:'SEASON_STANDING_REQUEST',
        SEASON_STANDING:'SEASON_STANDING',
    },
    TOURNAMENT:{
        SELECT_TOURNAMENT:'SELECT_TOURNAMENT',
        SHOW_CREATE_TOURNAMENT:'SHOW_CREATE_TOURNAMENT',
        INDEPENDENT_CREATION_MODE_ON:'INDEPENDENT_CREATION_MODE_ON',
        INDEPENDENT_CREATION_MODE_OFF:'INDEPENDENT_CREATION_MODE_OFF'

    },
    SEASON:{
        SELECT_SEASON:'SELECT_SEASON',
        SET_ALL_SEASONS:'SET_ALL_SEASONS',
    },
    LEAGUE:{
        SELECT_LEAGUE:'SELECT_LEAGUE',
        CREATE_LEAGUE:'CREATE_LEAGUE',
        CHANGE_SHOW_LEAGUE:'CHANGE_SHOW_LEAGUE',    
    },

    BODY_OVERFLOW_HIDDEN: "BODY_OVERFLOW_HIDDEN"
}
