import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./rootReducer";
// redux-persist //

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// import leaderboardReducer from './reducers/leaderboardReducer'


const persistConfig = {
  key: "root",
  storage,
  whitelist: ["league","season","tournament",'user'], // only persist the league,season,tournament slice of state
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = configureStore(
  {
    reducer: persistedReducer,
  },
  applyMiddleware(thunkMiddleware)
);

export const persistor = persistStore(createStore);
