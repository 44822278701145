import { actionType } from "../actionType";


// ================ Utils Actions =================//

export const requestSent = () => {
    return {
        type: actionType.UTILS.REQUEST_SENT
      };
  };

  export const responseRecived = () => {
    return {
        type: actionType.UTILS.RESPONSE_RECEVIED
      };
  };

  export const receivedError = (error) => {
    return {
        type: actionType.UTILS.REQUEST_ERROR,
        payload:error
      };
  };

// =============== Body Overflow action for Modals ============= //

export const bodyOverFlowHiddenAction = (data) => {
  return {
      type: actionType.BODY_OVERFLOW_HIDDEN,
      payload:data
    };
};