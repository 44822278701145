import { actionType } from "../actionType";

const initialState ={
    allLeagues:[],
    myLeagues: [],
    selectedLeague:{},
    showCreateLeague:false,
}

function leagueReducer(state= initialState, action){
     switch(action.type) {
        case (actionType.LEAGUE.SELECT_LEAGUE):
        return{
                ...state,
                selectedLeague: action.payload
            }
        case (actionType.LEAGUE.CHANGE_SHOW_LEAGUE):
            return{
                    ...state,
                    showCreateLeague: action.payload
                }
         default: return state
     };
}

export default leagueReducer;